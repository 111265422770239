import React from "react";
// Customizable Area Start
import { Button, Col, Modal, Row } from "antd";
import QuestionBankController, { QuestionOption } from "./QuestionBankController.web";
import "./QuestionBank.web.css";

import ReactPaginate from "react-paginate";
import { info, leave } from "../../signuplogin/src/assets";
import { Box, Dialog, Typography } from "@material-ui/core";
import Buttons from "./Buttons.web";
import '../../signuplogin/src/styles/loginComp.css'
import { cross, leave1 } from "./assets";
import Header from "../../catalogue1/src/Header.web";
import { FaCircle } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
// Customizable Area End

class QuestionBank extends QuestionBankController {
  // Customizable Area Start
  renderLeaveDialog = () => {
    return (
      <>
        <Dialog open={this.state.isModalOpen} className="dialogContainer">
          <Box className="dialogBox">
            <Box className="iconBox">
              <Box></Box>
              <Box>
                <Box className="leaveBorder">
                  <img src={leave1} />
                </Box>
              </Box>
              <Box>
                <img src={cross} onClick={this.handleLeave} />
              </Box>
            </Box>
            <Box className="dialogTextCon">
              <Typography className="dialogHeading">Leaving Question Bank?</Typography>
              <Typography className="textDec1">Are you sure you want to leave the question bank? Any progress made will be lost.</Typography>
              <Typography className="textDec">Click 'Cancel' to return to the question bank, or 'Leave' to exit.</Typography>
            </Box>

            <Box className="btnBox">
              <Button className="leaveBtn" onClick={this.handleLeaveQuestion}>Leave</Button>
              <Button className="cancelBtn" onClick={this.handleLeave}>Cancel</Button>
            </Box>
          </Box>
        </Dialog>
      </>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderShowQuetionList = () => {
    let orderPageCount = this.state.allData.meta.total_pages;
    return (
      <>
        <Modal
          closable={false}
          open={this.state.quetionSeeModal}
          onCancel={this.quetionSeeModalCancel}
          footer={null}
          centered
          className="main-modal"
        >
          <div className="modal-content">
            <h3 className="modal-heading">Question and Answers List</h3>
          </div>
          {this.state.allQuetionDataForShowButton.length > 0 && (
            <>
              {this.state.allQuetionDataForShowButton.map(
                (item, index: number) => {
                  return (
                    <div className="Question_answer_box" key={index}>
                      <div className="Question_box_view">
                        <p className="Question_box">
                          {item.attributes.question_no}.{" "}
                          {item.attributes.question}
                        </p>
                      </div>
                      <Row gutter={[8, 8]}>
                        {item.attributes.options.data.map(
                          (optionItem, optionIndex: number) => {
                            return (
                              <Col
                                lg={12}
                                xl={12}
                                md={12}
                                sm={24}
                                xs={24}
                                key={optionIndex}
                              >
                                <div className="Options_view_box">
                                  <p className="Options_view">
                                    {optionItem.attributes.possible_answer}
                                  </p>
                                </div>
                              </Col>
                            );
                          }
                        )}
                      </Row>
                      <div className="modal-content">
                        <h3 className="modal-heading description_heading">
                          Answer Description:
                        </h3>
                      </div>
                      <div className="Answer_description_box">
                        <p className="Answer_description">
                          {item.attributes.link_to_detail}
                        </p>
                        <p className="Answer_main_box">
                          Answer : {item.attributes.correct_answer}
                        </p>
                      </div>
                    </div>
                  );
                }
              )}
            </>
          )}
          <ReactPaginate
            className="class-pag"
            previousLabel={<i className="fa fa-angle-left" />}
            nextLabel={<i className="fa fa-angle-right" />}
            breakLabel={"..."}
            breakClassName={"page-item"}
            onPageChange={this.handlePageClick}
            pageRangeDisplayed={5}
            pageCount={orderPageCount}
            pageClassName={"page-item"}
            breakLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            previousLinkClassName={"previous page-link"}
            nextLinkClassName={"next page-link"}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </Modal>
      </>
    );
  };

  renderIcon = (step: boolean, pageName: string) => {
    return step
      ? <FaCheck size={this.state.quetionBankList.length} color={'#000'} data-testid={`${pageName}CheckIcon`} />
      : <FaCircle size={this.state.quetionBankList.length} color={step ? '#050d1550' : '#455364'} data-testid={`${pageName}CircleIcon`} />;
  };

  renderCircleWrapperStyle = (index: number) => {
    return index + 1 <= this.state.checkedQueue ? "progress-circle-active circleBlue" : "progress-circle circleBlue";
  }

  renderProgressLineStyle = (index: number) => {
    return index <= this.state.checkedQueue ? "progress-line-active" : "progress-line";
  }

  renderDots() {
    const dataLength = this.state.quetionBankList?.length;

    return (
      <div className="bar-container progressBarLine">
        {Array.from({ length: dataLength }, (_, index) => (
          <>
            <div className="progress-line-start"></div>
            <span
              className={this.renderCircleWrapperStyle(index)}
              data-testid="circleWrapperRole">
              {this.renderIcon(true, 'role')}
            </span>
          </>
        ))}
        <div
          className={this.renderProgressLineStyle(0)}
          data-testid="progressLineRole"></div>
      </div>
    )
  }

  getWrapperClass = (mdJourney: QuestionOption, index: number): string => {
    const possible_answer = mdJourney.attributes.possible_answer;
    const isSelected = this.state.selectedQuestion === possible_answer;
    const isCorrect = this.state.correctAnswer === this.state.selectedQuestion;
    const showValidation = this.state.isAnswerChecked;
    let wrapperClass = "mdJourney-wrapper";
    if (showValidation) {
      if (isSelected) {
        wrapperClass += isCorrect ? " rightAnswer" : " wrongAnswer";
      }
      if (this.state.correctAnswer === possible_answer) {
        wrapperClass += " correctAnswer";
      }
    } else if (isSelected) {
      wrapperClass += " mdJourney-selected-wrapper";
    }
    return wrapperClass;
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const checkedData = this.state.quetionBankList?.[this.state.checkedQueue];
    return (
      <>
        <Header navigation={this.props.navigation} id={"3"} />
        {this.renderLeaveDialog()}
        <Box className="buttonBox" data-test-id="buttonBoxTestid">
          {this.state.showinfoData &&
            <Box className="tooltipBox">
              <img src={info} />
              <p>Please choose an answer to move forward.</p>
              <img src={cross} />
            </Box>
          }
        </Box>
        <Buttons
          navigation={undefined}
          id={""}
          setup={true}
          onhover={this.handleIconHover}
          onleave={this.handleIconLeave}
          bgImgColor={'linear-gradient(180deg, rgba(85, 145, 245, 0) 0%, rgba(38, 197, 243, 0.25) 50%, rgba(85, 145, 245, 0.5) 100%)'}
          infoTextStyle={"desc-text"}
          backBtn={false}
          goBack={this.goBack}
          btnDisabled={this.state.questionBankBtn}
          onclick={this.handleNextClick}
          isQuestionBank={true}
          leaveClick={this.handleLeave}
          showinfoData={this.state.showInfo}
          isNextBtn={this.state.isNextBtnVisible}
          customForm={
            this.renderDots()
          }
          formContent={
            <>
              <div className="questionNum">Question #{this.state.checkedQueue + 1}</div>
              <div className="question">{checkedData?.attributes?.question}</div>
              <div className="mdjourney-container" data-testid="mdJomdJourneyContainer">

                {this.state.quetionBankList?.[this.state.checkedQueue]?.attributes.options.data.map((mdJourney: QuestionOption, index: any) => {
                  const possible_answer = mdJourney.attributes.possible_answer
                  const isSelected = this.state.selectedQuestion === possible_answer;
                  const wrapperClass = this.getWrapperClass(mdJourney, index);
                  const letter = String.fromCharCode(65 + index).toLocaleLowerCase();
                  return (
                    <div key={mdJourney.id} className={wrapperClass}>
                      <span className={`${isSelected ? 'mdJourney-icon  mdJourney-selected-icon' : 'mdJourney-icon'}`}
                      data-testid="mdIcon">
                        {`${letter})`}
                      </span>
                      <label
                        htmlFor={`mdJourney-${index}`}
                        data-testid="mdLabel"
                        className={`${isSelected ? 'mdJourney-label  mdJourney-selected-label' : 'mdJourney-label'}`}
                      >
                        {possible_answer}
                        <input
                          type="radio"
                          id={`mdJourney-${index}`}
                          name="mdJourney"
                          value={possible_answer}
                          className="radioBtn"
                          onChange={(e) => this.handleQuestionChange(e, mdJourney.id)}
                          checked={isSelected}
                          data-testid={`${index}-mdJourney`}
                          disabled={this.state.isAnswerChecked}
                        />
                      </label>
                    </div>
                  )
                })}
              </div>
              <Box style={{ transform: "translateY(20px)", display: this.props.btnDisplay }} className="nextBtnWrapper">
                <button
                  type="button"
                  onClick={this.handleModalOpen}
                  className="leaveButton"
                >
                  <img src={leave} />
                  Leave
                </button>
                <Box
                  onMouseLeave={() => this.handleUnhover()}
                  onMouseEnter={() => this.handleHover()}
                  style={{ display: 'inline-block' }}
                >
                  <button
                    type="button"
                    // onClick={()=>this.handleNextClick()}
                    onClick={this.state.isNextBtnVisible ? this.handleNextClick : this.checkAnswer}
                    className="Nextbutton"
                    style={{ background: !this.state.disabled ? "rgba(114, 239, 221, 0.5)" : "linear-gradient(0deg, #72EFDD, #72EFDD)", color: !this.state.disabled ? '#ffffff' : '#1E293B' }}
                    disabled={!this.state.disabled}
                    data-test-id="submitButton2"
                  >
                    {
                      this.state.isNextBtnVisible ?
                        (this.state.checkedQueue < this.state.quetionBankList?.length - 1) ?
                          "Next" : 'Finish' : "Check"
                    }
                  </button>
                </Box>
                <Box className="buttonBox" data-test-id="buttonBoxTestid">
                  {this.state.showinfoData &&
                    <Box className="tooltipBox">
                      <img src={info} />
                      <p>Please choose an answer to move forward.</p>
                      <img src={cross} />
                    </Box>
                  }
                </Box>
              </Box>
            </>
          }
        />

      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default QuestionBank;
export { QuestionBank };
// Customizable Area End
