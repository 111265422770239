import React from "react";

import {
  Box,
  Button,
  Switch, FormControlLabel,
  Grid,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  neurology,
  Bulb,
  Spacks,
  filter,
  question,
  exam,
  skip_icon,
  upwardCategory,
  downwardCategory,
  arrow_downward,
  arrow_upward,
  topone,
  toptwo,
  topthree,
  bookLight,
} from "./assets";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import CircularProgress from '@material-ui/core/CircularProgress';
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import Footer from "./Footer.web";
import "./Header.css";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AddIcon from "@material-ui/icons/Add";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ScheduleIcon from "@material-ui/icons/Schedule";
import GraphChart from "./GraphChart";
import SwipeableViews from "react-swipeable-views";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Chart } from "react-google-charts";
// Customizable Area End

import Catalogue1Controller, { Props } from "./Catalogue1Controller";
import Header from "./Header.web";
import { MedEdSkeleton } from "../../../components/src/Skeletons";

export default class KnowledgeTrainingCenter extends Catalogue1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderPaginationDots = () => {
    const { questions, questionsPerPage } = this.state;
    const totalItems = questions.length + 1;
    const pageCount = Math.ceil(totalItems / questionsPerPage);

    return (
      <Box
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {Array.from({ length: pageCount }).map((_, index) => (
          <Button
            key={index}
            onClick={() => this.handlePageChange(index)}
            style={{
              backgroundColor:
                this.state.currentPage === index ? "#6200EA" : "#e0e0e0",
              borderRadius: "50%",
              width: "10px",
              height: "10px",
              margin: "5px",
            }}
          />
        ))}
      </Box>
    );
  };

  updateGlow = (rotate: number, color: string) => {
    this.setState({
      glowParams: {
        rotate,
        color,
      },
    });
  };

  getBackground = () => {
    const { color } = this.state.glowParams;
    return `radial-gradient(
      20.95% 20.95% at 50.16% 20.95%,
      #ffffff 0%,
      ${color} 30%,
      #0f172a00 130%
    )`;
  };

  formatContinueLearningDuration = (duration: string) => {
    const [hours, minutes] = duration.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const formattedHours = Math.floor(totalMinutes / 60);
    const formattedMinutes = totalMinutes % 60;
    return `${formattedHours}h ${formattedMinutes}min`;
  };
  continueLearning = () => {
    return (
      <Box className="cardMainContainer">
        <Box style={webStyle.rowDirection}>
          <h4 style={webStyle.subHeadingText} data-testid="continue-learning">
            Continue Learning
          </h4>
          <Box
            style={{
              textTransform: "capitalize",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
              justifyContent: "space-around",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img src={filter} style={{ paddingLeft: "10px" }} />
            <Button
              style={{
                textTransform: "capitalize",
                fontWeight: 300,
                color: "white",
                fontSize: "14px",
              }}
            >
              {" "}
              Filter
            </Button>
          </Box>
        </Box>
        <Box className="CradBox">
          {this.state.continueLearning.map((card: any) => {
            const continueCard = card.attributes;
            return (
              <Box className="cardMapContainer">
                <Box style={{ position: "relative" }}>
                  <img
                    src={continueCard.image}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                    }}
                    alt="Our Image"
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: "24px",
                      left: "25px",
                      width: "88%",
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        style={{
                          fontStyle: "italic",
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#FFFFFF",
                        }}
                      >
                        {continueCard.first_unit_name}
                      </Typography>
                      <Box>
                        <Typography className="cardTitle">
                          {continueCard.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        alignItems: "end",
                        display: "flex",
                        overflow: "hidden",
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<PlayCircleFilledIcon />}
                        size="small"
                        style={{
                          color: "white",
                          textTransform: "capitalize",
                          backgroundColor: "rgba(205, 205, 205, 0.5)",
                          fontSize: "12px",
                          height: "24px",
                          width: "94px",
                          borderRadius: "20px",
                        }}
                      >
                        Preview
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    padding: "10px 20px",
                    marginTop: "-5px 5px",
                    borderBottomLeftRadius: "16px",
                    backgroundColor: "#172130",
                    borderBottomRightRadius: "16px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <LibraryBooksIcon
                        style={{ color: "white", marginRight: 5 }}
                        fontSize="small"
                      />
                      <Typography
                        style={{
                          color: "#FFFFFF",
                          fontFamily: "Raleway",
                          fontSize: "13px",
                        }}
                      >
                        {continueCard.completed_units.length > 0}
                        {continueCard.completed_units.length > 0 ? "/" : null}
                        {continueCard.all_units_count} courses
                      </Typography>
                    </Box>
                    <Box style={{ display: "flex" }}>
                      <ScheduleIcon
                        fontSize="small"
                        style={{ color: "white", marginRight: 5 }}
                      />
                      <Typography
                        style={{ color: "#FFFFFF", fontSize: "13px" }}
                      >
                        {this.formatContinueLearningDuration(
                          continueCard.duration
                        )}
                      </Typography>
                    </Box>
                    <Box
                      fontSize="small"
                      style={{ display: "flex", marginRight: 5 }}
                    >
                      <PeopleOutlineOutlinedIcon style={{ color: "white" }} />
                      <Typography
                        style={{ fontSize: "13px", color: "#FFFFFF" }}
                      >
                        level {continueCard.level}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      marginTop: "20px",
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div style={{ overflow: "auto", height: "100px" }}>
                      <Typography
                        style={{ fontSize: "14px", color: "#FFFFFF" }}
                      >
                        {continueCard.description}
                      </Typography>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{
                          color: "white",
                          borderRadius: "20px",
                          bottom: "10px",
                          background:
                            "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
                        }}
                      >
                        Enroll
                      </Button>
                    </div>
                  </Box>
                  <Box style={{ textAlign: "center" }} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };
  onStartQuestionBank = () => {
    const { questions, currentPage, questionsPerPage } = this.state;
    const totalItems = [{ isNewTask: true }, ...questions];
    const pageCount = Math.ceil(totalItems.length / questionsPerPage);
    const currentQuestions = Array.from(
      { length: pageCount },
      (_, pageIndex) => {
        const startIndex = pageIndex * questionsPerPage;
        return totalItems.slice(startIndex, startIndex + questionsPerPage);
      }
    );

    return (
      <div className="mainQueSimulateContainer" data-testid="main-container">
        <Box
          className="QuestionsContainer"
          data-testid="questions-container"
          style={{ width: "100%" }}
        >
          <SwipeableViews
            index={currentPage}
            onChangeIndex={(index: any) => this.handlePageChange(index)}
            enableMouseEvents
            className="swipedata"
          >
            {currentQuestions.map((questionsGroup, groupIndex) => (
              <Box
                key={groupIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                {questionsGroup.map((qu: any, index: number) => {
                  if (qu.isNewTask) {
                    return (
                      <Box className="newTaskQu" data-testid="new-task">
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <Button
                            data-testid="start-question-bank-button"
                            style={{
                              background:
                                "linear-gradient(90deg, rgba(97,32,201,1) 0%, rgba(64,93,219,1) 34%, rgba(49,120,227,1) 58%, rgba(0,212,255,1) 100%)",
                              borderRadius: "50%",
                              marginBottom: "20px",
                              padding: "0",
                              width: "50px",
                              height: "50px",
                              minWidth: "42px",
                            }}
                          >
                            <AddIcon style={{ color: "white" }} />
                          </Button>
                          <Box
                            style={{
                              textTransform: "capitalize",
                              borderRadius: "34px",
                              borderTop: 10,
                              opacity: "50%",
                            }}
                          >
                            <Typography
                              data-testid="new-task-text"
                              style={{
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "rgba(255,255,255,1)",
                                textAlign: "center",
                              }}
                            >
                              Start a new Question Bank!
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              textTransform: "capitalize",
                              borderRadius: "34px",
                              opacity: "50%",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 300,
                                fontSize: "14px",
                                color: "rgba(255,255,255,1)",
                              }}
                            >
                              Click here to begin <br />
                              customizing your quiz.
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  }

                  const Que = qu.attributes;
                  return (
                    <Box
                      className="quesbanks"
                      data-testid="question-box"
                      style={{
                        backgroundColor: " #172130",
                        width: "auto",
                        height: "416px",
                        borderRadius: "20px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "165px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "rgba(30, 41, 59, 0.5)",
                          overflow: "hidden",
                          borderRadius: "19px 19px 0px 0px",
                          border: "1px solid rgba(30,41,59,1)",
                        }}
                      >
                        <Button
                          style={{
                            background: "rgba(51, 65, 85, 0.6)",
                            borderRadius: "50%",
                            marginBottom: "20px",
                            padding: "0",
                            width: "48px",
                            height: "48px",
                            minWidth: "48px",
                          }}
                        >
                          <img
                            src={exam}
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "white",
                            }}
                          />
                        </Button>

                        <span
                          style={{
                            fontWeight: 900,
                            fontSize: "20px",
                            color: "white",
                          }}
                        >
                          {Que.name}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            padding: "5px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "rgba(255, 255, 255, 1)",
                              fontWeight: 300,
                              fontSize: "12px",
                            }}
                            className="cardtext"
                          >
                            {Que.id} questions .{" "}
                          </span>
                          <div
                            style={{
                              backgroundColor: "rgba(114, 239, 221, .4)",
                              padding: "5px 7px",
                              borderRadius: "20px",
                              color: "white",
                              fontWeight: 800,
                              fontSize: "12px",
                              opacity: "100%",
                              marginLeft: "5px",
                            }}
                            className="cardtext"
                          >
                            100/100
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style={{ margin: "20px" }}>
                          <div
                            style={{ marginBottom: "15px", display: "flex" }}
                          >
                            <img src={skip_icon} />
                            <div
                              style={{
                                backgroundColor: "white",
                                height: "20px",
                                width: "1px",
                                marginLeft: "10px",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "10px",
                              }}
                            >
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  fontWeight: 300,
                                }}
                              >
                                JOURNEY
                              </span>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                  fontWeight: 400,
                                }}
                              >
                                {Que.category}
                              </span>
                            </div>
                          </div>

                          <div
                            style={{ display: "flex", marginBottom: "15px" }}
                          >
                            <img src={skip_icon} />
                            <div
                              style={{
                                backgroundColor: "white",
                                marginLeft: "10px",
                                height: "20px",
                                width: "1px",
                              }}
                            />
                            <div
                              style={{
                                flexDirection: "column",
                                marginLeft: "10px",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 300,
                                  color: "white",
                                  fontSize: "10px",
                                }}
                              >
                                COURSE
                              </span>
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 400,
                                  color: "white",
                                }}
                              >
                                {Que.course}
                              </span>
                            </div>
                          </div>

                          <div
                            style={{ display: "flex", marginBottom: "15px" }}
                          >
                            <img src={skip_icon} />
                            <div
                              style={{
                                marginLeft: "10px",
                                backgroundColor: "white",
                                width: "1px",
                                height: "20px",
                              }}
                            />
                            <div
                              style={{
                                marginLeft: "10px",
                                flexDirection: "column",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 300,
                                  fontSize: "10px",
                                  color: "white",
                                }}
                              >
                                UNIT
                              </span>
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 400,
                                  color: "white",
                                }}
                              >
                                {Que.unit}
                              </span>
                            </div>
                          </div>

                          <div
                            style={{ marginBottom: "15px", display: "flex" }}
                          >
                            <img src={skip_icon} />
                            <div
                              style={{
                                height: "20px",
                                backgroundColor: "white",
                                marginLeft: "10px",
                                width: "1px",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "10px",
                              }}
                            >
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  fontWeight: 300,
                                }}
                              >
                                LESSON
                              </span>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                  fontWeight: 400,
                                }}
                              >
                                {Que.lesson}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: "5px 20px",
                          }}
                        >
                          <Button
                            className="quebankbtns"
                            variant="contained"
                            fullWidth
                            style={{
                              color: "white",

                              background:
                                "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
                              borderRadius: "20px",
                              height: "32px",
                              padding: "2px",
                              overflow: "hidden",
                              letterSpacing: ".7px",
                              textTransform: "capitalize",
                            }}
                          >
                            Retry Quiz
                          </Button>

                          <Button
                            fullWidth
                            data-test-id="ViewResult"
                            className="button-with-gradient-border quebankbtns"
                            variant="outlined"
                            style={{
                              borderRadius: "100px",
                              height: "32px",
                              padding: "2px",
                              textTransform: "capitalize",
                              color: "white",
                              fontWeight: 800,
                              background:
                                "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                              overflow: "hidden",
                              marginLeft: "5px",
                            }}
                          >
                            <span
                              style={{
                                width: "100%",
                                height: "28px",
                                backgroundColor: "#1E293B",
                                borderRadius: "100px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              View Results
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Box>
                  );
                })}
              </Box>
            ))}
          </SwipeableViews>
          <div className="paginationdots">{this.renderPaginationDots()}</div>
        </Box>
      </div>
    );
  };

  taskSelection = () => {
    const { glowParams } = this.state;

    return (
      <div className="taskContainer">
        <div>
          <h2
            className="welcomeTxt"
            style={{ width: "80%", display: "flex", alignItems: "center" }}
          >
            My Services
            <ArrowRightIcon style={{ margin: "0 10px" }} />
            <span className="subMenuText">Knowledge & Training Center</span>
          </h2>
          <span
            className="welcomeBottom-border"
            style={{ display: "block", width: "80%" }}
          />
        </div>

        <div className="questionImg">
          <img src={question} style={webStyle.imgQ} />
        </div>
        <div className="UpperKnowledgeContainer">
          <div style={{ marginTop: "-100px" }} className="img-container">
            <Box className="knowledgeLineContainer">
              {glowParams.rotate !== -1 && (
                <div
                  style={{
                    transform: `rotate(${glowParams.rotate}deg)`,
                    background: this.getBackground(),
                  }}
                  data-test-id="GlowBtn"
                  className="Glow"
                />
              )}

              <div>
                <Box className="backShadowFirst catalogue">
                  <div
                    className="firstBtn commonBox"
                    style={{
                      boxShadow:
                        this.state.currentGlow == "firstBox"
                          ? "rgba(114, 239, 221, 0.5) 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px"
                          : "rgba(114, 239, 221, 0.6) 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px",
                      opacity:
                        this.state.currentGlow == "firstBox" ? "2" : "1.9",
                    }}
                    onMouseEnter={() =>
                      this.setState({ currentGlow: "firstBox" })
                    }
                    onMouseLeave={() => this.setState({ currentGlow: "" })}
                    data-test-id="firstButton"
                  >
                    <img
                      src={neurology}
                      alt="neurology"
                      className="centerIconSize"
                    />
                  </div>

                  <Box style={{ width: "130px", color: "#FFFFFF" }}>
                    <Typography className="centerIcon">
                      {" "}
                      Knowledge <br />& Training Center
                    </Typography>
                  </Box>
                </Box>

                <Box className="positionKnowledgeSecond">
                  <div
                    style={{
                      boxShadow:
                        this.state.currentGlow == "secondBox"
                          ? "#B429F9 0px 40px 50px 0px"
                          : "none",
                      opacity:
                        this.state.currentGlow == "secondBox" ? "0.88" : "0.89",
                      borderWidth: "1",
                      background:
                        "linear-gradient(to right, rgb(53, 53, 53) 0%, rgb(255, 255, 255,0.48) 100%)",
                      padding: "1",
                      //border:'1px solid rgba(166, 161, 161, 0.65)'
                    }}
                    onMouseEnter={() =>
                      this.setState({ currentGlow: "secondBox" })
                    }
                    onMouseLeave={() => this.setState({ currentGlow: "" })}
                    className="secondBtn smallBtn"
                    data-test-id="secondButton"
                  >
                    <img src={Bulb} alt="Bulb" className="leftIconSize" />
                  </div>
                  <Box className="secondBtnKnowledgeTitle">
                    <Typography
                      style={{ fontSize: "12px", width: "125" }}
                      className="leftIcon"
                    >
                      {" "}
                      Healthcare Innovation & Entrepreneurship
                    </Typography>
                  </Box>
                </Box>

                <Box className="positionKnowledgeThird">
                  <div
                    className="thirdBtn smallBtn"
                    style={{
                      boxShadow:
                        this.state.currentGlow == "thirdBox"
                          ? "#26C5F3 0px 40px 50px 0px"
                          : "none",
                      borderWidth: "1",
                      opacity:
                        this.state.currentGlow == "thirdBox" ? "0.88" : "0.89",
                      background:
                        "linear-gradient(to right, rgb(255, 255, 255,0.48) 0%, rgb(53, 53, 53) 100%)",
                      //border:'1px solid rgba(166, 161, 161, 0.65)'
                    }}
                    onMouseEnter={() =>
                      this.setState({ currentGlow: "thirdBox" })
                    }
                    onMouseLeave={() => this.setState({ currentGlow: "" })}
                    data-test-id="thirdButton"
                  >
                    <img src={Spacks} alt="Spacks" className="rightIconSize" />
                  </div>
                  <Box className="thirdBtnTitle">
                    <Typography
                      style={{ fontSize: "12px" }}
                      className="rightIcon"
                    >
                      {" "}
                      Healthcare VR/AR & Gaming
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Box>
          </div>
          <div className="grapharea">{this.stats()}</div>
        </div>
      </div>
    );
  };

  handleTimeRangeChange = (
    event: SelectChangeEvent<"day" | "week" | "month" | "year">
  ) => {
    this.setState({
      selectedTimeRange: event.target.value as
        | "day"
        | "week"
        | "month"
        | "year",
    });
  };
  data = [
    ["Year", "Sales"],
    ["Mo", 1],
    ["Tu", 8],
    ["We", 7],
    ["Th", 3],
    ["Fr", 8],
    ["Sa", 1],
    ["Su", 8],
  ];

  options = {
    chart: {
      title: "Company Performance",
      subtitle: "Sales and Expenses over the Years",
    },
    width: 300,
    height: 150,
    colors: ["#72EFDD"],
    curveType: "function",
    lineWidth: 1,
    pointSize: 3,
    backgroundColor: "transparent",
    chartArea: {
      top: 10,
    },
    hAxis: {
      textStyle: {
        color: "#ffffff",
        fontSize: 11,
      },
    },
    vAxis: {
      textStyle: {
        color: "#ffffff",
        fontSize: 12,
      },
    },
  };

  getEntityType = (timeframe: string): string => {
    if (timeframe === "day") {
      return "lessons";
    } else if (timeframe === "week") {
      return "sections";
    } else if (timeframe === "month") {
      return "courses";
    } else if (timeframe === "year") {
      return "journeys";
    }
    return "";
  };

  handleSwipeChange = (index: number) => {
    this.setState({ currentGraphPage: index });
  };

  handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ showFirstSection: event.target.checked });
  };

  stats = () => {
    const {
      selectedTimeRange,
      chartHourDay,
      chartHourWeek,
      chartHourMonth,
      chartHourYear,
      mostPopularCategory,
      currentGraphPage,
      queBankAnalysis,
      showFirstSection
    } = this.state;
    const entityType = this.getEntityType(mostPopularCategory.timeframe);
    let chartData;
    switch (selectedTimeRange) {
      case "day":
        chartData = chartHourDay;
        break;
      case "week":
        chartData = chartHourWeek;
        break;
      case "month":
        chartData = chartHourMonth;
        break;
      case "year":
        chartData = chartHourYear;
        break;
    }

    return (
      <Box
        className={
          "knowledgeStatsContainer"
        }
      >
        <Box style={webStyle.rowDirection} className="statsheading">
          <h4 style={webStyle.subHeadingText}>Knowledge Center Stats</h4>
          <Box
            style={{
              textTransform: "capitalize",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
            }}
          >
            <Select
              className="selectTimeRange"
              id="menuitem1"
              value={selectedTimeRange}
              onChange={this.handleTimeRangeChange}
              MenuProps={{
                PaperProps: {
                  id: "menu-stats",
                  style: {
                    marginTop: "10px",
                  },
                },
              }}
              style={{
                borderRadius: "20px",
                color: "white",
                backgroundColor: "rgba(255,255,255,.1)",
              }}
            >
              <MenuItem
                value="day"
                data-testid="onChartHourDay"
                onClick={() => {
                  this.onChartHourDay();
                  this.onmostPopular("day");
                  this.onTopBestContent("day");
                  this.onQueBankAnalysis("day");
                }}
              >
                Today
              </MenuItem>
              <MenuItem
                value="week"
                data-testid="onChartHourWeek"
                onClick={() => {
                  this.onChartHourWeek();
                  this.onmostPopular("week");
                  this.onTopBestContent("week");
                  this.onQueBankAnalysis("week");
                }}
              >
                This week
              </MenuItem>
              <MenuItem
                value="month"
                data-testid="onChartHourMonth"
                onClick={() => {
                  this.onChartHourMonth();
                  this.onmostPopular("month");
                  this.onTopBestContent("month");
                  this.onQueBankAnalysis("month");
                }}
              >
                This month
              </MenuItem>
              <MenuItem
                value="year"
                data-testid="onChartHourYear"
                onClick={() => {
                  this.onChartHourYear();
                  this.onmostPopular("year");
                  this.onTopBestContent("year");
                  this.onQueBankAnalysis("year");
                }}
              >
                This year
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <SwipeableViews
          index={currentGraphPage}
          onChangeIndex={this.handleSwipeChange}
          enableMouseEvents
        >
          <div className="maingraphsection">
            <GraphChart
              chartHourData={chartData}
              selectedTimeRange={selectedTimeRange}
            />
          </div>
          <div className="maingraphsection">
            <div className="contentgraph">

              <FormControlLabel
                control={
                  <Switch
                    checked={showFirstSection}
                    onChange={this.handleToggle}
                    color="primary"
                    inputProps={{ "aria-label": "Toggle sections" }}
                    className="queSwitch"
                  />
                }
                label={""}
              />
              {showFirstSection
                ?
                <>
                  <div className="leftgraph">
                    <h5 className="graphheader">Question Bank Performance</h5>
                    <div className="piechartgraph">
                      <div className="donut-chart" style=
                        {{
                          background:
                            `conic-gradient(#5591F5 0% ${queBankAnalysis.correct_percentage}% , 
#72EFDD ${queBankAnalysis.correct_percentage}% 100%)`
                        }}>

                        <div className="donut-hole" >
                          <div className="donutdata" >
                            <p className="totalque">{queBankAnalysis.correct_count + queBankAnalysis.incorrect_count}
                            </p>
                            <p> Questions</p>
                          </div></div>
                      </div>
                      <div className="piedata">
                        <div className="correctdata">
                          <p>
                            {" "}
                            <span className="dotcorrect" />
                            <span>Correct</span>
                          </p>
                          <p>{queBankAnalysis.correct_percentage} %</p>
                        </div>
                        <div className="correctdata">
                          <p>
                            {" "}
                            <span className="dotincorrect" />
                            <span>Incorrect</span>
                          </p>
                          <p>{queBankAnalysis.incorrect_percentage} %</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rightgraph p-2">
                    <h5 className="graphheader">Error Analysis</h5>
                    <div className="horizontalbar">
                      {queBankAnalysis.question_category_percentage &&
                        (() => {
                          const counts = queBankAnalysis.question_category_percentage as Record<string, number>;
                          const maxCount = Math.max(...Object.values(counts));


                          return Object.entries(counts).map(([category, count]) => {
                            const calculatedWidth = count === 0 ? "1.5px" : `${(count / maxCount) * 100}%`;
                            return (
                              <div className="innersection innersection2" key={category}>
                                <p className="errorsname">
                                  <span className="errorspan">{category}</span>
                                </p>
                                <span className="errornum">{count}%</span>
                                <div className="graphareabar">
                                  <div
                                    className="widthfill"
                                    style={{
                                      width: calculatedWidth,
                                      backgroundColor: "#5591F5",
                                      height: "12px",
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          }


                          );

                        })()}
                    </div>
                  </div>
                </>

                :
                <>
                  <div className="leftgraph">
                    <h5 className="graphheader">Question Bank Performance</h5>
                    <div className="piechartgraph">
                      <div className="donut-chart" style=
                        {{
                          background:
                            `conic-gradient(#5591F5 0% ${queBankAnalysis.correct_percentage}% , 
  #72EFDD ${queBankAnalysis.correct_percentage}% 100%)`
                        }}>

                        <div className="donut-hole" >
                          <div className="donutdata" >
                            <p className="totalque">{queBankAnalysis.correct_count + queBankAnalysis.incorrect_count}
                            </p>
                            <p> Questions</p>
                          </div></div>
                      </div>
                      <div className="piedata">
                        <div className="correctdata">
                          <p>
                            {" "}
                            <span className="dotcorrect" />
                            <span>Correct</span>
                          </p>
                          <p>{queBankAnalysis.correct_count}</p>
                        </div>
                        <div className="correctdata">
                          <p>
                            {" "}
                            <span className="dotincorrect" />
                            <span>Incorrect</span>
                          </p>
                          <p>{queBankAnalysis.incorrect_count}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rightgraph p-2">
                    <h5 className="graphheader">Error Analysis</h5>
                    <div className="horizontalbar">
                      {
                        queBankAnalysis.question_category_count &&
                        (() => {
                          const counts = queBankAnalysis.question_category_count as Record<string, number>;
                          const maxCount = Math.max(...Object.values(counts));

                          return Object.entries(counts).map(([category, count]) => {
                            const calculatedWidth = count === 0 ? "1.5px" : `${(count / maxCount) * 100}%`;
                            return (
                              <div className="innersection" key={category}>
                                <p className="errorsname">
                                  <span className="errorspan">{category}</span>
                                </p>
                                <span className="errornum">{count}</span>
                                <div className="graphareabar">
                                  <div
                                    className="widthfill"
                                    style={{
                                      width: calculatedWidth,
                                      backgroundColor: "#5591F5",
                                      height: "12px",
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          }

                          );
                        })()}

                    </div>
                  </div>
                </>
              }

            </div>
          </div>
          <div className="maingraphsection">
            <div className="contentgraph">
              <div
                className="leftgraph"
                style={{ position: "relative", width: "49%" }}
              >
                <h5 className="graphheader">Sections Completed</h5>
                <div className="linechart" data-testid="linechart">
                  <Chart
                    chartType="LineChart"
                    width="100%"
                    height="auto"
                    data={this.data}
                    options={this.options}
                  />
                </div>
              </div>
              <div
                className="rightgraph p-2"
                style={{ position: "relative", width: "49%" }}
              >
                <h5 className="graphheader">Top Best Sections</h5>
                <div className="topbestarea" style={{ marginTop: "15px" }}>
                  <ul className="topbestimg">
                    <li>
                      <img src={topone} />
                    </li>
                    <li>
                      <img src={toptwo} />
                    </li>
                    <li>
                      <img src={topthree} />
                    </li>
                  </ul>
                  <ul style={{ width: "100%" }}>
                    {this.state.topBestContent.map((ele: any, i: any) => {
                      const percentage = ele.completion_percentage;
                      const title = ele.unit_title;
                      const title2 = ele.course_title;

                      return (
                        <li className="topbestlist" data-testid="topbestlist" key={i}>
                          <div style={{ display: "flex" }}>

                            {(this.state.bestContentType === 'day' || this.state.bestContentType === 'week')
                              ? <p data-testid="daytext">{title}</p> : <p data-testid="monthtext">{title2}</p>}
                          </div>
                          <div className="sectionnumber">
                            <span>{percentage}</span>
                          </div>
                        </li>
                      )
                    }



                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="maingraphsection">
            <div className="contentgraph">
              <div className="populargraph">
                <div className="popularCat">
                  <div style={{ display: "flex", position: "relative" }}>
                    <h5 className="graphHeader">
                      Your Most Popular Categories
                    </h5>

                    <h6 className="entityone">{entityType}</h6>
                    <h6 className="entitytwo">{entityType}</h6>
                  </div>
                  <div className="popularinner">
                    <div className="rightbest">
                      <div className="popularimg">
                        <img
                          src={upwardCategory}
                          alt="increasing"
                          className="uparrow"
                        />
                        <img
                          src={arrow_upward}
                          alt="up"
                          className="uparrow-sm"
                        />
                      </div>
                      <div className="popularimg2">
                        <img
                          src={downwardCategory}
                          alt="increasing"
                          className="downarrow"
                        />
                        <img
                          src={arrow_downward}
                          alt="up"
                          className="downarrow-sm"
                        />
                      </div>
                      {this.state.mostPopularCategory.top_parent_categories &&
                        this.state.mostPopularCategory.top_parent_categories.map(
                          (title: any) => {
                            return (
                              <div
                                className="popular-heading"
                                data-testid="mostPop"
                              >
                                <img
                                  src={title.parent_category_icon_url}
                                  alt="icon"
                                  data-testid="mostPopimg"
                                />
                                <p data-testid="mostPopHeading">
                                  {title.parent_category_name}
                                </p>
                                <div className="section-cat">
                                  <span data-testid="mostPopcount">
                                    {" "}
                                    {title.completed_lessons_count ||
                                      title.completed_units_count ||
                                      title.completed_courses_count ||
                                      title.completed_categories_count ||
                                      "0"}{" "}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwipeableViews>
        <div className="pagination-dots">
          {[0, 1, 2, 3].map((index) => (
            <span
              key={index}
              data-testid={`pagedot-${index}`}
              className={`dot ${currentGraphPage === index ? "active" : ""}`}
              onClick={() => {
                if (currentGraphPage !== index) {
                  this.setState({ currentGraphPage: index });
                }
              }}
            />
          ))}
        </div>
      </Box>
    );
  };

  renderMedEdSkeleton = () => {
    return (
      <Box className="cardMainContainer" style={{ marginTop: "50px" }}>
        <Box style={webStyle.rowDirection}>
          <h4 style={{ ...webStyle.subHeadingText, marginLeft: "10px" }}>
          MedEd Journeys
          </h4>
        </Box>
          <MedEdSkeleton />
      </Box>
    );
  };

  medEdJourney = () => {
  if(this.state.medEdJourneyKnowledgeLists.length == 0){
    return this.renderMedEdSkeleton();
  }
    return (
      <Box
        className="medMainContainer"
        style={{ marginTop: "50px" }}

      >
        <Box style={webStyle.rowDirection}>
          <h4 className="cursorpointer" datat-test-id="clicknext" style={{ ...webStyle.subHeadingText, marginLeft: "10px" }}
            onClick={() => this.onMedEdJourney()}>
            MedEd Journeys
          </h4>
          <Box
            style={{
              textTransform: "capitalize",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
              justifyContent: "space-around",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
              position: 'relative'
            }}
            onClick={this.handleStopPropogateFilter}
          >
            <img src={filter} style={{ paddingLeft: "10px" }} />
            <Button
              style={{
                textTransform: "capitalize",
                fontWeight: 300,
                color: "white",
                fontSize: "14px",
                marginRight: "10px",
              }}
            >
              {" "}
              Filter
            </Button>
            {this.state.isFilterOpen && (
              <div
                ref={this.popupRef}
                onClick={this.handleStopPropogate}
                style={{
                  zIndex: 99, position: 'absolute', top: '26px', right: '-40px', width: '640px',
                  padding: '20px',
                  borderRadius: '20px',
                  color: '#fff'
                }}>
                <div className="filter_header">
                  <div style={{ display: 'flex', gap: 8 }}>
                    <img src={filter} style={{ paddingLeft: "10px" }} />
                    <p style={{ fontWeight: 'bold' }}>Filter by</p>
                  </div>
                </div>
                <div className="filter_background">
                  <p style={{ fontWeight: 600, fontSize: '15px' }}>Category</p>
                  <div className="under_border" />
                  <div className="row" style={{ alignItems: 'center' }}>
                    {Array.from(new Map(this.state.medEdJourneyLists.map((ele: any) => [ele.attributes.name, ele])).values())
                      .map((ele: any) => (
                        <div className="column" key={ele.attributes.id}>
                          <div className="checkWrapper">
                            <input
                              checked={this.state.selectedCategoryKMed == ele.attributes.id}
                              onChange={this.handleCategoryChange}
                              type="radio"
                              id={ele.attributes.name}
                              name="med_category"
                              value={ele.attributes.id}
                            />
                            <label htmlFor={ele.attributes.name} style={{ fontSize: '12px' }}>
                              {ele.attributes.name}
                            </label>
                          </div>
                        </div>
                      ))}

                    <div className="column">
                      <div className="checkWrapper">
                        <input checked={this.state.selectedCategoryKMed == 'all categories'} onChange={this.handleCategoryChange} type="radio" id='all categories' name="med_category" value='all categories' />
                        <label htmlFor='all categories' style={{ fontSize: '12px' }}>All Categories</label>
                      </div>
                    </div>


                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <p style={{ fontWeight: 600, fontSize: '15px' }}>Level</p>
                    <div className="under_border" />
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                      {Array.from(new Set(this.state.medEdJourneyKnowledgeLists.map((ele: any) => ele.attributes.level)))
                        .map((level: any) => (
                          <div className="checkWrapper" style={{ padding: '6px 10px' }} key={level}>
                            <input
                              checked={this.state.selectedLevelKMed == level}
                              onChange={this.handleLevelChange}
                              type="radio"
                              id={level}
                              name="med_level"
                              value={level}
                            />
                            <label htmlFor={level} style={{ fontSize: '12px' }}>
                              Level <span style={{ fontSize: '14px' }}>{level}</span>
                            </label>
                          </div>
                        ))}
                      <div className="checkWrapper" style={{ padding: '6px 10px' }} >
                        <input
                          checked={this.state.selectedLevelKMed == 'all levels'}
                          onChange={this.handleLevelChange}
                          type="radio"
                          id='all levels'
                          name="med_level"
                          value='all levels'
                        />
                        <label htmlFor='all levels' style={{ fontSize: '12px' }}>
                          All Levels
                        </label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )}
          </Box>

        </Box>
        {this.state.loading ?
          <Box style={{ textAlign: "center" }}>
            <CircularProgress /> 
          </Box>
          :
          <Grid
            container
            spacing={3}
            className="CradBox overall_card"
            data-test-id="recommendedCard"
            style={{
              overflowX: "auto",
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
            onClick={(e) => this.handleStopPropogate(e)}
          >
            <>
              {this.state.medEdJourneyKnowledgeLists.map(
                (card: any, index: any) => {
                  const medEdCard = card.attributes;
                  return (
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                      style={{ flexShrink: 0 }}
                      key={index}
                      data-testid={`card-know-med-${index}`}
                    >
                      <Box className="cardMapContainer" style={{ width: "100%" }}>
                        <Box style={{ position: "relative", padding:'0px 5px' }}>
                          <img
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                              borderTopLeftRadius: "16px",
                              borderTopRightRadius: "16px",
                              opacity: "0.5",
                            }}
                            src={medEdCard?.image?.url}
                            alt="Our Image"
                          />
                          <Box
                            style={{
                              position: "absolute",
                              bottom: "24px",
                              left: "25px",
                              width: "88%",
                              justifyContent: "space-between",
                              display: "flex",
                            }}
                          >
                            <Box>
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    background: "rgba(51, 65, 85, 0.6)",
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <img
                                    src={medEdCard?.parent_category_icon?.url}
                                    style={{
                                      color: "white",
                                      width: "90%",
                                      height: "90%",
                                      objectFit: "contain",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    color: "rgba(255, 255, 255, 0.6)",
                                    marginLeft: 5,
                                    textTransform: "uppercase",
                                  }}
                                  variant="body1"
                                >
                                  {medEdCard?.parent_category_name}
                                </Typography>
                              </div>

                              <Box>
                                <Typography
                                  className="cardTitle"
                                  style={{ minHeight: "60px" }}
                                >
                                  {medEdCard?.name}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#172130",
                            margin: "-5px 5px",
                            borderBottomLeftRadius: "16px",
                            borderBottomRightRadius: "16px",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 10,
                            }}
                          >
                            <Box style={{ display: "flex" }}>
                              <img
                                src={bookLight}
                                style={{
                                  color: "#fff",
                                  marginRight: 5,
                                  width: 18,
                                  height: 18,
                                }}
                              />
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontFamily: "Raleway",
                                  fontSize: "13px",
                                }}
                              >
                                {medEdCard?.completed_courses &&
                                  medEdCard?.completed_courses > 0 &&
                                  medEdCard?.completed_courses}
                                {medEdCard?.completed_courses &&
                                  medEdCard?.completed_courses > 0
                                  ? "/"
                                  : null}
                                {medEdCard?.all_courses_count > 1
                                  ? `${medEdCard?.all_courses_count} courses`
                                  : `${medEdCard?.all_courses_count} course`}
                              </Typography>
                            </Box>
                            <Box
                              fontSize="small"
                              style={{ display: "flex", marginRight: 5, gap: 4 }}
                            >
                              <PeopleOutlineOutlinedIcon
                                style={{ color: "white" }}
                                fontSize="small"
                              />
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "13px",
                                }}
                              >
                                Level {medEdCard?.level}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              marginTop: "20px",
                              flexDirection: "column",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="course_desc"
                              style={{ overflow: "auto", height: "100px" }}
                            >
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "14px",
                                }}
                              >
                                {medEdCard?.description}
                              </Typography>
                            </div>
                            <div style={{ marginTop: "20px", marginBottom: '10px' }}>
                              {medEdCard?.completed_percentage > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: 10,
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "35px",
                                        height: "35px",
                                        borderRadius: "50%",
                                        position: "relative",
                                        background: `conic-gradient(
                          #5591F5 0% ${medEdCard?.completed_percentage}%, 
                          rgba(255,255,255,.1) ${medEdCard?.completed_percentage}% 100% 
                        )`,
                                      }}
                                    >
                                      <div className="donut-hole-2" />
                                      <div className="donut-data">
                                        {medEdCard?.completed_percentage}%
                                      </div>
                                    </div>
                                    <Typography
                                      style={{ color: "#fff", fontSize: "12px" }}
                                    >
                                      Complete
                                    </Typography>
                                  </div>
                                  <Button
                                    onClick={() => this.handleJourney(card.id)}
                                    className="quebankbtns"
                                    variant="contained"
                                    data-test-id="reviewbtncondition"
                                    style={{
                                      color: "white",
                                      width: "50%",
                                      background:
                                        "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
                                      borderRadius: "20px",
                                      height: "32px",
                                      padding: "2px",
                                      overflow: "hidden",
                                      letterSpacing: ".7px",
                                      textTransform: "capitalize",
                                    }}
                                  >

                                    {medEdCard.completed_percentage && medEdCard.completed_percentage == "100" ? "Review" : "Continue"}
                                  </Button>
                                </div>
                              ) : (
                                <div style={{ padding: "11.5px" }}>
                                  <Button
                                    fullWidth
                                    className="button-with-gradient-border"
                                    variant="outlined"
                                    style={{
                                      borderRadius: "100px",
                                      width: "100%",
                                      height: "32px",
                                      padding: "2px",
                                      textTransform: "capitalize",
                                      color: "white",
                                      fontWeight: 800,
                                      background:
                                        "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                                      overflow: "hidden",
                                    }}
                                    data-test-id="ExploreJourney"
                                    onClick={() => this.handleJourney(card.id)}
                                  >
                                    <span
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#1E293B",
                                        borderRadius: "100px",
                                        height: "28px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      Explore Journey
                                    </span>
                                  </Button>
                                </div>
                              )}
                            </div>
                          </Box>
                          <Box style={{ textAlign: "center" }} />
                        </Box>
                      </Box>
                    </Grid>
                  );
                }
              )}
            </>
          </Grid>
        }
      </Box>
    );
  };

  questionBank = () => {
    const { questions, startNewQuestions } = this.state;
    const hasQuestions = questions && questions.length > 0;

    return (
      <Box
        className={
          !this.state.startNewQuestions
            ? "cardMainContainer"
            : "QueNewContainer"
        }
        data-test-id="boxdataModel"
        style={{ position: "relative" }}
      >
        <Box style={webStyle.rowDirection}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h4 style={webStyle.subHeadingText}>Question Banks</h4>

            <div className="quePopUp">
              <Button
                data-test-id="questionModal"
                //onClick={() => this.setState({ isQuestionModal: true })}
                style={{
                  marginLeft: "5px",
                  paddingTop: "5px",
                  lineHeight: "0px",
                  zIndex: 2,
                  minWidth: "1px",
                }}
              >
                <InfoOutlinedIcon style={{ color: "#2D00F7" }} />
              </Button>
              <div className="popUpMain">
                <Typography
                  style={{ color: "white", fontSize: "14px", fontWeight: 275 }}
                >
                  <b>
                    <i>Question Banks </i>
                  </b>
                  enable you to create and access{" "}
                  <b>
                    <i>custom quizzes</i>
                  </b>{" "}
                  tailored to specific study areas, simulating real exam
                  conditions. Utilize this feature to enhance your preparation,
                  identify knowledge gaps, and track your progress over time.
                </Typography>
              </div>
            </div>
          </div>

          <Box
            style={{
              textTransform: "capitalize",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
              overflow: "hidden",
            }}
          >
            <Button
              variant="outlined"
              style={{
                borderRadius: "34px",
                textTransform: "none",
                fontWeight: 300,
                color: "white",
                fontSize: "14px",
              }}
            >
              {" "}
              This week
            </Button>
          </Box>
        </Box>
        {!startNewQuestions && !hasQuestions ? (
          <div>
            <Box style={webStyle.statsBodyy}>
              <img src={exam} style={{ width: "50px", height: "50px" }} />
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "rgba(255, 255, 255, 1)",
                  opacity: "30%",
                }}
              >
                Ready to test your knowledge? This space is waiting for your
                custom question banks.
              </Typography>
              <Typography
                style={{
                  fontWeight: 300,
                  fontSize: "14px",
                  color: "rgba(255, 255, 255, 1)",
                  opacity: "30%",
                }}
              >
                Create one to simulate real exam conditions and challenge
                yourself.
              </Typography>
              <Button
                data-test-id="newTaskBtn"
                //onClick={() => this.setState({ startNewQuestions: true })}
                className="button-with-gradient-border simulateBtn"
                variant="outlined"
                style={{
                  borderRadius: "100px",
                  padding: "2px",
                  textTransform: "capitalize",
                  color: "white",
                  fontWeight: 800,
                  background:
                    "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                  overflow: "hidden",
                  margin: "30px 32%",
                  zIndex: 2,
                }}
              >
                <span className="spanSimulateBtn">Simulate Question Bank</span>
              </Button>
            </Box>
          </div>
        ) : (
          this.onStartQuestionBank()
        )}
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.backgroundCLr}>
        <Header navigation={this.props.navigation} id={"3"} />

        {this.taskSelection()}
        {this.medEdJourney()}
        {this.state.startNewQuestions && this.continueLearning()}
        {this.questionBank()}
        <Footer navigation={this.props.navigation} id={""} />
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  statsBodyy: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    opacity: "30% !important",
  } as const,
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
  medBody: {
    display: "flex",
  },
  imgIconBack: {
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    background: "rgba(51, 65, 85, 1)",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
  },
  plusBtnStyle: {
    width: "32px !important",
    height: "32px !important",
    color: "white",
    borderRadius: "50%",
    background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
    padding: 5,
  },
  imgQ: {
    width: 14,
    height: 20,
  },
  subHeadingText: {
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(255,255,255,1)",
  },
};
// Customizable Area End
