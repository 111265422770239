import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@mui/material";

interface BaseSkeletonProps {
  variant?: "text" | "rectangular" | "circular";
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
}

const BaseSkeleton: React.FC<BaseSkeletonProps> = ({
  variant = "text",
  width,
  height,
  style = {},
}) => (
  <Skeleton
    variant={variant}
    width={width}
    height={height}
    style={{
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderRadius: "10px",
      ...style,
    }}
  />
);

const CardImageSkeleton = () => (
  <Box style={{ position: "relative" }}>
    <BaseSkeleton
      variant="rectangular"
      width="100%"
      height={200}
      style={{
        borderRadius: "16px 16px 0 0",
      }}
    />
  </Box>
);

const CardInfoSkeleton = () => (
  <Box
    style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}
  >
    <BaseSkeleton width={100} height={20} />
    <BaseSkeleton width={80} height={20} />
  </Box>
);

const CardDescriptionSkeleton = () => (
  <Box style={{ marginTop: "20px" }}>
    {[...Array(3)].map((_, index) => (
      <BaseSkeleton
        key={index}
        width={`${100 - index * 5}%`}
        height={15}
        style={{ marginBottom: index < 2 ? "8px" : 0 }}
      />
    ))}
  </Box>
);

const CardActionSkeleton = () => (
  <Box style={{ marginTop: "20px", marginBottom: "10px" }}>
    <BaseSkeleton
      variant="rectangular"
      width="100%"
      height={32}
      style={{ borderRadius: "20px" }}
    />
  </Box>
);

const InfoRowSkeleton = () => (
  <Box display="flex" alignItems="center" style={styles.infoRow}>
    <BaseSkeleton variant="circular" width={18} height={18} />
    <div style={styles.verticalLine} />
    <Box style={styles.infoContent}>
      <BaseSkeleton variant="text" width={100} />
      <BaseSkeleton variant="text" width={150} />
    </Box>
  </Box>
);

const PreviewCardSkeleton = () => (
  <Box style={styles.previewCard}>
    <Box style={styles.preview}>
      <BaseSkeleton variant="text" width="100%" height="100%" />
    </Box>
    <Box style={styles.courseInfo}>
      <InfoRowSkeleton />
      <InfoRowSkeleton />
      <InfoRowSkeleton />
    </Box>
  </Box>
);

const UnitTitleSkeleton = () => (
  <Box style={styles.courseName}>
    <BaseSkeleton width={200} height={30} />
    <BaseSkeleton width={300} height={40} />
  </Box>
);

const UnitContentSkeleton = () => (
  <Box style={styles.container}>
    <Box style={styles.titleContainer}>
      <BaseSkeleton
        width={200}
        height={75}
        style={{ backgroundColor: "#334155" }}
      />
    </Box>
    <Box style={styles.textContent}>
      {[...Array(5)].map((_, index) => (
        <BaseSkeleton
          key={index}
          height={25}
          style={{
            backgroundColor: "#334155",
            marginBottom: "10px",
          }}
        />
      ))}
    </Box>
    <Box style={styles.videoContainer}>
      <BaseSkeleton
        height={400}
        width={770}
        style={{ backgroundColor: "#334155" }}
      />
    </Box>
  </Box>
);

export const MedEdSkeleton = () => (
  <Grid container spacing={3} className="CradBox" style={styles.gridContainer}>
    {[1, 2, 3].map((index) => (
      <Grid item md={4} sm={4} style={{ flexShrink: 0 }} key={index}>
        <Box className="cardMapContainer" style={{ width: "100%" }}>
          <CardImageSkeleton />
          <Box style={styles.cardContent}>
            <CardInfoSkeleton />
            <CardDescriptionSkeleton />
            <CardActionSkeleton />
          </Box>
        </Box>
      </Grid>
    ))}
  </Grid>
);

export const UnitSkeleton = () => (
  <Box style={styles.mainContent}>
    <Box style={styles.content}>
      <UnitTitleSkeleton />
      <BaseSkeleton height={50} style={styles.progressBar} />
      <UnitContentSkeleton />
      <Box style={styles.btnBox}>
        {[...Array(2)].map((_, index) => (
          <BaseSkeleton
            key={index}
            width={230}
            height={50}
            style={{ borderRadius: "20px" }}
          />
        ))}
      </Box>
    </Box>
  </Box>
);

export const CourseSkeleton = () => (
  <Box style={styles.root}>
    <Box style={styles.courseContainer}>
      <PreviewCardSkeleton />
      <Box style={styles.learningPath}>
        <Box style={styles.learningPathContent}>
          <BaseSkeleton
            variant="rectangular"
            width={800}
            height={548}
            style={{
              borderRadius: "16px",
            }}
          />
        </Box>
      </Box>
    </Box>
  </Box>
);

const styles = {
  cardContent: {
    padding: "20px",
    backgroundColor: "#172130",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
  },
  gridContainer: {
    overflowX: "auto",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginTop: "10px",
  },

  root: {
    display: "flex",
    flexWrap: "wrap" as const,
    background: "#1C2235",
    minHeight: "100vh",
    color: "#fff",
    overflow: "hidden" as const,
    textAlign: "end" as const,
    margin: "0 0 -5% 0",
  },
  courseContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: "30px",
    maxWidth: "1400px",
    margin: "0 auto",
    padding: "0 24px",
    flexDirection: "row" as const,
  },
  previewCard: {
    backgroundColor: "rgb(24,34,50)",
    borderRadius: "20px",
    padding: 0,
    width: "400px",
    height: "auto",
    backdropFilter: "blur(10px)",
    border: "1px solid #4852717A",
    filter: "drop-shadow(0px 1px 2px #000)",
    flex: "0 0 auto",
  },
  preview: {
    position: "relative" as const,
    width: "100%",
    height: "328px",
    backgroundColor: "#FFFFFF0C",
    borderRadius: "20px",
    margin: "0 0 24px 0",
    overflow: "hidden" as const,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  courseInfo: {
    display: "flex",
    flexDirection: "column" as const,
    padding: "0 0 20px 0",
  },

  mainContent: {
    width: "100%",
  },
  content: {
    position: "relative",
  },
  courseName: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    padding: "20px 0",
  },
  container: {
    padding: "40px",
    width: "84%",
    margin: "0 auto",
    background: "linear-gradient(315deg, #94A3B8, #BECBDF, #94A3B8)",
    borderRadius: "20px",
  },

  infoRow: {
    display: "flex",
    alignItems: "center" as const,
    gap: "12px",
    padding: "8px 24px",
  },
  verticalLine: {
    width: "1px",
    height: "24px",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  infoContent: {
    display: "flex",
    flexDirection: "column" as const,
    textAlign: "start" as const,
    gap: "4px",
  },
  learningPath: {
    flex: "1 1 auto",
    backgroundColor: "transparent",
    borderRadius: "16px",
    minWidth: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative" as const,
  },
  learningPathContent: {
    width: "100%",
    maxWidth: "50rem",
    margin: "0 auto",
  },
  progressBar: {
    margin: "20px auto",
    width: "850px",
  },
  titleContainer: {
    marginBottom: "20px",
  },
  textContent: {
    marginTop: "20px",
  },
  videoContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  btnBox: {
    width: "84%",
    padding: "40px",
    margin: "30px auto",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
} as const;

export default {
  MedEdSkeleton,
  UnitSkeleton,
  CourseSkeleton,
};
