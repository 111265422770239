Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.coureseDetailsEndpoint = "bx_block_categories/course_details?sub_category_id";
exports.subCategoryEndpoint = "bx_block_categories/sub_category_by_id?sub_category_id";
exports.unitPercentagesEndpoint = "bx_block_profile/percentage_of_unit?unit_id";
exports.questionBankScoreEndpoint = "bx_block_profile/unit_question_bank_score?unit_id";
exports.unitEndpoint = "bx_block_profile/list_topics?unit_id";
exports.MarkAsCompleteEndpoint = "bx_block_profile/store_completion_data";
exports.certificateEndpoint = "bx_block_certification_tracking/certificate_specific_by_id?sub_category_id";
// Customizable Area End