
import React from "react";
import { Box } from "@material-ui/core";
import {
    KeyboardArrowRightOutlined,
    KeyboardArrowLeftOutlined,
} from "@material-ui/icons";

const UnitProgress:any = () => {
    return (
        <Box className="topic-progress" style={styles.progressWrapper}>
            <Box style={styles.innerWrapper}><Box style={styles.progressContainer}> <KeyboardArrowLeftOutlined  data-testid="prev-button"
            style={{
                ...styles.navIcons,
                cursor: "not-allowed",
                opacity: 0.5,
              }}/>
                       <Box
                        data-testid="progress-bar-1"
                        key={4}
                        style={{
                            ...styles.progressBar,
                            width: '100%',
                            display: 'flex',
                        }}>
                        <Box
                            style={{
                                ...styles.progressTracked,             
                                padding: '2px',
                                width: '100%',
                                border: '1px solid #727c8b',
                                height: '9px',
                            }}>
                            <Box
                                data-testid={`progress-bar-5`}
                                style={{
                                    ...styles.progressFilled,
                                    backgroundColor: '#72EFDD',
                                }}/></Box>
                    </Box>
                    <KeyboardArrowRightOutlined
                        data-testid="next-button"
                        style={{
                            ...styles.navIcons,
                            cursor:
                                "not-allowed",
                            opacity: 0.5,
                        }}/></Box></Box></Box>);};

const styles = {
    innerWrapper: {
        maxWidth: "850px",
        margin: "0 auto",
    },
    progressContainer: {
       
        justifyContent: "space-between",
        gap: "8px",
        display: "flex",
        alignItems: "center",
    },
    progressWrapper: {
        width: "100%",
        padding: "20px 0",
        backgroundColor: "transparent",
    },
    
    progressBar: {
        width: "150px",
    },
   
    navIcons: {
        color: "white",
        fontSize: "24px",
        transition: "opacity 0.3s ease",
    },
    progressTracked: {
        backgroundColor: "#334155",
        borderRadius: "10px",
        overflow: "hidden",
    },
    progressFilled: {
        height: "100%",
        transition: "width 0.3s ease-out",
        borderRadius: "2px",
    },
};

export default UnitProgress;
