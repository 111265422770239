// Customizable Area Start
import React from "react";
import { Container, Box, Typography} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { IoChevronBack } from "react-icons/io5";
import ButtonController, {
  Props,
} from "./ButtonController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Buttons extends ButtonController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  renderContent = () => {
    return (
      <>
         {this.props.customForm && <>{this.props.customForm}</>}
        <Box className="content-wrapper"  data-testid="content">
          <img src={this.props.pgIcon} alt={this.props.pgIcon} className="pgIcon" data-testid="pgIcon" />
            <Box className="form-content">
              {this.props.formContent && <>{this.props.formContent}</>}
              <Box
                className={`${this.props.form ? 'form-wrapper' : ''}`}
                data-testid="form-wrapper"
              >
                {this.props.form && <>{this.props.form}</>}
              </Box>

            
            </Box>
        </Box>
      </>
    );
  };

  renderBgImg = () => {
    if (this.props.bgImg) return `url(${this.props.bgImg})`;
    return this.props.bgImgColor ? this.props.bgImgColor : 'none';
  }

 

  // Customizable Area End
  render() {
    return (
      <Container
        maxWidth="xl"
        className="container-loginComp"
      >
          
         
        <Box
          className={`content-container ${this.props.contentContainerStyle}`}
          style={{ 
            backgroundImage: this.renderBgImg(), 
            backgroundPosition: this.props.bgImgPosition}}
            data-testid="content-container"
        >
         
       
          
          {this.renderContent()}         
        </Box>
       
      </Container>
      // Customizable Area End
    );
  }
}
