import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface Lesson {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    image: {
      url: string;
    } | null;
    video: {
      url: string;
    } | null;
    audio: {
      url: string;
    } | null;
    table: {
      url: string;
    } | null;
  };
}
interface Section {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    image: {
      url: string;
    } | null;
    video: {
      url: string;
    } | null;
    audio: {
      url: string;
    } | null;
    table: {
      url: string;
    } | null;
    lessons: {
      id: number;
      type: string;
      data: Lesson[]
    };
    completed?: boolean;
  };
}
interface Topic {
  id: number;
  name: string;
  description: string;
  image?: string | null;
  sections: {
    data: Section[];
  };
  checked: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  unitId: number | null;
  currentTopic: Topic;
  data: {
    unit_name: string;
    course_name: string;
    journey_name: string;
    topics: Topic[];
  } | null;
  loading: boolean;
  error: string | null;
  currentSectionIndex: number;
  scrollPercentages: number;
  qb: boolean;
  isPlaying: boolean;
  open: boolean;
  isPlayingLess: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UnitController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getUnitCallId: any;
  videoRef: HTMLVideoElement | null = null;
  videoRefLess: HTMLVideoElement | null = null;
  fetchSaveMarkAsComplete: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      unitId: null,
      currentTopic: {
        id: 0,
        name: "",
        description: "",
        image: null,
        sections: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                id: 0,
                name: "",
                description: "",
                image: {
                  url: "",
                },
                video: {
                  url: "",
                },
                audio: {
                  url: "",
                },
                table: {
                  url: "",
                },
                lessons: {
                  id: 0,
                  type: "",
                  data: [
                    {
                      id: "",
                      type: "",
                      attributes: {
                        id: 0,
                        name: "",
                        description: "",
                        image: {
                          url: "",
                        },
                        video: {
                          url: "",
                        },
                        audio: {
                          url: "",
                        },
                        table: {
                          url: "",
                        },
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        checked: false,
      },
      data: null,
      loading: false,
      error: null,
      currentSectionIndex: 0,
      scrollPercentages: 6,
      qb: false,
      isPlaying: false,
      open: true,
      isPlayingLess: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.scrollTo(0, 0);
    const unitId = this.props.navigation.getParam("unitId", null);
   
    const token = await getStorageData("signupToken");
    this.setState({ token: token, unitId: unitId }, () => {
      localStorage.setItem("topicid",unitId)
      this.getUnitDetails(unitId, token);
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.getUnitCallId) {
      if (responseJson?.course_name) {
        this.updateTopicProgress(responseJson)
        this.setState({
          loading: false,
          data: responseJson,
          currentTopic: responseJson?.topics[Number(localStorage.getItem("currentTopicId"))],
        });
      }
    } else if (apiRequestCallId === this.fetchSaveMarkAsComplete) {
      console.log(responseJson, 'currentSection');

    }
    // Customizable Area End
  }

  // Customizable Area Start
  updateTopicProgress = (responseJson: any) => {
    
    const currentTopicId = localStorage.getItem("currentTopicId") || "0";
    const isLast = responseJson?.topics?.length - 1 === Number(currentTopicId);
  
    localStorage.setItem("currentTopicId", currentTopicId);
    localStorage.setItem("lastTopic", String(isLast));
  }
  goBackfromUnit = () => {
    this.props.navigation.goBack()
  }

  getUnitDetails = (unitId: number, token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUnitCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unitEndpoint}=${unitId}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    this.setState({ loading: true });
  };
  handleSectionChange = (index: number) => {
    this.setState({ currentSectionIndex: index });
    this.handleTest()
  };

  handleNavigation = (direction: "prev" | "next") => {
    const { currentSectionIndex, currentTopic } = this.state;
    const totalSections = currentTopic?.sections?.data.length || 0;

    if (direction === "next" && currentSectionIndex < totalSections - 1) {
      this.setState({
        currentSectionIndex: currentSectionIndex + 1,
        scrollPercentages: 0,
      });
    }  else if (direction === "prev" && currentSectionIndex > 0) {
      this.setState({
        currentSectionIndex: currentSectionIndex - 1,
        scrollPercentages: 0,
      });
    }
  };

  handleTest = () => {
    console.log("for testing purpose")
  }

  handleNavigationNext = (direction: "prev" | "next",data:any) => {
    let uniqueID = data.sections?.data[0].attributes;
    const { currentSectionIndex, currentTopic } = this.state;
    const totalSections = currentTopic?.sections?.data.length || 0;

    if (direction === "next" && currentSectionIndex < totalSections - 1) {
      this.setState({
        currentSectionIndex: currentSectionIndex + 1,
        scrollPercentages: 0,
      });
    }  else if (direction === "prev" && currentSectionIndex > 0) {
      this.setState({
        currentSectionIndex: currentSectionIndex - 1,
        scrollPercentages: 0,
      });
    } else {
      this.props.history.push("/QuestionBank", uniqueID )
    }
  };

  handleSectionCompletion = (currentSection: any) => {
    const { data, currentTopic, currentSectionIndex } = this.state;

    if (!data?.topics) return;

    const updatedData = { ...data };
    const topicIndex = updatedData.topics.findIndex(
      (topic) => topic.id === currentTopic.id
    );
    localStorage.setItem("topicRightId",String(currentTopic.id))
    if (topicIndex !== -1) {
       currentSection =
        updatedData.topics[topicIndex].sections.data[currentSectionIndex];
      currentSection.attributes.completed = !currentSection.attributes.completed;

      const allSectionsCompleted = updatedData.topics[
        topicIndex
      ].sections.data.every((section) => section.attributes.completed);

      updatedData.topics[topicIndex].checked = allSectionsCompleted;

      this.setState({
        data: updatedData,
        currentTopic: updatedData.topics[topicIndex],
        scrollPercentages: currentSection.attributes.completed ? 100 : 0,
      });
    }
    this.fetchMarkAsComplete(currentSection)
  };

  fetchMarkAsComplete = (currentSection: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      "completion": {
        "category_id": currentSection.category_id,
        "sub_category_id": currentSection.sub_category_id,
        "unit_id": currentSection.unit_id,
        "topic_id": currentSection.topic_id,
        "section_id": currentSection.id
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchSaveMarkAsComplete = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.MarkAsCompleteEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePlayClick = () => {
    if (this.videoRef) {
      if (this.videoRef.paused) {
        this.videoRef.play();
        this.setState({ isPlaying: true });
      } else {
        this.videoRef.pause();
        this.setState({ isPlaying: false });
      }
    }
  };

  handlePlayClickLess = () => {
    if (this.videoRefLess) {
      if (this.videoRefLess.paused) {
        this.videoRefLess.play();
        this.setState({ isPlayingLess: true });
      } else {
        this.videoRefLess.pause();
        this.setState({ isPlayingLess: false });
      }
    }
  };
  
  handleVideoToggle = (isPlaying: boolean) => {
    this.setState({ isPlaying: !isPlaying });
  };
  handleVideoToggleLess = (isPlaying: boolean) => {
    this.setState({ isPlayingLess: !isPlaying });
  };
  // Customizable Area End
}
