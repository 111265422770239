import React from "react";
// Customizable Area Start
import {
    Box, Typography,
    Toolbar,
    IconButton,
    CssBaseline,
} from "@material-ui/core";
import Header from "../../catalogue1/src/Header.web";
import "../../catalogue1/src/Header.css";
import "../../scheduling/src/global.css";
import OrganSystemUnitController, { Props } from "./OrganSystemUnitController.web";
import { ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from "@material-ui/icons";
import UnitSideBar from "../../../components/src/UnitSideBar";
import TopicProgress from "../../catalogue/src/TopicProgress.web";
const openDrawerWidth = 300; 
const closedDrawerWidth = 60;
const styles = {
    root: {
        display: "flex",

    },
    appBar: {
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    appBarShift: {
        marginLeft: openDrawerWidth,
        width: `calc(100% - ${openDrawerWidth}px)`,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1), margin 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    menuButton: {
        marginRight: "20px",
    },
    hide: {
        display: "none",
    },
    show: {
        display: "block",
    },
    drawer: {
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: openDrawerWidth,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    drawerClose: {
        overflowX: "hidden",
        width: closedDrawerWidth,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",

    },
    drawerPaper: {

    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: "0px ",
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: "30px",
        marginLeft: closedDrawerWidth, // Default for closed drawer
        transition: "margin-left 225ms cubic-bezier(0, 0, 0.2, 1)",
        width: `calc(100% - ${closedDrawerWidth}px)`,

    },
    contentShift: {
        marginLeft: openDrawerWidth, 
        width: `calc(100% - ${openDrawerWidth}px)`,
    },
};
// Customizable Area End
export default class OrganSystemUnit extends OrganSystemUnitController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <>
                <Box style={webStyle.backgroundCLr}>
                    <Header navigation={this.props.navigation} id={"3"} />
                    <div style={styles.root} >
                        {this.state.open && <div className="sidenavHeader" style={styles.drawerHeader} >
                            <IconButton data-test-id = "handleDrawerClose" onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>}
                        <Toolbar className="closedNav" style={this.state.open ? styles.hide : styles.show}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                data-test-id = "handleDrawerOpen"
                                edge="start"
                                style={styles.menuButton}
                            >
                                <ChevronRightIcon />
                            </IconButton>

                        </Toolbar>
                        <CssBaseline />
                        <UnitSideBar open={this.state.open} Styles={styles} />
                        <main
                            style={this.state.open ? { ...styles.content, ...styles.contentShift } : styles.content}
                        >
                            <div style={styles.drawerHeader} />
                            {!this.state.open && <button className="goBackbtn2" data-test-id = "goBackbtn2" onClick={this.goBackfromUnit}>
                                <ChevronLeftIcon />
                            </button>}
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="auto"
                                width="100%"
                                padding="0px 50px"
                            >
                                <Typography paragraph>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                    ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
                                    facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
                                    gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
                                    donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                                    adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras.
                                    Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis
                                    imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget
                                    arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                                    donec massa sapien faucibus et molestie ac.
                                </Typography>
                            </Box>
                        </main>
                    </div>
                    {/* <Footer navigation={this.props.navigation} id={""} /> */}
                </Box>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle = {
    backgroundCLr: {
        backgroundColor: "rgb(28, 34, 53)",
        minHeight: "100vh",
    },
    root: {
        backgroundColor: "rgb(28, 34, 53)",
        minHeight: "100vh",
        color: "white",
        overflowX: "hidden" as const,
        display: "flex",
        flexWrap: "wrap" as const,
        textAlign: "end" as const,
    },
    leftSidebar: {
        backgroundColor: "#050D15",
        width: "25%",
        height: "auto",
    },
    mainContent: {
        width: "100%",
    },
    content: {
        position: "relative" as const,
    },
    container: {
        padding: "40px",
        width: "84%",
        height: "auto",
        background: "linear-gradient(315deg, #94A3B8, #BECBDF, #94A3B8)",
        borderRadius: "20px",
        margin: "0 auto",
        overflowY: "auto" as const,
        scrollbarWidth: "none" as const,
        maxHeight: "100%",
    },
    titleContainer: {
        width: "fit-content",
        height: "43px",
        backgroundColor: "#64748B",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center" as const,
        alignItems: "center",
    },
    title: {
        fontSize: "22px",
        padding: "16px",
    },
    description: {
        fontSize: "18px",
        textAlign: "justify" as const,
        letterSpacing: "0.7px",
        paddingTop: "10px",
    },
    courseName: {
        display: "flex",
        alignItems: "center" as const,
        padding: "30px 0",
        flexDirection: "column" as const,
        gap: "10px",
        position: "relative" as const,
        margin: "0 154px",
    },
    btnBox: {
        width: "84%",
        padding: "40px",
        height: "auto",
        background: "#0F172A",
        borderRadius: "20px",
        margin: "30px auto",
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "center",
        gap: "10px",
    },
    btn1: {
        width: "230px",
        height: "44px",
        border: "1px solid #72EFDD",
        borderRadius: "20px",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        letterSpacing: "0.8px",
    },
    btn2: {
        width: "230px",
        height: "44px",
        background: "#72EFDD",
        border: "1px solid #72EFDD",
        borderRadius: "20px",
        fontSize: "16px",
        letterSpacing: "0.8px",
    },
    videoContainer: {
        width: "100%",
        marginTop: "20px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    video: {
        width: "100%",
        maxWidth: "770px",
        borderRadius: "10px",
        backgroundColor: "transparent",
    },
    tabsContainer: {
        display: "flex",
        gap: "10px",
        overflowX: "auto" as const,
        padding: "10px 0",
        marginBottom: "20px",
    },
    tab: {
        padding: "8px 16px",
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
        fontSize: "16px",
        transition: "all 0.3s ease",
        whiteSpace: "nowrap" as const,
    },
    wholeContent: {},
    imgDiv: {
        padding: "20px 0",
    },
    imgTag: {
        borderRadius: "10px",
    },
};
// Customizable Area End
