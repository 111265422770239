import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  prev,
  next,
  courseMed,
  AtoZ,
  ZtoA,
  low,
  high,
  backgroundSlider,
  skipIcon,
  bookLight,
  arrowNext,
  arrowPrev,
  question,
  skipSearchOff
} from "./assets";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import Footer from "./Footer.web";
import "./Header.css";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import SearchIcon from "@material-ui/icons/Search";
import SwapVertIcon from '@material-ui/icons/SwapVert';
import ReactSimplyCarousel from 'react-simply-carousel';
// Customizable Area End

import Catalogue1Controller, { Props } from "./Catalogue1Controller";
import Header from "./Header.web";
import { MedEdSkeleton } from "../../../components/src/Skeletons";

export default class MedEdJourneys extends Catalogue1Controller {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  taskSelection = () => {

    return (
      <div className="taskContainer">
        <div style={{ width: '96%', margin: '0 auto' }}>
          <h2
            className="welcomeTxt"
            style={{ width: "87%", display: "flex", alignItems: "center" }}
          >
            My Services
            <ArrowRightIcon style={{ margin: "0 10px" }} />
            <span>Knowledge & Training Center</span>
            <ArrowRightIcon style={{ margin: "0 10px" }} />
            <span style={{ fontWeight: 'bold' }}>MedEd Journeys</span>
          </h2>
          <span
            className="welcomeBottom-border"
            style={{ display: "block", width: "87%" }}
          />
        </div>
        <div className="questionImg">
          <img src={question} style={webStyle.imgQ} />
        </div>
        <Grid container spacing={4}>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Box style={{ position: 'relative', flex: 1 }}>
              <ReactSimplyCarousel
                activeSlideIndex={this.state.activeSlideIndex}
                onRequestChange={this.setActiveSlideIndex}
                speed={500}
                easing="linear"
                itemsToShow={1}
                infinite={true}
                itemsToScroll={1}

                forwardBtnProps={{
                  style: {
                    alignSelf: 'center',
                    background: '#101C29',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '20px',
                    height: 30,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 30,
                    position: 'absolute',
                    right: -9,
                    zIndex: 99
                  },
                  children: <img src={arrowNext} style={{ width: '30px', height: '30px', marginTop: '2px', marginLeft: '2px' }} />,
                }}
                backwardBtnProps={{
                  style: {
                    alignSelf: 'center',
                    background: '#101C29',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '20px',
                    height: 30,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 30,
                    position: 'absolute',
                    left: -11,
                    zIndex: 99
                  },
                  children: <img src={arrowPrev} style={{ width: '30px', height: '30px', marginTop: '2px', marginRight: '2px' }} />,
                }}
              >
                {this.state.sliderData && this.state.sliderData.map((slide: any) => (
                  <Box style={{ position: 'relative', minWidth: '650px' }} key={slide.id}>
                    <img src={slide.image} style={{ width: '100%', height: '100%' }} />
                    <Box style={{
                      position: 'absolute', left: '50px', bottom: '100px', border: '2px solid transparent',
                      backgroundOrigin: 'border-box',
                      backgroundClip: 'content-box, border-box',
                      borderRadius: '14px',
                      height: '129px',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '263px',
                      background: `url(${backgroundSlider})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      padding: '6px'
                    }}>
                      <div style={{ width: '80%', margin: '0 auto' }}>
                        <p style={{ fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>{slide.heading}</p>
                        <p style={{ fontSize: '13px', textAlign: 'left' }}>{slide.desc}</p>
                      </div>
                    </Box>
                  </Box>
                ))}

              </ReactSimplyCarousel>
            </Box>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box sx={{ color: '#fff' }}>
              <Box style={{
                background: 'rgb(18,28,40)',
                padding: '32px',
                borderRadius: '20px'
              }}>
                <p style={{ fontWeight: 'bold', marginLeft: '6px' }}>Our Prime Selection for You</p>
                <div style={{ display: 'flex', marginTop: '15px' }}>
                  <img src={courseMed} className="medRightImg" />
                  <div style={{
                    padding: '21px 18px', background: 'rgb(25,34,51)', marginLeft: '-8px', marginTop: '8px',
                    borderRadius: '0px 20px 20px 0px', marginBottom: '14px'
                  }}>
                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                      <Box
                        style={{
                          width: 16,
                          height: 16,
                          borderRadius: '50%',
                          backgroundColor: '#293542',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={skipIcon} width={10} height={10} alt="skip-icon" />
                      </Box>
                      <p style={{ fontSize: '10px' }}>FOUNDATIONAL SCIENCES</p>
                    </div>
                    <h4 style={{ fontWeight: 'bold', marginTop: '4px' }}>Essential Medical Foundations</h4>
                    <p style={{ marginTop: '12px', fontSize: '15px', color: 'rgba(255, 255, 255, 0.7)' }}>Delve into the core principles of medicine, from anatomy to biochemistry, in this foundational journey.</p>
                    <Box
                      style={{
                        display: "flex",
                        marginTop: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Box style={{ display: "flex", alignItems: 'center' }}>
                        <img src={bookLight}
                          style={{ color: "white", marginRight: 3, width: 18, height: 18 }}
                        />
                        <Typography
                          style={{
                            color: "#FFFFFF",
                            fontFamily: "Raleway",
                            fontSize: "12px",
                          }}
                        >
                          courses
                        </Typography>
                      </Box>
                      <Box
                        fontSize="small"
                        style={{ display: "flex", justifyContent: 'center', width: '30%', alignItems: 'center' }}
                      >
                        <PeopleOutlineOutlinedIcon
                          style={{ color: "white" }}
                          fontSize="small"
                        />
                        <Typography
                          style={{ color: "#FFFFFF", fontSize: "12px" }}
                        >
                          Level 1
                        </Typography>
                      </Box>
                      <Box style={{ width: '36%' }}>
                        <Button
                          fullWidth
                          className="button-with-gradient-border"
                          style={{
                            borderRadius: "100px",
                            width: "100%",
                            height: "32px",
                            padding: "2px",
                            textTransform: "capitalize",
                            color: "white",
                            fontWeight: 800,
                            background:
                              "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                            overflow: "hidden",
                          }}
                          data-test-id="ExploreJourney"
                        >
                          <span
                            style={{
                              width: "100%",
                              backgroundColor: "#1E293B",
                              borderRadius: "100px",
                              height: "28px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Explore
                          </span>
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <Box style={{ display: 'flex', gap: '40px', maxWidth: '1200px', margin: '0 auto', padding: '20px', }}>
        </Box> */}
      </div>
    );
  };

  continue = () => {
    return (
      <Box style={{ marginTop: "50px" }} className="medMainContainer">
        <Box style={webStyle.rowDirection}>
          <h4 style={{ ...webStyle.subHeadingText }}>
            Continue Learning
          </h4>
        </Box>

        <Grid container spacing={3} style={{ width: '100%' }} data-test-id="recommendedCard"
        // style={{
        //   overflowX: 'auto', display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between',
        //   marginTop: '10px'
        // }}
        >
          {this.state.medRecommanded && this.state.medRecommanded.map((card: any, index: number) => {
            return (
              <Grid item lg={4} md={4} sm={6} xs={12} style={{ flexShrink: 0 }} key={index} data-testid={`card-${index}`}>
                <Box className="cardMapContainer card22" style={webStyle.MedBox}>
                  <Box style={webStyle.MedBox2}>
                    <div className="cardimagedata"
                      style={{

                        width: "100%",
                        height: "200px",
                        opacity: '0.5',
                        margin: "-4px 0px 0px",
                        backgroundImage: `url(${card.attributes.image.url})`,
                        position: "relative",
                        backgroundSize: 'auto 230px',
                        backgroundPosition: '-20px -5px',
                        backgroundRepeat: 'no-repeat'

                      }}
                    />


                    <Box
                      style={webStyle.MedBox3}
                    >
                      <Box>
                        <div style={webStyle.MedBox4}>
                          <div
                            style={webStyle.MedBox5}
                          >
                            <img
                              src={card.attributes.parent_category_icon?.url}
                              style={webStyle.MedImg}
                              alt="recommand parent icon"
                            />
                          </div>
                          <Typography
                            style={webStyle.MedText}
                            variant="body1"
                            data-testid={`card-name-${index}`}
                          >
                            {card.attributes.name}
                          </Typography>
                        </div>

                        <Box>
                          <Typography className="cardTitle" style={{ minHeight: '60px' }}>
                            {card.attributes.first_course_name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={webStyle.MedTextBox}
                  >
                    <Box
                      style={webStyle.MedTopBox}
                    >
                      <Box style={{ display: "flex" }}>
                        <img src={bookLight}
                          style={webStyle.MedTopImg}
                        />
                        <Typography
                          style={webStyle.MedText2}
                          className="font-family-p"
                        >
                          {card.attributes.all_courses_count > 1 ? `1/${card.attributes.all_courses_count} courses` : `${card.attributes.all_courses_count} course`}
                        </Typography>
                      </Box>
                      <Box
                        style={{ marginRight: 5, display: "flex", gap: 4 }}
                        fontSize="small"
                      >
                        <PeopleOutlineOutlinedIcon
                          fontSize="small"
                          style={{ color: "white" }}

                        />
                        <Typography
                          style={{ fontSize: "13px", color: "rgba(255,255,255,0.6)" }}
                          className="font-family-p"
                        >
                          Level {card.attributes.level}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={webStyle.MedCourseBox}
                    >
                      <div className="course_desc" style={{ overflow: "auto", height: "100px" }}>
                        <Typography
                          style={{ color: "rgba(255,255,255,0.6)", fontSize: "14px" }}
                        >
                          {card.attributes.description}
                        </Typography>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={6} xs={6} style={{ margin: 'auto' }}>
                            <Button
                              fullWidth
                              className="button-with-gradient-border"
                              style={webStyle.MedButton}
                              data-test-id="ExploreJourney"
                            >
                              <div>
                                <Box position="relative" display="inline-flex">
                                  <CircularProgress
                                    variant="determinate"
                                    value={100}
                                    style={{
                                      color: "#2E3B55",
                                    }}
                                    size={60}
                                    thickness={4}
                                  />
                                  <CircularProgress
                                    variant="determinate"
                                    value={10}
                                    style={{
                                      color: "#4CD3F7",
                                      position: "absolute",
                                      left: 0,
                                    }}
                                    size={60}
                                    thickness={4}
                                  />
                                  <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <span
                                      style={{ color: "white", fontSize: "16px", fontWeight: "bold" }}
                                    >
                                      10%
                                    </span>
                                  </Box>
                                </Box>
                              </div>
                              <span
                                style={{
                                  width: "100%",
                                  // backgroundColor: "#1E293B",
                                  borderRadius: "100px",
                                  height: "28px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                Complete
                              </span>
                            </Button>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6} style={{ margin: 'auto' }}>
                            <Button
                              fullWidth
                              className="button-with-gradient-border"
                              style={{
                                color: "white",
                                fontWeight: 800,
                                background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
                                borderRadius: "100px",
                                width: "100%",
                                height: "32px",
                                padding: "2px",
                                textTransform: "capitalize",
                                overflow: "hidden",
                              }}
                              data-test-id="ExploreJourney"
                            >
                              <span
                                style={{
                                  width: "100%",
                                  // backgroundColor: "#1E293B",
                                  borderRadius: "100px",
                                  height: "28px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                Continue
                              </span>
                            </Button>
                          </Grid>
                        </Grid>

                      </div>
                    </Box>
                    <Box style={{ textAlign: "center" }} />
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  recommended = () => {
    return (
      <Box style={{ marginTop: "50px" }} className="medMainContainer">
        <Box style={webStyle.rowDirection}>
          <h4 style={{ ...webStyle.subHeadingText }}>
            Recommended
          </h4>
        </Box>

        <Grid container spacing={3} className="CradBox overall_card" data-test-id="recommendedCard"
          style={{
            overflowX: 'auto', display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between',
            marginTop: '10px'
          }}>
          {this.state.medRecommanded && this.state.medRecommanded.map((card: any, index: number) => {
            return (
              <Grid item md={4} sm={4} style={{ flexShrink: 0 }} key={index} data-testid={`card-${index}`}>
                <Box className="cardMapContainer card22" style={{ width: '100%' }}>
                  <Box style={{ position: "relative" }}>
                    <div className="cardimagedata"
                      style={{

                        width: "100%",
                        height: "200px",
                        opacity: '0.5',
                        margin: "-4px 0px 0px",
                        backgroundImage: `url(${card.attributes.image.url})`,
                        position: "relative",
                        backgroundSize: 'auto 230px',
                        backgroundPosition: '-20px -5px',
                        backgroundRepeat: 'no-repeat'

                      }}
                    />


                    <Box
                      style={{
                        width: "88%",
                        position: "absolute",
                        left: "25px",
                        bottom: "24px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              background: "rgba(51, 65, 85, 0.6)",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                            }}
                          >
                            <img
                              src={card.attributes.parent_category_icon?.url}
                              style={{
                                color: "white",
                                width: '70%',
                                height: '70%',
                                objectFit: 'contain',
                                borderRadius: '50%'
                              }}
                              alt="recommand parent icon"
                            />
                          </div>
                          <Typography
                            style={{
                              color: "rgba(255,255,255,0.8)",
                              fontSize: "12px",
                              marginLeft: 5,
                              fontWeight: 500,
                              textTransform: 'uppercase'
                            }}
                            variant="body1"
                            data-testid={`card-name-${index}`}
                          >
                            {card.attributes.name}
                          </Typography>
                        </div>

                        <Box>
                          <Typography className="cardTitle" style={{ minHeight: '60px' }}>
                            {card.attributes.first_course_name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      borderBottomLeftRadius: "16px",
                      borderBottomRightRadius: "16px",
                      margin: "-5px 5px",
                      padding: "30px 20px",
                      backgroundColor: "#172130",
                    }}
                  >
                    <Box
                      style={{
                        marginTop: 10,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box style={{ display: "flex" }}>
                        <img src={bookLight}
                          style={{ color: "#fff", marginRight: 5, width: 18, height: 18 }}
                        />
                        <Typography
                          style={{
                            color: "rgba(255,255,255,0.6)",
                            fontSize: "13px",
                          }}
                          className="font-family-p"
                        >
                          {card.attributes.all_courses_count > 1 ? `${card.attributes.all_courses_count} courses` : `${card.attributes.all_courses_count} course`}
                        </Typography>
                      </Box>
                      <Box
                        style={{ marginRight: 5, display: "flex", gap: 4 }}
                        fontSize="small"
                      >
                        <PeopleOutlineOutlinedIcon
                          fontSize="small"
                          style={{ color: "white" }}

                        />
                        <Typography
                          style={{ fontSize: "13px", color: "rgba(255,255,255,0.6)" }}
                          className="font-family-p"
                        >
                          Level {card.attributes.level}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: "20px",
                        flexDirection: "column",
                      }}
                    >
                      <div className="course_desc" style={{ overflow: "auto", height: "100px" }}>
                        <Typography
                          style={{ color: "rgba(255,255,255,0.6)", fontSize: "14px" }}
                        >
                          {card.attributes.description}
                        </Typography>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <Button
                          fullWidth
                          className="button-with-gradient-border"
                          style={{
                            color: "white",
                            fontWeight: 800,
                            background: "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                            borderRadius: "100px",
                            width: "100%",
                            height: "32px",
                            padding: "2px",
                            textTransform: "capitalize",
                            overflow: "hidden",
                          }}
                          data-test-id="ExploreJourney"
                          onClick={() => this.props.navigation.navigate("OrganSystems")}
                        >
                          <span
                            style={{
                              width: "100%",
                              backgroundColor: "#1E293B",
                              borderRadius: "100px",
                              height: "28px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Explore Journey
                          </span>
                        </Button>
                      </div>
                    </Box>
                    <Box style={{ textAlign: "center" }} />
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  popular = () => {
    return (
      <Box className="medMainContainer" style={{ marginTop: "50px" }}>
        <Box style={webStyle.rowDirection}>
          <h4 style={{ ...webStyle.subHeadingText }}>
            Popular
          </h4>

        </Box>

        <Grid container spacing={3} className="CradBox overall_card" data-test-id="recommendedCard"
          style={{
            overflowX: 'auto', display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between',
            marginTop: '10px'
          }}>
          {this.state.medPopular && this.state.medPopular.map((card: any, index: number) => {
            const medEdCard = card.attributes;
            return (
              <Grid item md={4} sm={4} style={{ flexShrink: 0 }} key={index} data-testid={`card-${index}`}>
                <Box className="cardMapContainer" style={{ width: '100%' }}>
                  <Box style={{ position: "relative" }}>
                    <div className="cardimagedata"
                      style={{
                        width: "100%",
                        height: "200px",
                        opacity: '0.5',
                        margin: "-4px 0px 0px",
                        backgroundImage: `url(${medEdCard.image.url})`,
                        position: "relative",
                        backgroundSize: 'auto 230px',
                        backgroundPosition: '-20px -5px',
                        backgroundRepeat: 'no-repeat'

                      }}
                    />

                    <Box
                      style={{
                        bottom: "24px",
                        left: "25px",
                        position: "absolute",
                        justifyContent: "space-between",
                        display: "flex",
                        width: "88%",
                      }}
                    >
                      <Box>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              borderRadius: "50%",
                              justifyContent: "center",
                              background: "rgba(51, 65, 85, 0.9)",
                              width: "20px",
                              height: "20px",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <img
                              style={{
                                color: "white",
                                width: '70%',
                                height: '70%',
                                objectFit: 'contain',
                                borderRadius: '50%'
                              }}
                              src={medEdCard.parent_category_icon?.url}
                              alt="icons"
                            />
                          </div>
                          <Typography
                            style={{
                              fontWeight: 400,
                              color: "rgba(255,255,255,0.8)",
                              fontSize: "12px",
                              marginLeft: 5,
                              textTransform: 'uppercase'
                            }}
                            variant="body1"
                            data-testid={`card-name-${index}`}
                          >
                            {medEdCard.name}
                          </Typography>
                        </div>

                        <Box>
                          <Typography className="cardTitle" style={{ minHeight: '60px' }}>
                            {medEdCard.first_course_name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      padding: "30px 20px",
                      borderBottomLeftRadius: "16px",
                      borderBottomRightRadius: "16px",
                      backgroundColor: "#172130",
                      margin: "-5px 5px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        marginTop: 10,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box style={{ display: "flex" }}>
                        <img src={bookLight}
                          style={{ color: "#fff", marginRight: 5, width: 18, height: 18 }}
                        />
                        <Typography
                          style={{
                            fontFamily: "Raleway",
                            color: "rgba(255,255,255,0.6)",
                            fontSize: "13px",
                          }}
                          className="font-family-p"
                        >
                          {card.attributes.all_courses_count > 1 ? `${card.attributes.all_courses_count} courses` : `${card.attributes.all_courses_count} course`}
                        </Typography>
                      </Box>
                      <Box
                        fontSize="small"
                        style={{ marginRight: 5, display: "flex", gap: 4 }}
                      >
                        <PeopleOutlineOutlinedIcon
                          fontSize="small"
                          style={{ color: "white" }}
                        />
                        <Typography
                          style={{ color: "rgba(255,255,255,0.6)", fontSize: "13px" }}
                          className="font-family-p"
                        >
                          Level {medEdCard.level}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <div className="course_desc" style={{ overflow: "auto", height: "100px" }}>
                        <Typography
                          style={{ color: "rgba(255,255,255,0.6)", fontSize: "14px" }}
                        >
                          {medEdCard.description}
                        </Typography>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <Button
                          fullWidth
                          className="button-with-gradient-border"
                          style={{
                            borderRadius: "100px",
                            textTransform: "capitalize",
                            color: "white",
                            fontWeight: 800,
                            background:
                              "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                            overflow: "hidden",
                            width: "100%",
                            height: "32px",
                            padding: "2px",
                          }}
                          data-test-id="ExploreJourney"
                        >
                          <span
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#1E293B",
                              borderRadius: "100px",
                              height: "28px",
                            }}
                          >
                            Explore Journey
                          </span>
                        </Button>
                      </div>
                    </Box>
                    <Box style={{ textAlign: "center" }} />
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };


  // Customizable Area End

  explore = () => {
    const { currentPageExplore, itemsPerPage, medEdJourneyLists } = this.state;
    const indexOfLastItem = currentPageExplore * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = this.state.isSearching && this.state.exploreSearchedLists && this.state.exploreSearchedLists.length > 0 ? this.state.exploreSearchedLists.slice(indexOfFirstItem, indexOfLastItem) : this.state.medEdExploreLists && this.state.medEdExploreLists.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(this.state.isSearching && this.state.exploreSearchedLists && this.state.exploreSearchedLists.length > 0 ? this.state.exploreSearchedLists.length / itemsPerPage : this.state.medEdExploreLists && this.state.medEdExploreLists.length / itemsPerPage);
    return (
      <Box style={{ background: 'rgb(18,28,40)', borderRadius: '20px', paddingTop: '35px' }}>
        <Box>
          <Box style={{ width: '81%', margin: '0 auto' }}>
            <div style={{ display: 'flex' }}>
              <h4 style={{ ...webStyle.subHeadingText, marginLeft: "10px", flex: 1 }}>
                Explore
              </h4>
              <div style={{ display: 'flex', gap: 10, flex: 1, justifyContent: 'end', }}>
                <div style={{ background: 'rgba(255, 255, 255, 0.1)', borderRadius: '20px', padding: '6px 12px', display: 'flex', alignItems: 'center', gap: 4, ...(this.state.isSearching ? { width: '70%' } : {}), }}>
                  <SearchIcon data-testid="search-icon" style={{ color: '#fff', fontSize: '16px' }} onClick={() => this.setState({ isSearching: true })} />
                  {this.state.isSearching ? (<><input data-testid="search-input" onChange={this.handleExploreSearch} value={this.state.exploreSearch} className="explore_search" placeholder="Search" style={{ width: '100%', background: 'rgba(62, 58, 58, 0.1)', color: 'white' }} />
                    <p data-testid="close-search" style={{ color: 'white', fontSize: '12px', cursor: 'pointer' }} onClick={() => {
                      this.setState({ isSearching: false, exploreSearch: '', exploreSearchedLists: [] });
                    }}>X</p>
                  </>) :
                    <p data-testid="search-text" style={{ color: '#fff', fontSize: '14px', cursor: 'pointer' }} onClick={() => this.setState({ isSearching: true })}>Search</p>}
                </div>
                <div style={{
                  background: 'rgba(255, 255, 255, 0.1)', borderRadius: '20px', padding: '6px 12px', display: 'flex', alignItems: 'center', gap: 4,
                  position: 'relative'
                }} onClick={this.handleExploreSort}>
                  <SwapVertIcon style={{ color: '#fff', fontSize: '16px', cursor: 'pointer' }} />
                  <p style={{ color: '#fff', fontSize: '14px', cursor: 'pointer' }}>Order by</p>
                  {this.state.isSortOpen && (
                    <div ref={this.popupExSortRef} style={{
                      zIndex: 99, position: 'absolute', top: '50px', right: '0px', width: '250px',
                      padding: '20px',
                      borderRadius: '20px',
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      backdropFilter: 'blur(10px)',
                      color: '#fff'
                    }}>
                      <ul style={{ listStyle: 'none' }} className="explore-sort-items">
                        <li data-testid="sort-name-asc" onClick={() => this.handleSort('name_asc')} style={{ padding: '10px', display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}><img src={AtoZ} width="22px" /> <span>Name (A-Z)</span></li>
                        <li data-testid="sort-name-desc" onClick={() => this.handleSort('name_desc')} style={{ padding: '10px', display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}><img src={ZtoA} width="22px" /> <span>Name (Z-A)</span></li>
                        <li data-testid="sort-level-low-to-high" onClick={() => this.handleSort('level_low_to_high')} style={{ padding: '10px', display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}><img src={high} width="22px" /> <span>Level (Low to High)</span></li>
                        <li data-testid="sort-level-high-to-low" onClick={() => this.handleSort('level_high_to_low')} style={{ padding: '10px', display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}><img src={low} width="22px" /> <span>Level (High to Low)</span></li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </Box>

        </Box>
        <Box data-testid="outer-box" style={{ background: 'rgb(30,41,59)', marginTop: '50px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ width: '14%', textAlign: 'right', paddingRight: '20px', cursor: 'pointer' }}><img data-testid="prev-button" style={{ color: '#fff' }}
            src={prev} onClick={this.scrollLeft} /></div>
          <div data-testid="scrollable-container" className="hide-scrollbar" style={{
            display: 'flex', gap: 24, padding: '16px 10px', width: '85%', margin: '0 auto', overflowX: 'auto',    // Enable horizontal scrolling
            whiteSpace: 'nowrap',
          }} ref={(ref) => this.scrollContainer = ref}>
            <div style={{ color: this.state.selectMenuExplore === 'All' ? 'white' : 'rgba(255,255,255,0.5)', cursor: 'pointer' }} onClick={() => this.handleMenuSelected({ id: 0, name: 'All' })}>All</div>
            {medEdJourneyLists && medEdJourneyLists.map((list: any) => (
              <div key={list?.id} style={{ color: this.state.selectMenuExplore === list?.attributes?.name ? 'white' : 'rgba(255,255,255,0.5)', cursor: 'pointer' }} onClick={() => this.handleMenuSelected({ id: list.attributes.id, name: list.attributes.name })}>
                {list?.attributes?.name}
            </div>
        ))}
        
        </div>
        <div style={{width: '14%', textAlign: 'left', paddingLeft: '20px', cursor: 'pointer'}}><img style={{color: '#fff'}}
            src={next} onClick={this.scrollRight}/></div>
    </Box>
    <Box style={{width: '80%', margin: '0 auto 10%'}}>
      {this.state.medEdExploreLists.length == 0 ? this.renderMedEdSkeleton() : 
    <Box className="exploreCard" style={{marginTop: '55px', minHeight: '1000px'}}>
          {
          this.state.exploreSearchedLists !== undefined && currentItems && currentItems.length > 0 ?
          currentItems && currentItems.map((card: any, index: number) => {
            return (
              <Box key={index} className="cardMapContainer" data-testid={`cardCrad-${index}`} style={{maxHeight: '458px'}}>
                <Box style={{ position: "relative" }}>
                  <img
                    style={{
                      width: "100%",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      height: "200px",
                      objectFit: "cover",
                      opacity: '0.5',
                    }}
                    src={card.attributes?.image?.url}
                    alt="Our Image"
                  />
                  <Box
                    style={{
                      width: "88%",
                      position: "absolute",
                      bottom: "24px",
                      left: "25px",
                      display: "flex",
                      justifyContent: "space-between",
                      
                    }}
                  >
                    <Box>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            borderRadius: "50%",
                            background: "rgba(51, 65, 85, 0.6)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          <img
                            src={card.attributes?.parent_category_icon?.url}
                            style={{
                              color: "white",
                              width: '70%',
                              height: '70%',
                              objectFit: 'contain',
                              borderRadius: '50%'
                            }}
                          />
                        </div>
                        <Typography
                          style={{
                            fontWeight: 400,
                            color: "rgb(255,255,255)",
                            fontSize: "12px",
                            marginLeft: 5,
                            textTransform: 'uppercase'
                          }}
                          variant="body1"
                        >
                          {card.attributes?.parent_category_name}
                        </Typography>
                      </div>

                            <Box>
                              <Typography className="cardTitle" style={{ minHeight: '60px' }}>
                                {card.attributes?.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          margin: "-5px 5px",
                          borderBottomLeftRadius: "16px",
                          padding: "30px 20px",
                          backgroundColor: "#172130",
                          borderBottomRightRadius: "16px",
                        }}
                      >
                        <Box
                          style={{
                            marginTop: 10,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box style={{ display: "flex" }}>
                            <img src={bookLight}
                              style={{ color: "#fff", marginRight: 5, width: 18, height: 18 }}
                            />
                            <Typography
                              style={{
                                color: "rgba(255,255,255,0.6)",
                                fontFamily: "Raleway",
                                fontSize: "13px",
                              }}
                              className="font-family-p"
                            >
                              {card.attributes?.all_courses_count > 1 ? `${card.attributes?.all_courses_count} courses` : `${card.attributes?.all_courses_count} course`}
                            </Typography>
                          </Box>

                          <Box
                            fontSize="small"
                            style={{ marginRight: 5, display: "flex", gap: 4 }}
                          >
                            <PeopleOutlineOutlinedIcon
                              fontSize="small"
                              style={{ color: "white" }}

                            />
                            <Typography
                              className="font-family-p"
                              style={{ fontSize: "13px", color: "rgba(255,255,255,0.6)", }}
                            >
                              Level {card.attributes?.level}
                            </Typography>

                          </Box>
                        </Box>
                        <Box
                          style={{
                            marginTop: "20px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="course_desc" style={{ overflow: "auto", height: "100px" }}>
                            <Typography
                              style={{ color: "rgba(255,255,255,0.6)", fontSize: "14px" }}
                            >
                              {card.attributes?.description}
                            </Typography>
                          </div>
                          <div style={{ marginTop: "20px" }}>
                            <Button
                              onClick={() => this.handleJourney(card.id)}
                              data-test-id="ExploreJourney2"
                              className="button-with-gradient-border explorebtn"
                              fullWidth
                              style={{
                                width: "100%",
                                height: "32px",
                                borderRadius: "100px",
                                color: "white",
                                padding: "2px",
                                background:
                                  "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                                textTransform: "capitalize",
                                fontWeight: 800,
                                overflow: "hidden",
                              }}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "28px",
                                  backgroundColor: "#1E293B",
                                  borderRadius: "100px",
                                }}
                              >

                                Explore Journey
                              </span>
                            </Button>
                          </div>
                        </Box>
                        <Box style={{ textAlign: "center" }} />
                      </Box>

                    </Box>
                  );
                })
                :
                <div style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center',
                  flexDirection: 'column', paddingBottom: '80px', width: '100%'
                }} >
                  <div className="empty-explore">
                    <img src={skipSearchOff} alt="search-icon" className="centered-element" />
                  </div>
                  <div style={{ marginTop: '-125px', color: 'rgba(255,255,255,0.5)' }}>
                    <p style={{ textAlign: 'center', marginBottom: '4px', fontWeight: 'bold' }}>Uh-oh! It seems we couldn't find any journeys matching your search.</p>
                    <p>Try broadening your search with different keywords to uncover more journeys, or explore more journeys for inspiration!</p>
                  </div>
                  <div style={{ width: '30%', marginTop: "30px" }}>
                    <Button
                      fullWidth

                      style={{
                        width: "100%",
                        borderRadius: "100px",
                        padding: "2px",
                        textTransform: "capitalize",
                        height: "32px",
                        fontWeight: 800,
                        color: "white",
                        overflow: "hidden",
                        background:
                          "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",

                      }}
                      data-test-id="ExploreJourney"
                      className="button-with-gradient-border"
                    >
                      <span
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          height: "28px",
                          alignItems: "center",
                          backgroundColor: "#1E293B",
                          borderRadius: "100px",
                        }}
                      >
                        Explore Journey

                      </span>
                    </Button>

                    </div>
          </div>
        }
        </Box>
  }
        {this.state.exploreSearchedLists !== undefined && currentItems && currentItems.length > 0 && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 18, paddingBottom: '35px'}}>
          {/* Previous Page Button */}
          <img
            onClick={() => this.handlePageChangeExplore(currentPageExplore - 1)}
            style={{color: '#fff', cursor: 'pointer'}}
            src={prev}
            className={currentPageExplore === 1 ? 'disabled-image' : ''}
          />
           

            {/* Page Numbers */}
            {[...Array(totalPages > 0 ? totalPages : 0)].map((_, i) => (
              <p
                key={i + 1}
                onClick={() => this.handlePageChangeExplore(i + 1)}
                className={currentPageExplore === i + 1 ? 'active_explore' : 'no_active'}
                style={{ cursor: 'pointer' }}
              >
                {i + 1}
              </p>
            ))}

            {/* Next Page Button */}
            <img
              onClick={() => this.handlePageChangeExplore(currentPageExplore + 1)}
              style={{ color: '#fff', cursor: 'pointer' }}
              src={next}
              className={currentPageExplore === totalPages ? 'disabled-image' : ''}
            />

          </div>}

        </Box>
      </Box>
    )
  }

  renderMedEdSkeleton = () => {
    return (
        <MedEdSkeleton />
    );
  };
  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.backgroundCLr}>
        <Header navigation={this.props.navigation} id={"3"} />

        {this.taskSelection()}
        {this.continue()}
        {this.recommended()}
        {this.popular()}
        {this.explore()}
        <Footer navigation={this.props.navigation} id={""} />
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  statsBodyy: {
    opacity: "30% !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  } as const,
  buttonStyle: {
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    width: "100%",
    height: "45px",
    marginTop: "40px",
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
    padding: '10px 0px',
  },
  medBody: {
    display: "flex",
  },
  imgIconBack: {
    background: "rgba(51, 65, 85, 1)",
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
  },
  plusBtnStyle: {
    background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
    color: "white",
    borderRadius: "50%",
    padding: 5,
    width: "32px !important",
    height: "32px !important",
  },
  imgQ: {
    width: 14,
    height: 20,
  },
  subHeadingText: {
    fontWeight: 700,
    fontSize: "20px",
    color: "rgba(255,255,255,1)",
  },
  MedBox: {
    width: '100%'
  },
  MedBox2: {
    position: "relative" as "relative"
  },
  MedBox3: {
    width: "100%",
    position: "absolute" as "absolute",
    left: "25px",
    bottom: "24px",
    display: "flex",
    justifyContent: "space-between",
  },
  MedBox4: {
    display: "flex"
  },
  MedBox5: {
    width: "20px",
    height: "20px",
    background: "rgba(51, 65, 85, 0.6)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  MedImg: {
    color: "white",
    width: '70%',
    height: '70%',
    objectFit: 'contain' as 'contain',
    borderRadius: '50%'
  },
  MedText: {
    color: "rgba(255,255,255,0.8)",
    fontSize: "12px",
    marginLeft: 5,
    fontWeight: 500,
    textTransform: 'uppercase' as 'uppercase'
  },
  MedTextBox: {
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    margin: "-5px 5px",
    padding: "30px 20px",
    backgroundColor: "#172130",
  },
  MedTopBox: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  MedTopImg: {
    color: "#fff",
    marginRight: 5,
    width: 18,
    height: 18
  },
  MedText2: {
    color: "rgba(255,255,255,0.6)",
    fontSize: "13px",
  },
  MedCourseBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px",
    flexDirection: "column" as "column",
  },
  MedButton: {
    color: "white",
    fontWeight: 800,
    // background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
    borderRadius: "100px",
    width: "100%",
    // height: "32px",
    padding: "2px",
    textTransform: "capitalize" as "capitalize",
    overflow: "hidden",
  }
};
// Customizable Area End
