import React from "react";
// Customizable Area Start
import '../../catalogue1/src/Header.css'
import {
    Box, CircularProgress, Typography,
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    CssBaseline,
} from "@material-ui/core";
import { tryagain, viewresult, skipexam } from "./assets";
import Header from "../../catalogue1/src/Header.web";
import "../../scheduling/src/global.css";
import ScoreController, { Props } from "./ScoreController.web";
import ProgessBar from "./ProgressBar.web";
import {
   ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from "@material-ui/icons";

const openDrawerWidth = 300; // Width when drawer is open
const closedDrawerWidth = 60; // Width when drawer is closed
const styles = {
    root: {
        display: "flex",

    },
    appBar: {
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    appBarShift: {
       
        width: `calc(100% - ${openDrawerWidth}px)`,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1), margin 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    menuButton: {
        marginRight: "20px",
    },
    hide: {
        display: "none",
    },
    show: {
        display: "block",
    },
    drawer: {
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: openDrawerWidth,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    drawerClose: {
        overflowX: "hidden",
        width: closedDrawerWidth,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",

    },
    drawerPaper: {

    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: "0px ",
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: "30px",
     
        transition: "margin-left 225ms cubic-bezier(0, 0, 0.2, 1)",
        width: `calc(100% - ${closedDrawerWidth}px)`,

    },
    contentShift: {
      
        width: `calc(100% - ${openDrawerWidth}px)`,
    },
};

// Customizable Area End
export default class Score extends ScoreController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    
    renderProgressBar() {
        const {
            currentSection,
            currentLessonIndex,
        } = this.state;

        return (
            <ProgessBar data-test-id="handleNavigation"
            lessons={currentSection?.sections?.data || []}
            currentIndex={currentLessonIndex}
            scrollProgress={100} />)}         
        

    renderContent() {

        const { currentSection, loading, error, currentLessonIndex } = this.state;
        const currentLesson =
            currentSection?.sections?.data[currentLessonIndex || 0];


        if (loading) {
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    width="100%"
                >
                    <CircularProgress style={{ color: "white" }} />
                </Box>
            );
        }

        if (error) {
            return (
                <Typography variant="h6" style={{ color: "red" }}>
                    {error}
                </Typography>
            );
        }
        return (
            <Box style={webStyle.mainContent}>
                <>
                    <style>
                        {`
                .preview-font {
                    font-family: 'Poppins', sans-serif !important;
                }
                .mainDescription p, .mainDescription span {
                  color: white !important;
                  font-size: 20px;
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                }
                .preview-font span {
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                  color: #fff !important;
                }
                .scroll-container::-webkit-scrollbar {
                  display: none;
                }
                .preview-font p {
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                  clear: left;
                }
                .preview-font img {
                  float: right;
                  margin: 0 0 15px 15px;
                  border-radius: 10px;
                  height: 200px;
                  width: 250px;
                  object-fit: fill;
                }
                .MuiPaper-root.MuiPaper-rounded{
                  min-width: auto !important;
                  border-radius: 50px;
                  scrollbar-width: none;
                }
            `}
                    </style>
                </>
                <Box style={webStyle.content}>
                    <Box style={webStyle.courseName}>
                        <Typography
                            variant="h2"
                            style={{ fontSize: "24px" }}
                            data-testid="unit-title"
                        >
                            {this.state.data?.unit_name}
                        </Typography>
                        <div
                            className="mainDescription"
                            dangerouslySetInnerHTML={{
                                __html: this.state.currentSection?.description,
                            }}
                        ></div>
                    </Box>
                    {this.renderProgressBar()}
                    <Box style={webStyle.container} className="scroll-container">
                        <Box style={webStyle.titleContainer}>
                            <Typography variant="subtitle1" style={webStyle.title}>
                                Question Bank Completed
                            </Typography>
                        </Box>
                        <div style={webStyle.wholeContent}>
                            You did it! You've reached the end of the Question Bank.
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img style={{ marginTop: '5px', marginRight: '8px' }} src={skipexam} />
                            <div style={webStyle.question}>
                                 {this.state.SectionData?.total_questions} questions
                            </div>
                        </div>
                        <Box style={{ ...webStyle.subcontainer }} className="scroll-container">
                            <div style={{ color: '#64748B', fontSize: '14px', fontWeight: 700 , fontFamily:'Raleway',}}>
                                YOUR GRADE:
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <div
                                    style={{
                                        ...webStyle.questionScore,
                                        backgroundColor: this.handleColor(),
                                        marginRight: '10px',

                                    }}
                                >
                                    {this.state.SectionData?.score}/100
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' ,marginTop:'6px',marginLeft:'3px'}}>

                                    <div style={{...webStyle.questionMessage,marginTop:this.state.SectionData?.score < 70?'10px':'20px'}}>
                                        {this.state.SectionData?.header_message}
                                    </div>
                                    {this.state.SectionData?.score < 70 &&

                                        <div style={{ ...webStyle.questionMessage, color: '#334155B2' }}>
                                            Remember, you can try again to boost your score!
                                        </div>
                                    }
                                </div>
                            </div>
                        </Box>
                    </Box>
                    <Box style={webStyle.btnBox}>
                        <button data-test-id="handleLessonCompletion"
                        onClick={()=>this.handleView()}
                            style={{
                                ...webStyle.btn1,
                                background: !currentLesson?.attributes.completed
                                    ? "transparent"
                                    : "#72EFDD33",
                            }}
                           
                        >

                            <>
                                <img src={viewresult} />
                                <span style={{ color: "white", fontWeight: 'bold',fontFamily:'Raleway' ,cursor:'pointer'}}>View results</span>
                            </>

                        </button>
                        <button data-test-id="handleLessonCompletion"
                            style={{
                                ...webStyle.btn1,
                                background: !currentLesson?.attributes.completed
                                    ? "transparent"
                                    : "#72EFDD33",
                            }}
                            onClick={()=>this.handleRetry()}
                           
                        >

                            <>
                                <img src={tryagain} />
                                <span style={{ color: "white", fontWeight: 'bold' ,fontFamily:'Raleway',cursor:'pointer'}}>Try again</span>
                            </>

                        </button>
                        <button data-test-id="handleNavigationNext"
                            style={webStyle.btn2}
                           onClick={()=>this.nextTopic()}
                        >
                           
                          {localStorage.getItem('lastTopic') == 'true' ? 'View unit results':  'Go to next topic'}
                        </button>
                    </Box>
                </Box>
            </Box>
        );
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { open } = this.state;
        return (
            //Merge Engine DefaultContainer
            <>
                <Box style={webStyle.backgroundCLr}>
                    <Header navigation={this.props.navigation} id={"3"} />


                    <div style={styles.root} >
                        {open && <div className="sidenavHeader" style={styles.drawerHeader} >
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>}
                        <Toolbar className="closedNav" style={open ? styles.hide : styles.show}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                edge="start"
                                style={styles.menuButton}
                            >
                                <ChevronRightIcon />
                            </IconButton>

                        </Toolbar>
                        <CssBaseline />

                        <Drawer
                            style={styles.drawer}
                            variant="permanent"
                            anchor="left"
                            open={open}
                            PaperProps={{ style: open ? styles.drawerOpen : styles.drawerClose }}
                        >

                            <AppBar
                                className="navHeader"
                                style={open ? { ...styles.appBar, ...styles.appBarShift } : styles.appBar}

                            >

                            </AppBar>
                            <Divider />
                            <ul>
                                <div className="navHeading">
                                    {open && <button className="goBackbtn" data-testid="goBack">
                                        <ChevronLeftIcon />
                                    </button>}
                                    <div className="navSubHead">
                                        <li className="coursename">
                                            <h5>{open && this.state.data && this.state.data.course_name}</h5>
                                        </li>
                                        <li className="unitname">
                                            <h4>{open && this.state.data && this.state.data.unit_name}</h4>
                                        </li>
                                    </div>
                                </div>
                                <Divider className="gradientHR" />
                                {/* Render sections and lessons */}
                                {this.state.data && this.state.data?.topics?.map((section) => (
                                    <li key={section.id}>
                                        <ListItemIcon style={{ color: "#fff" }}>

                                        </ListItemIcon> <h5 className="sectionName">{open && section.name}</h5>
                                        <ul>
                                            {section.sections.data.map((lesson, index) => (
                                                <List key={lesson.id}> <ListItem >
                                                    <ListItemIcon
                                                        className="iconIndex">
                                                        {index + 1}
                                                    </ListItemIcon>
                                                    <p className="lessonName">{open && <ListItemText primary={lesson.attributes.name} />}</p>
                                                </ListItem></List>
                                            ))}
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon className="iconIndex">
                                                        {section.sections.data.length + 1}
                                                    </ListItemIcon>
                                                    <p className="lessonName">
                                                        {open && <ListItemText primary="QuestionBank" />}
                                                    </p>
                                                </ListItem>
                                            </List>

                                        </ul>
                                    </li>
                                ))}
                            </ul>
                            <Divider />

                        </Drawer>

                        <main
                            style={open ? { ...styles.content, ...styles.contentShift } : styles.content}
                        >
                            <div style={styles.drawerHeader} />
                            {!open && <button className="goBackbtn2" >
                                <ChevronLeftIcon />
                            </button>}
                            {this.renderContent()}
                        </main></div>
                    <div
                        style={{ backgroundColor: '#050D15', height: '45px', marginBottom: '30px' }}>
                        <div className="social-links-container">
                            <p style={{ color: '#FFFFFF', marginTop: '5px', marginLeft: '100px' }}>2024 MedEd Cloud. All rights reserved.</p>
                        </div>

                    </div>
                </Box>
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle = {
    backgroundCLr: {
        backgroundColor: "rgb(28, 34, 53)",
        minHeight: "100vh",
    },
    root: {
        backgroundColor: "rgb(28, 34, 53)",
        minHeight: "100vh",
        color: "white",
        overflowX: "hidden" as const,
        display: "flex",
        flexWrap: "wrap" as const,
        textAlign: "end" as const,
    },
    leftSidebar: {
        backgroundColor: "#050D15",
        width: "25%",
        height: "auto",
    },
    mainContent: {
        width: "100%",
    },
    content: {
        position: "relative" as const,
    },
    container: {
        padding: "40px",
        width: "84%",
        height: "auto",
        background: "linear-gradient(315deg, #94A3B8, #BECBDF, #94A3B8)",
        borderRadius: "20px",
        margin: "0 auto",
        overflowY: "auto" as const,
        scrollbarWidth: "none" as const,
        maxHeight: "100%",
    },
    subcontainer: {
        padding: "40px",
        height: "auto",
        background: "#CBD5E1",
        borderRadius: "20px",
        margin: "0 auto",
        marginTop: '30px',
        overflowY: "auto" as const,
        scrollbarWidth: "none" as const,
        maxHeight: "100%",
    },
    titleContainer: {
        width: "fit-content",
        height: "43px",
        backgroundColor: "#64748B",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center" as const,
        alignItems: "center",
    },
    title: {
        fontFamily:'Raleway',
        fontSize: "22px",
        padding: "16px",
        fontWeight: 'bold'
    },
    description: {
        fontSize: "18px",
        textAlign: "justify" as const,
        letterSpacing: "0.7px",
        paddingTop: "10px",
    },
    courseName: {
        display: "flex",
        alignItems: "center" as const,
        padding: "30px 0",
        flexDirection: "column" as const,
        gap: "10px",
        position: "relative" as const,
        margin: "0 154px",
    },
    btnBox: {
        width: "84%",
        padding: "40px",
        height: "auto",
        background: "#0F172A",
        borderRadius: "20px",
        margin: "30px auto",
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "center",
        gap: "10px",
    },
    btn1: {
        width: "230px",
        height: "44px",
        border: "1px solid #72EFDD",
        borderRadius: "20px",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        letterSpacing: "0.8px",
    },
    btn2: {
        cursor:'pointer',
        width: "230px",
        height: "44px",
        background: "#72EFDD",
        border: "1px solid #72EFDD",
        borderRadius: "20px",
        fontSize: "16px",
        letterSpacing: "0.8px",
        fontWeight: 600,
        fontFamily:'Raleway'
    },
    videoContainer: {
        width: "100%",
        marginTop: "20px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    video: {
        width: "100%",
        maxWidth: "770px",
        borderRadius: "10px",
        backgroundColor: "transparent",
    },
    tabsContainer: {
        display: "flex",
        gap: "10px",
        overflowX: "auto" as const,
        padding: "10px 0",
        marginBottom: "20px",
    },
    tab: {
        padding: "8px 16px",
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
        fontSize: "16px",
        transition: "all 0.3s ease",
        whiteSpace: "nowrap" as const,
    },
    question: {
        color: '#64748B',
        fontSize: '16px',
        marginTop: '10px',
        fontFamily: "poppins"
    },
    questionScore: {
        marginTop: '16px',
        color: 'white',
        fontSize: '19px',
        fontWeight: 'bold',
        marginRight: '15px',
        fontFamily:'poppins',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
       
        width: '99px',
        height: '43px',
        borderRadius: '15px'
    },
    questionMessage: {
        fontWeight: 500,
        color: '#334155',
        fontSize: '16px',
        marginTop: '10px',
        fontFamily: "poppins"
    },
    wholeContent: {
        fontSize: '18px',
        marginRight: '10px',
        marginTop: '30px',
        fontFamily: "poppins"
    },
    imgDiv: {
        padding: "20px 0",
    },
    imgTag: {
        borderRadius: "10px",
    },
};
// Customizable Area End
