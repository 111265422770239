import React, { useRef, useEffect, useState, useCallback } from "react";
import {
  Box,
  IconButton,
  Slider,
  Menu,
  MenuItem,
  Typography,
  Popover,
} from "@material-ui/core";
import {
  PlayArrow,
  Pause,
  VolumeUp,
  VolumeOff,
  Replay10,
  Forward10,
  Settings,
  Speed,
} from "@material-ui/icons";

interface WaveformProps {
  height: number;
  isPlaying: boolean;
}

interface WaveformProps {
  height: number;
  isPlaying: boolean;
}

const Waveform: React.FC<WaveformProps> = ({ height, isPlaying }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [barHeights, setBarHeights] = useState<number[]>(
    Array.from({ length: 100 }, () => 20 + Math.random() * 40)
  );

  const drawWaveform = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const { width, height } = canvas;
    const bars = 100;
    const barWidth = 4;
    const barGap = 2;
    const totalBarWidth = barWidth + barGap;

    ctx.clearRect(0, 0, width, height);

    const gradient = ctx.createLinearGradient(0, 0, width, 0);
    gradient.addColorStop(0, "#64748B");
    gradient.addColorStop(0.5, "#869CBD");
    gradient.addColorStop(1, "#64748B");

    // Update bar heights with smooth transitions
    const newBarHeights = barHeights.map((height) => {
      const targetHeight = 20 + Math.random() * 60;
      const diff = targetHeight - height;
      return height + diff * 0.1; // Smooth transition factor
    });

    setBarHeights(newBarHeights);

    for (let i = 0; i < bars; i++) {
      const barHeight = isPlaying ? newBarHeights[i] : 20 + Math.random() * 40;
      const x = i * totalBarWidth;
      const y = (height - barHeight) / 2;

      ctx.fillStyle = gradient;

      // Custom rounded rectangle path
      ctx.beginPath();
      ctx.moveTo(x, y + 2);
      ctx.lineTo(x + barWidth, y + 2);
      ctx.lineTo(x + barWidth, y + barHeight - 2);
      ctx.lineTo(x, y + barHeight - 2);
      ctx.closePath();
      ctx.fill();
    }
  }, [barHeights, isPlaying]);

  useEffect(() => {
    let animationFrameId: number;

    const animate = () => {
      drawWaveform();
      if (isPlaying) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(animate);
    }

    // Cleanup function
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [drawWaveform, isPlaying]);

  return (
    <canvas
      ref={canvasRef}
      width={600}
      height={height}
      style={{
        width: "100%",
        height: height,
        background: "transparent",
        borderRadius: "10px",
      }}
    />
  );
};

// export default React.memo(Waveform);
interface AudioPlayerState {
  isPlaying: boolean;
  currentTime: number;
  duration: number;
  volume: number;
  isMuted: boolean;
  previousVolume: number;
  playbackRate: number;
  settingsAnchorEl: null | HTMLElement;
  volumeAnchorEl: null | HTMLElement;
}

class CustomAudioPlayer extends React.Component<any, AudioPlayerState> {
  private audioRef: HTMLAudioElement | null = null;
  private progressRef: HTMLDivElement | null = null;

  state = {
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    volume: 1,
    isMuted: false,
    previousVolume: 1,
    playbackRate: 1,
    settingsAnchorEl: null,
    volumeAnchorEl: null,
  };

  componentDidMount() {
    if (this.audioRef) {
      this.audioRef.addEventListener(
        "loadedmetadata",
        this.handleLoadedMetadata
      );
      this.audioRef.addEventListener("timeupdate", this.handleTimeUpdate);
    }
  }

  componentWillUnmount() {
    if (this.audioRef) {
      this.audioRef.removeEventListener(
        "loadedmetadata",
        this.handleLoadedMetadata
      );
      this.audioRef.removeEventListener("timeupdate", this.handleTimeUpdate);
    }
  }

  handleLoadedMetadata = () => {
    if (this.audioRef) {
      this.setState({ duration: this.audioRef.duration });
    }
  };

  handleTimeUpdate = () => {
    if (this.audioRef) {
      this.setState({ currentTime: this.audioRef.currentTime });
    }
  };

  togglePlay = () => {
    if (this.audioRef) {
      if (this.state.isPlaying) {
        this.audioRef.pause();
      } else {
        this.audioRef.play();
      }
      this.setState((prevState) => ({ isPlaying: !prevState.isPlaying }));
    }
  };

  handleProgressClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.progressRef && this.audioRef) {
      const rect = this.progressRef.getBoundingClientRect();
      const pos = (event.clientX - rect.left) / rect.width;
      this.audioRef.currentTime = pos * this.state.duration;
    }
  };

  handleVolumeChange = (_event: any, newValue: number | number[]) => {
    const volumeValue = newValue as number;
    if (this.audioRef) {
      this.audioRef.volume = volumeValue;
      this.setState({
        volume: volumeValue,
        isMuted: volumeValue === 0,
      });
    }
  };

  toggleMute = () => {
    if (this.audioRef) {
      if (this.state.isMuted) {
        this.audioRef.volume = this.state.previousVolume;
        this.setState({
          isMuted: false,
          volume: this.state.previousVolume,
        });
      } else {
        this.setState({
          isMuted: true,
          previousVolume: this.state.volume,
          volume: 0,
        });
        this.audioRef.volume = 0;
      }
    }
  };

  handlePlaybackRateChange = (rate: number) => {
    if (this.audioRef) {
      this.audioRef.playbackRate = rate;
      this.setState({
        playbackRate: rate,
        settingsAnchorEl: null,
      });
    }
  };

  skipTime = (seconds: number) => {
    if (this.audioRef) {
      this.audioRef.currentTime += seconds;
    }
  };

  formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ settingsAnchorEl: event.currentTarget });
  };

  handleSettingsClose = () => {
    this.setState({ settingsAnchorEl: null });
  };

  handleVolumeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ volumeAnchorEl: event.currentTarget });
  };

  handleVolumeClose = () => {
    this.setState({ volumeAnchorEl: null });
  };

  render() {
    const {
      isPlaying,
      currentTime,
      duration,
      volume,
      isMuted,
      playbackRate,
      settingsAnchorEl,
      volumeAnchorEl,
    } = this.state;

    const playbackRates = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

    return (
      <Box
        style={{
          width: "100%",
          maxWidth: "850px",
          padding: "20px",
          background: "transparent",
          borderRadius: "20px",
          color: "white",
        }}
      >
        <audio
          ref={(ref) => (this.audioRef = ref)}
          src={this.props.audioUrl}
          style={{ display: "none" }}
        />

        {/* Waveform Visualization (Static) */}
        <Box
          style={{
            height: "60px",
            marginBottom: "20px",
            background: "transoarent",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            padding: "0 10px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* Static waveform bars */}
          <Waveform height={60} isPlaying={this.state.isPlaying} />
        </Box>

        {/* Time Display */}
        <Box display="flex" justifyContent="space-between" marginBottom="10px">
          <span>{this.formatTime(currentTime)}</span>
          <span>{this.formatTime(duration)}</span>
        </Box>

        {/* Progress Bar */}
        <div
          ref={(ref: HTMLDivElement | null) => (this.progressRef = ref)}
          onClick={this.handleProgressClick}
          style={{
            width: "100%",
            height: "4px",
            background: "#475569",
            borderRadius: "2px",
            cursor: "pointer",
            marginBottom: "20px",
            position: "relative",
          }}
        >
          <Box
            style={{
              width: `${(currentTime / duration) * 100}%`,
              height: "100%",
              background: "#72EFDD",
              borderRadius: "2px",
              position: "absolute",
            }}
          />
        </div>

        {/* Controls */}
        {/* Volume Control Popover */}
        <Popover
          className="scroll-container"
          open={Boolean(volumeAnchorEl)}
          anchorEl={volumeAnchorEl}
          onClose={this.handleVolumeClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Box
            style={{
              padding: "20px",
              height: "150px",
              background: "#526074",
            }}
          >
            <Slider
              orientation="vertical"
              value={volume}
              onChange={this.handleVolumeChange}
              aria-labelledby="vertical-slider"
              min={0}
              max={1}
              step={0.01}
              style={{
                color: "#fff",
              }}
            />
          </Box>
        </Popover>

        {/* Settings Menu */}
        <Menu
          anchorEl={settingsAnchorEl}
          keepMounted
          open={Boolean(settingsAnchorEl)}
          onClose={this.handleSettingsClose}
          PaperProps={{
            style: {
              background: "#1E293B",
              color: "white",
            },
          }}
        >
          <MenuItem disabled style={{ color: "#72EFDD" }}>
            <Speed style={{ marginRight: "8px" }} />
            Playback Speed
          </MenuItem>
          {playbackRates.map((rate) => (
            <MenuItem
              key={rate}
              onClick={() => this.handlePlaybackRateChange(rate)}
              selected={playbackRate === rate}
              style={{
                paddingLeft: "32px",
                background: playbackRate === rate ? "#72EFDD33" : "transparent",
              }}
            >
              {rate}x
            </MenuItem>
          ))}
        </Menu>

        {/* Controls */}
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            background: "#64748B",
            borderRadius: "40px",
            padding: "10px 0",
          }}
        >
          <IconButton
            onClick={isMuted ? this.toggleMute : this.handleVolumeClick}
            style={{ color: "white", background: "#050D1533" }}
          >
            {isMuted ? <VolumeOff /> : <VolumeUp />}
          </IconButton>

          <IconButton
            onClick={() => this.skipTime(-10)}
            style={{ color: "white", background: "#050D1533" }}
          >
            <Replay10 />
          </IconButton>

          <IconButton
            onClick={this.togglePlay}
            style={{
              color: "white",
              background: "#050D1533",
              padding: "15px",
            }}
          >
            {isPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>

          <IconButton
            onClick={() => this.skipTime(10)}
            style={{ color: "white", background: "#050D1533" }}
          >
            <Forward10 />
          </IconButton>
          <IconButton
            onClick={this.handleSettingsClick}
            style={{ color: "white", background: "#050D1533" }}
          >
            <Settings />
          </IconButton>
        </Box>

        {/* Additional Controls */}
        <Box
          display="flex"
          justifyContent="space-between"
          marginTop="20px"
        ></Box>
      </Box>
    );
  }
}

export default CustomAudioPlayer;
