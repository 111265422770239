import React from "react";

import {
  Box,
  Button,
  Grid,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  bookLight,
} from "../../catalogue1/src/assets";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import Footer from "./Footer.web";
import "./Header.css";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Customizable Area End

import Catalogue1Controller, { Props } from "./Catalogue1Controller";
import Header from "./Header.web";

export default class OrganSystems extends Catalogue1Controller {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  settingslider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };

  taskSelectionSystem = () => {
    return (
      <Box className="taskContainer">
        <Grid container>
          <Grid item lg={3}>
            <Box style={webStyle.boxStyle}>
              <h2
                className="welcomeTxt"
                style={webStyle.welcomeText}
              >
                <span style={{ fontSize: '20px' }}>MedEd Journeys</span>
                <Box style={webStyle.MedBox}>
                  <Box style={webStyle.MedBox2}>
                    <ArrowLeftIcon style={webStyle.leftIcon} />
                  </Box>
                  <span style={webStyle.IconSystem}>Organ Systems</span>
                </Box>
              </h2>
            </Box>
          </Grid>
          <Grid item lg={9}>
          </Grid>
        </Grid>
      </Box >
    );
  };

  recommendedSystem = () => {
    return (
      <Box style={{ marginTop: "50px" }} className="medMainContainer11">
        <Box>
          <Grid>
            <Slider {...this.settingslider}>
              {this.state.medRecommanded && this.state.medRecommanded.map((card: any, index: number) => {
                return (
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{ flexShrink: 0 }} key={index} data-testid={`card-${index}`}>
                    <Box className="cardMapContainer card22" style={webStyle.card22}>
                      <Box style={webStyle.card23}>
                        <Box className="cardimagedata"
                          style={{
                            width: "100%",
                            height: "200px",
                            opacity: '0.5',
                            margin: "-4px 0px 0px",
                            backgroundImage: `url(${card.attributes.image.url})`,
                            position: "relative",
                            backgroundSize: 'auto 230px',
                            backgroundPosition: '-20px -5px',
                            backgroundRepeat: 'no-repeat'
                          }}
                        />


                        <Box
                          style={webStyle.cardImage}
                        >
                          <Box>
                            <Box style={webStyle.cardImage1}>
                              <Box
                                style={webStyle.cardImage12}
                              >
                                <img
                                  src={card.attributes.parent_category_icon?.url}
                                  style={{
                                    color: "white",
                                    width: '70%',
                                    height: '70%',
                                    objectFit: 'contain',
                                    borderRadius: '50%'
                                  }}
                                  alt="recommand parent icon"
                                />
                              </Box>
                              <Typography
                                style={webStyle.cardText}
                                variant="body1"
                                data-testid={`card-name-${index}`}
                              >
                                {card.attributes.name}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography className="cardTitle" style={{ minHeight: '60px' }}>
                                {card.attributes.first_course_name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        style={webStyle.courseBox}
                      >
                        <Box
                          style={webStyle.courseBox1}
                        >
                          <Box style={{ display: "flex" }}>
                            <img src={bookLight}
                              style={webStyle.courseImg}
                            />
                            <Typography
                              style={webStyle.courseText}
                              className="font-family-p"
                            >
                              {card.attributes.all_courses_count > 1 ? `${card.attributes.all_courses_count} Unit` : `${card.attributes.all_courses_count} Unit`}
                            </Typography>
                          </Box>
                          <Box
                            style={webStyle.outline}
                            fontSize="small"
                          >
                            <PeopleOutlineOutlinedIcon
                              fontSize="small"
                              style={{ color: "white" }}

                            />
                            <Typography
                              style={{ fontSize: "13px", color: "rgba(255,255,255,0.6)" }}
                              className="font-family-p"
                            >
                              {card.attributes.level}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          style={webStyle.autoBox}
                        >
                          <Box className="course_desc" style={webStyle.autoBox1}>
                            <Typography
                              style={{ color: "rgba(255,255,255,0.6)", fontSize: "14px" }}
                            >
                              {card.attributes.description}
                            </Typography>
                          </Box>
                        </Box>
                        <Box style={{ textAlign: "center" }} />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Slider>
          </Grid>
        </Box>
        <Button
          fullWidth
          className="button-with-gradient-border"
          style={webStyle.buttonNavigate}
          data-test-id="ExploreJourney"
          onClick={() => this.props.navigation.navigate("OrganSystemsNext")}
        >
          <span
            style={webStyle.buttonCont}
          >
            Continue
          </span>
        </Button>
      </Box>
    );
  };



  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.backgroundCLr1}>
        <Header navigation={this.props.navigation} id={"3"} />
        {this.taskSelectionSystem()}
        {this.recommendedSystem()}
        <Footer navigation={this.props.navigation} id={""} />
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  backgroundCLr1: {
    backgroundColor: "rgb(28, 34, 53)",
  },
  boxStyle: {
    width: '96%',
    margin: '0 auto'
  },
  welcomeText: {
    width: "87%",
    display: "flex" as "flex",
    flexDirection: 'column' as 'column'
  },
  MedBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px'
  },
  MedBox2: {
    width: '30px',
    height: '30px',
    backgroundColor: '#121826',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '15px'
  },
  leftIcon: {
    margin: "0 10px",
  },
  IconSystem: {
    fontWeight: 'bold' as 'bold',
    fontSize: '25px'
  },
  card22: {
    width: '100%'
  },
  card23: {
    position: "relative" as "relative"
  },
  cardImage: {
    width: "100%",
    position: "absolute" as "absolute",
    left: "25px",
    bottom: "24px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardImage1: {
    display: "flex"
  },
  cardImage12: {
    width: "20px",
    height: "20px",
    background: "rgba(51, 65, 85, 0.6)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardText: {
    color: "rgba(255,255,255,0.8)",
    fontSize: "12px",
    marginLeft: 5,
    fontWeight: 500,
    textTransform: 'uppercase' as 'uppercase'
  },
  courseBox: {
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    margin: "-5px 5px",
    padding: "30px 20px",
    backgroundColor: "#172130",
  },
  courseBox1: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  courseImg: {
    color: "#fff",
    marginRight: 5,
    width: 18,
    height: 18
  },
  courseText: {
    color: "rgba(255,255,255,0.6)",
    fontSize: "13px",
  },
  outline: {
    marginRight: 5,
    display: "flex",
    gap: 4
  },
  autoBox: {
    display: "flex" as "flex",
    justifyContent: "space-between" as "space-between",
    width: "100%",
    marginTop: "20px",
    flexDirection: "column" as "column",
  },
  autoBox1: {
    overflow: "auto",
    height: "100px"
  },
  buttonNavigate: {
    color: "white" as "white",
    fontWeight: 800,
    background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
    borderRadius: "100px",
    width: "30%",
    height: "32px",
    padding: "2px",
    textTransform: "capitalize" as "capitalize",
    overflow: "hidden",
    display: 'flex',
    margin: 'auto'
  },
  buttonCont: {
    width: "100%",
    // backgroundColor: "#1E293B",
    borderRadius: "100px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
};
// Customizable Area End
