import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
interface Lesson {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    image: {
      url: string;
    } | null;
    video: {
      url: string;
    } | null;
    audio: {
      url: string;
    } | null;
    table: {
      url: string;
    } | null;
    completed?: boolean;
  };
}
interface Section {
  id: number;
  name: string;
  description: string;
  image?: string | null;
  lessons: {
    data: Lesson[];
  };
  checked: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  unitId: number | null;
  currentSection: Section;
  data: {
    unit_name: string;
    course_name: string;
    journey_name: string;
    sections: Section[];
  } | null;
  loading: boolean;
  error: string | null;
  currentLessonIndex: number;
  scrollPercentages: number;
  open: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrganSystemUnitController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getUnitCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      unitId: null,
      currentSection: {
        id: 0,
        name: "",
        description: "",
        image: null,
        lessons: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                id: 0,
                name: "",
                description: "",
                image: {
                  url: "",
                },
                video: {
                  url: "",
                },
                audio: {
                  url: "",
                },
                table: {
                  url: "",
                },
              },
            },
          ],
        },
        checked: false,
      },
      data: null,
      loading: false,
      error: null,
      currentLessonIndex: 0,
      scrollPercentages: 6,
      open: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const unitId = this.props.navigation.getParam("unitId", null);
    const token = await getStorageData("signupToken");
    this.setState({ token: token, unitId: unitId }, () => {
      this.getUnitDetails(unitId, token);
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.getUnitCallId) {
      if (responseJson?.course_name) {
       
        this.setState({
          loading: false,
          data: responseJson,
          currentSection: responseJson?.sections[0] || null,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  
  goBackfromUnit = () => {
    this.props.navigation.goBack()
  }

  getUnitDetails = (unitId: number, token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUnitCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unitEndpoint}=${unitId}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    this.setState({ loading: true });
  };
 
  // Customizable Area End
}
