import React, { useState } from "react";
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse,
    Typography,
    Divider,
    IconButton,
    CssBaseline,
    Box,
    Button,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    AppBar,
    Toolbar,
} from "@material-ui/core";
import {
    ExpandLess,
    ExpandMore,
    ChevronLeft,
    RadioButtonUnchecked,
    Done,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import steps from "antd/lib/steps";
import SettingsIcon from "@material-ui/icons/Settings";
import Header from "../../blocks/catalogue1/src/Header.web";
import Footer from "../../blocks/catalogue1/src/Footer.web";

const useStyles = makeStyles((theme) => ({
    // drawer: {
    //     width: 350,
    //     flexShrink: 0,
    //     overflow: 'hidden',
    //     height: 'auto',
    // },
    // drawerPaper: {
    //     width: 350,
    //     backgroundColor: "#0F172A",
    //     color: "#FFFFFF",
    //     overflow: 'hidden',
    //     height: 'auto',
    // },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    header: {
        display: "flex",
        alignItems: "center",
        padding: '0px 16px',
        margin: '0px 15px'
        // backgroundColor: "#0F172A",
    },
    closeButton: {
        // marginRight: "10px",
    },
    sectionTitle: {
        fontWeight: "bold",
        padding: "10px 16px",
    },
    listItem: {
        color: "#FFFFFF",
    },
    selectedLesson: {
        backgroundColor: "#72EFDD33",
        borderRadius: "10px",
    },
    iconSelected: {
        color: "#72EFDD",
    },
}));

function getSteps() {
    return ["Select campaign settings", "Create an ad group", "Settings"];
}

function getStepContent(step: any) {
    switch (step) {
        case 0:
            return ``;
        case 1:
            return "";
        case 2:
            return ``;
        default:
            return "Unknown step";
    }
}

export default function SidebarDrawer(Style: any, open:any) {
    console.log(Style,'kkkkkkkkkkk');
    
    const classes = useStyles();
    const [openCongenital, setOpenCongenital] = useState(true);
    const [openAcquired, setOpenAcquired] = useState(true);
    const [selectedLesson, setSelectedLesson] = useState(4); // Example: Selected lesson 4
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleToggleCongenital = () => {
        setOpenCongenital(!openCongenital);
    };

    const handleToggleAcquired = () => {
        setOpenAcquired(!openAcquired);
    };

    const handleLessonClick = (lessonIndex: any) => {
        setSelectedLesson(lessonIndex);
    };

    return (
        <div>
            <div>
            <Drawer
              style={Style.drawer}
              variant="permanent"
              anchor="left"
              open={open}
              PaperProps={{ style: open ? Style.drawerOpen : Style.drawerClose }}
            >

              <AppBar
                className="navHeader"
                style={open ? { ...Style.appBar, ...Style.appBarShift } : Style.appBar}

              >

              </AppBar>
                <div>
                    <Typography
                        // variant="h6"
                        style={{ color: "#fff", padding: '0px 70px', fontSize: '20px' }}
                    >
                        Cardiovascular
                    </Typography>
                    <div className={classes.header}>
                        <Box style={{
                            width: '30px',
                            height: '30px',
                            backgroundColor: '#121826',
                            borderRadius: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '15px'
                        }}>
                            <IconButton className={classes.closeButton}>
                                <ChevronLeft style={{ color: "white" }} />
                            </IconButton>
                        </Box>
                        <Typography
                            variant="h6"
                            style={{ color: "white", fontWeight: "bold" }}
                        >
                            Pathology
                        </Typography>
                    </div>
                </div>
                <Divider />


                <List>
                    <ListItem
                        button
                        onClick={handleToggleCongenital}
                        className={classes.listItem}
                    >
                        <ListItemText primaryTypographyProps={{
                            style: { fontSize: '20px', fontWeight: 'bold', padding: '10px 16px' },
                        }} primary="Congenital Heart Diseases" />
                        {openCongenital ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Stepper style={{ backgroundColor: 'transparent' }} activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    icon={index === 2 ? <SettingsIcon style={{ color: '#fff' }} /> : index + 1}>
                                    <Typography style={{ fontSize: '20px', color: '#fff' }}>
                                        {label}
                                    </Typography>
                                </StepLabel>
                                <StepContent>
                                    <Typography style={{ color: '#fff' }}>{getStepContent(index)}</Typography>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </List>
                <Divider />

                <List>
                    <ListItem
                        button
                        onClick={handleToggleCongenital}
                        className={classes.listItem}
                    >
                        <ListItemText primaryTypographyProps={{
                            style: { fontSize: '20px', fontWeight: 'bold', padding: '10px 16px' },
                        }} primary="Congenital Heart Diseases" />
                        {openCongenital ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Stepper style={{ backgroundColor: 'transparent' }} activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    icon={index === 2 ? <SettingsIcon style={{ color: '#fff' }} /> : index + 1}>
                                    <Typography style={{ fontSize: '20px', color: '#fff' }}>
                                        {label}
                                    </Typography>
                                </StepLabel>
                                <StepContent>
                                    <Typography style={{ color: '#fff' }}>{getStepContent(index)}</Typography>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </List>


                {/* Acquired Heart Diseases */}
                <List>
                    <ListItem
                        button
                        onClick={handleToggleAcquired}
                        className={classes.listItem}
                    >
                        <ListItemText primaryTypographyProps={{
                            style: { fontSize: '20px', fontWeight: 'bold', padding: '10px 16px' },
                        }} primary="Acquired Heart Diseases" />
                        {openAcquired ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Stepper style={{ backgroundColor: 'transparent' }} activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    icon={index === 2 ? <SettingsIcon style={{ color: '#fff' }} /> : index + 1}>
                                    <Typography style={{ fontSize: '20px', color: '#fff' }}>
                                        {label}
                                    </Typography>
                                </StepLabel>
                                <StepContent>
                                    <Typography style={{ color: '#fff' }}>{getStepContent(index)}</Typography>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </List>

                <List>
                    <ListItem
                        button
                        onClick={handleToggleAcquired}
                        className={classes.listItem}
                    >
                        <ListItemText primaryTypographyProps={{
                            style: { fontSize: '20px', fontWeight: 'bold', padding: '10px 16px' },
                        }} primary="Acquired Heart Diseases" />
                        {openAcquired ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Stepper style={{ backgroundColor: 'transparent' }} activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    icon={index === 2 ? <SettingsIcon style={{ color: '#fff' }} /> : index + 1}>
                                    <Typography style={{ fontSize: '20px', color: '#fff' }}>
                                        {label}
                                    </Typography>
                                </StepLabel>
                                <StepContent>
                                    <Typography style={{ color: '#fff' }}>{getStepContent(index)}</Typography>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </List>
                {/* <main style={{
                    flexGrow: 1,
                    padding: '50px 24px',
                    // minHeight: '230vh'
                    height: 'calc(100vh - 64px)',
                    overflow: 'auto',
                }}>
                    <Toolbar />
                    <Typography paragraph>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
                        facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
                        gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
                        donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                        adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras.
                        Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis
                        imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget
                        arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                        donec massa sapien faucibus et molestie ac.
                    </Typography>

                </main> */}

            </Drawer>
            </div>
            {/* <Footer navigation={navigation} id={""} />  */}
        </div>
    );
}
