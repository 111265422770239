import React from "react";

// Customizable Area Start
import { Container, Grid } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { medEdLogo } from "../../signuplogin/src/assets";
import { facebook, instagram, xIcon, linkedin, youtube } from "../../landingpage/src/assets";


// Customizable Area End

import Catalogue1Controller, {
  Props,
} from "./Catalogue1Controller";
import "./Header.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Footer extends Catalogue1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  renderNav = () => {
    return (
      <div className="landing-page-footer">
        <div className="footer-top">
          <Grid container>
            <Grid item lg={9} md={9} sm={6} xs={12}>
              <div className="footer-links-container">

                <div className="footer-links-wrapper">
                  <h4 className="links-heading">Our Company</h4>
                  <ul className="footer-links" data-testid="footer-links">
                    <li
                      className="footer-link"
                    >
                      <a href="#about-us">About Us</a>
                    </li>
                    <li
                      className="footer-link"
                    >
                      <a href="#features-and-benefits">Features & Benefits</a>
                    </li>
                    <li
                      className="footer-link"
                    >
                      <a href="#testimonials">Testimonials</a>
                    </li>
                    <li
                      className="footer-link"
                    >
                      <a href="#faqs">FAQs</a>
                    </li>
                    <li
                      className="footer-link"
                    >
                      <a href="#contact-us">Contact-us</a>
                    </li>
                  </ul>
                </div>

                <div className="footer-links-wrapper">
                  <h4 className="links-heading">Our Services</h4>
                  <ul className="footer-links" data-testid="footer-links">
                    <li
                      className="footer-link"
                    >
                      <a href="#for-schools">For Schools</a>
                    </li>
                    <li
                      className="footer-link"
                    >
                      <a href="#for-students">For Students</a>
                    </li>
                    <li
                      className="footer-link"
                    >
                      <a href="#for-government">For Government</a>
                    </li>
                    <li
                      className="footer-link"
                    >
                      <a href="#pricing">Pricing</a>
                    </li>
                  </ul>
                </div>

                <div className="footer-links-wrapper">
                  <h4 className="links-heading">Legal</h4>
                  <ul className="footer-links" data-testid="footer-links">
                    <li
                      className="footer-link"
                      onClick={() => this.goToTermsAndConditions()}
                    >Security
                    </li>
                    <li
                      className="footer-link"
                      onClick={this.goToTermsAndConditions}
                    >Privacy Policy
                    </li>
                    <li
                      className="footer-link"
                      onClick={this.goToTermsAndConditions}
                      data-testid="termsLink"
                    >Terms and Conditions
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item lg={3} md={9} sm={6} xs={12}>
              <div className="motto">
                <img
                  src={medEdLogo}
                  className="logo footer-logo"
                  alt="medEd-logo"
                  data-testid="logo"
                />
                <p>MedEd <span style={{ fontWeight: 300 }}>CLOUD</span> is your reliable partner for navigating the
                  complexities of medical education, offering a seamless and
                  interactive learning experience.</p>
              </div>
            </Grid>
          </Grid>



        </div>
        <span className="footer-divider"></span>
        <div className="social-links-container">
          <p className="footnote">2024 MedEd Cloud. All rights reserved.</p>
          <div className="social-links" data-testid="socialLinks">
            <a href="#" target="_blank" className="social-link-wrapper">
              <img
                src={facebook}
                alt="facebook-icon"
                className="sm-icons"
              />
            </a>
            <a href="#" target="_blank" className="social-link-wrapper">
              <img
                src={instagram}
                alt="instagram-icon"
                className="sm-icons"
              />
            </a>
            <a href="#" target="_blank" className="social-link-wrapper">
              <img
                src={xIcon}
                alt="x-icon"
                className="sm-icons"
              />
            </a>
            <a href="#" target="_blank" className="social-link-wrapper">
              <img
                src={linkedin}
                alt="linkedin-icon"
                className="sm-icons"
              />
            </a>
            <a href="#" target="_blank" className="social-link-wrapper">
              <img
                src={youtube}
                alt="youtube-icon"
                className="sm-icons"
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <div
        // maxWidth="xl"
        className="container-header"
      >
        {this.renderNav()}

      </div>
      // Customizable Area End
    );
  }
}
