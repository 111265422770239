import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Skeleton } from "@mui/material";
import { skipBook, skipGroup, skipIconBook, arrowPrev } from "./assets";
import "./Header.css";
// Customizable Area End

import Catalogue1Controller, {Props} from "./Catalogue1Controller";

export default class SubHeader extends Catalogue1Controller  {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderSkeletonLoader = () => {
    return (
      <div className="subheader">
        <>
          <style>
            {`
              .subheader .MuiSkeleton-root {
                background-color: rgba(255, 255, 255, 0.11);
              }

              .subheader .leftheader .MuiSkeleton-root {
                margin: 4px 0;
              }

              .subheader .journeyData p .MuiSkeleton-root {
                display: inline-block;
                margin-left: 8px;
              }

              .subheader .journeyData span .MuiSkeleton-root {
                vertical-align: middle;
              }
            `}
          </style>
        </>
        <div className="headerData">
          <div className="leftheader">
            <div className="journeyback">
              <Skeleton variant="circular" width={24} height={24} />
            </div>
            <div>
              <Skeleton variant="text" width={120} height={24} />
              <Skeleton variant="text" width={180} height={20} />
            </div>
          </div>
          <div className="rightheader">
            <div className="journeyData">
              <p>
                <span>
                  <Skeleton variant="circular" width={20} height={20} />
                </span>
                <Skeleton variant="text" width={100} height={20} />
              </p>
              <p>
                <span>
                  <Skeleton variant="circular" width={20} height={20} />
                </span>
                <Skeleton variant="text" width={80} height={20} />
              </p>
              <p>
                <span>
                  <Skeleton variant="circular" width={20} height={20} />
                </span>
                <Skeleton variant="text" width={150} height={20} />
              </p>
            </div>
            <p className="journeytext">
              <Skeleton variant="text" width="100%" height={60} />
            </p>
          </div>
        </div>
      </div>
    );
  };

  subHeader = () => {
    if (!this.props?.data?.attributes) {
      return this.renderSkeletonLoader();
    }
   
    return (
      <div className="subheader">
        <div className="headerData">
          <div className="leftheader">
           <div datat-testid = "backmenu" className="journeyback"> <img src ={arrowPrev}  onClick={this.props.onGoBack}/> </div>
            <div className="subHeading">
              <h4>MedEd Journey</h4>
              <p>{this.props.data?.attributes?.name}</p>
            </div>
          </div>
          <div className="rightheader">
            <div className="journeyData">
              <p>
                <span>
                  <img src={skipIconBook} />
                </span>
                {this.props.data?.attributes?.parent_category_name}
              </p>
              <p>
                <span>
                  <img src={skipGroup} />
                </span>
                {typeof this.props.data?.attributes?.level === "number"
                  ? "Level"
                  : ""}{" "}
                {this.props.data?.attributes?.level}
              </p>
              <p>
                <span>
                  <img src={skipBook} />
                </span>
                {this.props.data?.attributes?.all_units_count != null ||
                this.props.data?.attributes?.all_units_count != undefined ? (
                  <>
                    {this.props.data?.attributes?.completed_units} of{" "}
                    {this.props.data?.attributes?.all_units_count} Units
                    Completed
                  </>
                ) : (
                  <>
                    {this.props.data?.attributes?.completed_courses} of{" "}
                    {this.props.data?.attributes?.all_courses_count} {this.props.data?.attributes?.all_courses_count == 1 ? "Course" : "Courses"}
                    {" "}Completed
                  </>
                )}
              </p>
            </div>
            <p className="journeytext">
            {this.props.data?.attributes?.description}
            </p>
          </div>
        </div>
      </div>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>{this.subHeader()}</Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
