export const GROUP = require("../assets/Group.png");
export const PDF1 = require("../assets/PDFCopy.png");
export const PDF2 = require("../assets/PDFCopy2.png");
export const LEFTARROW = require("../assets/arrow-left.png");
export const WhiteStar = require("../assets/StarCopy.png")
export const yellowStar = require("../assets/Star.png")
export const DROPDOWN = require("../assets/dropDawnIcone1.png")
export const PurpleView = require("../assets/Path.png")
export const Purplebigview = require("../assets/Path2.png")
export const TIME = require("../assets/Time.png")
export const REDBOX = require("../assets/RedBox.png")
export const BELL = require("../assets/BellIcon.png")
export const DropDownIcon = require("../assets/DropDownimg.png")
export const UPLOADEDIMG = require("../assets/UploadingImg.png")
export const Attachments = require("../assets/Attachments.png")
export const QuestionsCopy = require("../assets/QuestionsCopy.png")
export const uploadedfile = require("../assets/uploadedfile.png")
export const uploadingfile = require("../assets/Uploading.png")
export const Free_Button = require("../assets/Free_Button.png")
export const Pro_Button = require("../assets/Pro_Button.png")
export const leave1 = require("../assets/leave1.png")
export const cross = require("../assets/cross.png")
export const tryagain=require("../assets/tryagain.png")
export const skipexam=require("../assets/skipexam.png")
export const viewresult=require("../assets/viewresult.png")
export const right=require("../assets/right.png");
export const missed=require("../assets/missed.png");
export const wrong=require("../assets/wrong.png");
export const arrow=require("../assets/chevron_left.png");
export const exam=require("../assets/skip_exam.png");
export const lessons=require("../assets/skip_lessons.png");
export const section=require("../assets/skip_sections.png");
export const incorrect=require("../assets/incorrect.png");
export const correct=require("../assets/skip_icon.png");
export const backbutton=require("../assets/button_back.png");


