// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
interface Lesson {
  attributes: {
    id: number;
    name: string;
    description: string;
    audio: {
      url: string;
    } | null;
    video: {
      url: string;
    } | null;
    table: {
      url: string;
    } | null;
    image: {
      url: string;
    } | null;
   
    completed?: boolean;
  };
  id: string;
  type: string;
}
interface Section {
  
  description: string;
  image?: string | null;
  sections: {
    data: Lesson[];
  };
  id: number;
  name: string;
  checked: boolean;
  sub_category_id?: number
}


export const configJSON = require("./config");

export interface Props {
 
  id: string;
  navigation: any;
  // Customizable Area Start
  history:any
  // Customizable Area End
}

interface S {
  token: string;
  // Customizable Area Start
  unitId: number | null;
  error: string | null;
  SectionData:any,
  scoreUnit:any,
  currentSection: Section;
  currentLessonIndex: number;
  scrollPercentages: number;
  open: boolean;
  nextUnit:number,
  openMore:boolean;
  detailsShow:string;
  sectionID:number,
  data: {
    unit_name: string;
    course_name: string;
    journey_name: string;
    topics: Section[];
  } | null;
  loading: boolean;
 
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ScoreController extends BlockComponent<Props, S, SS> {
 
  getUnitCallId: any;
  getScore:any;
  getSectionResultValue:any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      token: "",
      currentSection: {
        id: 0,
        name: "",
      
        sections: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                id: 0,
               
               
                table: {
                  url: "",
                },
                name: "",
                description: "",
                image: {
                  url: "",
                },
                video: {
                  url: "",
                },
                audio: {
                  url: "",
                },
              },
            },
          ],
        },
        description: "",
        image: null,
        checked: false,
        sub_category_id: 0
      },
      unitId: null,
      scrollPercentages: 6,
      sectionID:0,
      nextUnit:0,
      openMore:false,
      detailsShow:'More details',
      SectionData:[],
      scoreUnit:[],
      data: null,
      loading: false,
      error: null,
      currentLessonIndex: 0,
      open: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.scrollTo(0, 0);
    
    const unitId = this.props.navigation.getParam("unitId", null);
    const token = await getStorageData("signupToken");
    this.setState({ token: token, unitId: unitId }, () => {
     let answer=   Number(localStorage.getItem("topicid"))
      this.getUnitDetails(answer, token);
      this.getSectionResult(token)
      this.getUnitScore(answer,token)
      
    });
    let currentUnit = Number(localStorage.getItem("topicid")); 
    // let totalUnit = localStorage.getItem("AllUnits"); 
    // let unitArray = totalUnit ? JSON.parse(totalUnit) : [];
    // let currentIndex = unitArray.indexOf(currentUnit);
    // let nextUnit = currentIndex !== -1 && currentIndex < unitArray.length - 1 ? (unitArray[currentIndex]) : -1;
    this.setState({nextUnit:currentUnit})
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.getUnitCallId) {
      if (responseJson?.course_name) {

        this.setState({
          loading: false,
          data: responseJson,
          currentSection: responseJson?.topics[0] || null,
        });
      }
     
    } 

    if (apiRequestCallId === this.getSectionResultValue) {
      
      if(responseJson.message =='No attempts found for this section.'){
        this.setState({SectionData:[]})
      }
     else  if (responseJson) {
    
       this.setState({SectionData:responseJson})   
      }
    }
    if(apiRequestCallId===this.getScore){
      if(responseJson && !responseJson.error){
        this.setState({scoreUnit:responseJson})   
      }
      else{
        this.setState({scoreUnit:[]})  
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  nextTopic() {
    let currentTopicId = Number(localStorage.getItem("currentTopicId"));
    currentTopicId = currentTopicId+1
    localStorage.setItem("currentTopicId", String(currentTopicId))
    let unitId=this.state.nextUnit;
    const target = localStorage.getItem('lastTopic') === 'true' ? "ViewUnitResult" : "Unit";
    const params = target === "Unit" ? { unitId } : undefined;
    this.props.navigation.navigate(target, params);
  }
  viewMore=()=>{

    if(this.state.detailsShow=='More details'){
    this.setState({detailsShow:'Less details',openMore:true})
    }
    else{
      this.setState({openMore:false,detailsShow:'More details'})
    }
  }
 
  handleColor=()=>{
    if(this.state.SectionData?.color==='Green'){
      return '#21D58E';
    }
    else if(this.state.SectionData?.color=='Orange'){
      return '#F59E0B'}
      return '#F87171';
  }
  handleDashboard=()=>{
    const courseID = this.state.currentSection.sub_category_id
    this.props.navigation.navigate("Course", {courseID})
  }
  handleColorUnit=()=>{
    if(this.state.scoreUnit?.score>=70){
      return '#21D58E';
    }
    else if(this.state.scoreUnit?.score>=50 && this.state.scoreUnit?.score<=69){
      return '#F59E0B'}
      return '#F87171';
  } 
  handleColorSections=(scoreUnit:any)=>{
    if(scoreUnit>=70){
      return '#21D58E';
    }
    else if(scoreUnit>=50 && scoreUnit<=69){
      return '#F59E0B'}
      return '#F87171';
  }
  handleDrawerOpen = () => {
    this.setState({ open: true });
};
handleView=()=>{
this.props.navigation.navigate("Viewresult")
}
handleDrawerClose = () => {
    this.setState({ open: false });
};
  getUnitDetails = (unitId: number, token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUnitCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unitEndpoint}=${unitId}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    this.setState({ loading: true });
  };
  handleRetry=()=>{
    this.props.navigation.navigate("QuestionBank")
  }
  getSectionResult = ( token: string) => {
    let sectionResult = localStorage.getItem("sectionID");
    if (!sectionResult || sectionResult === "undefined") {
      sectionResult = (localStorage.getItem("retryID"));
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSectionResultValue = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sectionEndPoint}=${sectionResult}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    
  };

  getUnitScore = (unitId: number, token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const unitRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getScore = unitRequest.messageId;

    unitRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unitScore}=${unitId}`
    );

    unitRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    unitRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(unitRequest.id, unitRequest);
    this.setState({ loading: true });
  };
  handleLessonChange = (index: number) => {
    this.setState({ currentLessonIndex: index });
  };
  // Customizable Area End
}
