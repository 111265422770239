
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { ReactNode } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  infoText3?: string;
  infoTextMt1?: string;
  infoTextMt2?: string;
  infoTextMt3?: string;
  bgImgPosition?: any;
  bgImgColor?: any;
  heading?: string;
  headingStyle?: string;
  infoText1?: string;
  infoText2?: string;
  auth?: any;
  setup?: boolean;
  goToSignup?: any;
  onhover?: any;
  onleave?: any;
  showInfo?: boolean;
  pgIcon?: any;
  contentContainerStyle?: any;
  bgImg?: any;
  showinfoData?: boolean;
  isNextBtn?: boolean;
  buttonText?: string;
  btnDisplay?: string;
  btnDisabled?: any;
  backBtn?: boolean
  goBack?: any;
  customForm?: ReactNode;
  isQuestionBank?: boolean;
  leaveClick?: () => void;
  infoTextStyle?: any;
  form?: any;
  formContent?: any;
  onclick?: any;
  popupError?: any;
  popupErrorStyle?: any;
  warning?: any;
  checkAnswer?: () => void;
  // Customizable Area End
}

interface S {

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ButtonController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End


}
