import React from "react";

import {
    Box,
    Button,
    Grid,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { OrganSystemsNext12 } from "../src/assets";
import Footer from "./Footer.web";
import "./Header.css";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GroupIcon from '@material-ui/icons/Group';
import UpdateIcon from '@material-ui/icons/Update';
// Customizable Area End

import Catalogue1Controller, { Props } from "../../catalogue/src/CoursePageController.web";
import Header from "./Header.web";

export default class OrganSystemsNext extends Catalogue1Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    currentProgress = this.state.stations.findIndex(
        (station) => !station.completed
    );

    transitionStyle = {
        transition: "all 2s ease-in-out",
    };

    renderActiveStation = (station: any, index: number) => {
        const isLast = index === this.state.stations.length - 1;

        return (
            <g data-test-id = "renderStationLabels"
                key={`active-${index}`}
                transform={`translate(${station.x - 53} ${station.y - 49})`}
            >
                <svg
                    width="106"
                    height="106"
                    viewBox="0 0 106 106"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_d_17843_19286)">
                        <circle
                            cx="53"
                            cy="49"
                            r="45"
                            fill={station.completed ? "#72EFDD" : "#334155"}
                            style={this.transitionStyle}
                        />
                        <circle
                            cx="53"
                            cy="49"
                            r="42.5"
                            stroke={station.completed ? "#8AB9B8" : "#475569"}
                            strokeWidth="5"
                            style={{
                                transition: "stroke 2s ease-in-out",
                            }}
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_17843_19286"
                            x="0"
                            y="0"
                            width="106"
                            height="106"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="4" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_17843_19286"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_17843_19286"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
                {this.renderStationLabels(station, index)}
                <circle
                    ref={this.state.stationRefs[index]}
                    data-station-id="1"
                    cx="53"
                    cy="49"
                    r="15"
                    fill={station.completed ? "#D9FFFE" : "#64748B"}
                    filter={station.completed ? "drop-shadow(0px 2px 4px #e1060640)" : ""}
                    fillOpacity="1"
                    style={{ ...this.transitionStyle, cursor: "pointer" }}
                    data-test-id = "handleStationClick"
                    onClick={(e) => this.handleStationClick(index, e)}
                />

                {/* Left side paths */}
                {(index === 1 || index === 3) && (
                    <g transform="translate(-18 22)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M-0.000694275 17.0177C0.268269 17.0224 0.537807 17.0248 0.807899 17.0248C14.8424 17.0248 27.3763 10.6 35.6289 0.53125C30.2068 7.96355 27.0071 17.1205 27.0071 27.0248C27.0071 36.9291 30.2068 46.086 35.6289 53.5183C27.3763 43.4495 14.8424 37.0248 0.807899 37.0248C0.537807 37.0248 0.268269 37.0272 -0.000694275 37.0319V17.0177Z"
                            fill={station.completed ? "#8AB9B8" : "url(#paint0_linear_left)"}
                            opacity={1}
                            style={this.transitionStyle}
                        />
                    </g>
                )}

                {/* Right side paths */}
                {(index === 0 || index === 2 || index === 3) && (
                    <g transform="translate(88 22)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M35.9984 16.9944C35.7323 16.999 35.4656 17.0014 35.1984 17.0014C21.1639 17.0014 8.63004 10.5766 0.377411 0.507812C5.79949 7.94012 8.9992 17.0971 8.9992 27.0014C8.9992 36.9095 5.79702 46.0697 0.371094 53.5036C8.62363 43.4298 21.1607 37.0014 35.1992 37.0014C35.4662 37.0014 35.7326 37.0037 35.9984 37.0083V16.9944Z"
                            fill={
                                station.completed
                                    ? "#8AB9B8"
                                    : "url(#paint0_linear_17843_19187)"
                            }
                            style={this.transitionStyle}
                        />
                    </g>
                )}

                {/* Special right side for 2nd and last circles */}
                {(index === 1 || isLast) && (
                    <g transform="translate(90 31)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M26.8583 26.8851C26.4433 26.983 26.0284 27.087 25.6137 27.1972C15.3269 29.9309 6.83241 36.0315 0.999999 44L0.80036 43.2488C6.99948 33.1216 9.20437 20.5735 5.91404 8.19196C5.17964 5.42842 4.20349 2.79359 3.01435 0.304139L3.50484 0.173792C6.3103 5.77527 12.403 9.07525 18.6894 8.27919C20.1591 8.34008 21.6627 8.18367 23.1591 7.78602C24.5937 7.40477 25.9241 6.82768 27.1292 6.09113L32.1201 24.8719C31.7137 24.9446 31.307 25.0347 30.9008 25.1426C29.4368 25.5317 28.0817 26.1255 26.8583 26.8851Z"
                            fill={station.completed ? "#8AB9B8" : "url(#paint0_linear_right)"}
                            opacity={1}
                            style={this.transitionStyle}
                        />
                    </g>
                )}

                {/* Special left side for 3rd circle */}
                {index === 2 && (
                    <g transform="translate(-17 25)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.36263 17.6C6.94763 17.7103 6.53245 17.8143 6.11717 17.9123C4.89385 18.6717 3.539 19.2654 2.07516 19.6544C1.6692 19.7623 1.2627 19.8524 0.85649 19.9251L5.86224 38.7618C6.66922 38.3808 7.52191 38.0672 8.41518 37.8298C9.2037 37.6203 9.99425 37.478 10.7808 37.3994C11.276 37.2094 11.7863 37.0438 12.3106 36.9045C19.2618 35.0572 26.3714 38.4365 29.4702 44.6239L29.9605 44.4936C28.7713 42.0042 27.7952 39.3694 27.0608 36.6059C23.7703 24.2234 25.9757 11.6744 32.1759 1.54688L31.9765 0.796894C26.1441 8.76552 17.6496 14.8663 7.36263 17.6Z"
                            fill={station.completed ? "#8AB9B8" : "url(#paint0_linear_left)"}
                            opacity={1}
                            style={this.transitionStyle}
                        />
                    </g>
                )}
            </g>
        );
    };

     getLabelColor = (score: number) => {
        if (score > 90) {
            return "#72EFDD66";
        } else if (score <= 50) {
            return "#f871718c";
        } else {
            return "#F59E0B66";
        }
    }

    renderStationLabels = (station: any, index: number) => {
        const questionBankScore = this.state.questionBankScore.find(
            (qs) => qs.unit_name === station.label
        );
        
        const score = questionBankScore
            ? parseInt(questionBankScore?.score)
            : 0;
            
        const unitPercentage = parseInt(
            this.state.unitPercentages.find((up) => up.unit_name === station.label)
                ?.completion_percentage || "0%"
        );

        return (
            <g>
                {/* Score Label */}
                {score > 0 && (
                    <g data-test-id = "getLabelColor"  transform="translate(10, 115)">
                        <foreignObject x="10" y="12" width="60" height="20">
                            <div
                                className="preview-font"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "11px",
                                    backgroundColor: this.getLabelColor(score),
                                    color: "#FFFFFF",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                    textAlign: "center",
                                }}
                            >
                                {Number(
                                    questionBankScore?.score
                                ) || 0}{" "}
                                / 100
                            </div>
                        </foreignObject>
                    </g>
                )}
                {score === 0 && unitPercentage != 0 && this.renderProgressBars(station)}
                {/* Station Label - positioned below score */}
                <text
                    x="53"
                    y="117"
                    textAnchor="middle"
                    fill="white"
                    style={{
                        fontSize: "16px",
                        fontWeight: 500,
                    }}
                >
                    {station.label}
                </text>
            </g>
        );
    };

    renderProgressBars = (station: any) => {
        const percentage =
            this.state.unitPercentages.find((up) => up.unit_name === station.label)
                ?.completion_percentage || "0.0%";

        return (
            <foreignObject x="11" y="130" width="85" height="20">
                <div
                    data-testid={`progress-container-${station.label}`}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        width: "100%",
                        height: "8px",
                    }}
                >
                    <div
                        data-testid={`progress-bar-${station.label}`}
                        style={{
                            width: "60px",
                            height: "100%",
                            background: "#334155",
                            borderRadius: "4px",
                            overflow: "hidden",
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                width: percentage,
                                height: "100%",
                                background: "#72EFDD",
                                borderRadius: "4px",
                                transition: "all 0.5s ease-in-out",
                                opacity: 0.5,
                            }}
                        />
                    </div>
                    <Typography
                        data-testid={`progress-text-${station.label}`}
                        className="preview-font"
                        style={{
                            fontSize: "10px",
                            color: "#FFFFFF",
                            marginLeft: "4px",
                        }}
                    >
                        {percentage}
                    </Typography>
                </div>
            </foreignObject>
        );
    };

    taskSelectionNext = () => {
        let value = "nkjjjj"
        console.log(value);

        return (
            <Box className="taskContainer">
                <Grid container>
                    <Grid item lg={3}>
                        <Box style={{ width: '96%', margin: '0 auto' }}>
                            <h2
                                className="welcomeTxt"
                                style={{ width: "87%", display: "flex", flexDirection: 'column' }}
                            >
                                <span style={{ fontSize: '20px' }}>MedEd Journeys</span>
                                <Box style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '15px'
                                }}>
                                    <Box style={{
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: '#121826',
                                        borderRadius: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '15px'
                                    }}>
                                        <ArrowLeftIcon style={{ margin: "0 10px" }} />
                                    </Box>
                                    <span style={{ fontWeight: 'bold', fontSize: '25px' }}>Organ Systems</span>
                                </Box>
                            </h2>
                        </Box>
                    </Grid>
                    <Grid item lg={9}>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    NewFlow = () => {
        return (
            <Box style={{ padding: '50px' }}>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box
                            style={{
                                width: 400,
                                borderRadius: '20px',
                                overflow: 'hidden',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                backgroundColor: '#172130',
                                margin: 'auto',
                                height: '580px'
                            }}
                        >
                            <Box
                                style={{
                                    height: 350,
                                    backgroundImage: `url(${OrganSystemsNext12})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    filter: 'brightness(0.8)',
                                }}
                            ></Box>

                            <Box style={{ padding: '16px', backgroundColor: '#172130', color: 'white' }}>
                                <Grid container style={{ padding: '0px' }} spacing={1} alignItems="center">
                                    <Grid item xs={12} container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <AccessTimeIcon style={{ color: '#9ca3af' }} />
                                        </Grid>
                                        <Box style={{
                                            width: '1px',
                                            backgroundColor: '#fff',
                                            height: '40px',
                                            margin: '0px 15px'
                                        }} />
                                        <Grid item>
                                            <Typography variant="caption" style={{ color: '#9ca3af' }}>EXPECTED DURATION</Typography>
                                            <Typography variant="body1">6h 30min</Typography>
                                        </Grid>
                                    </Grid>

                                    {/* Learners */}
                                    <Grid item xs={12} style={{ padding: '15px 0px' }} container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <GroupIcon style={{ color: '#9ca3af' }} />
                                        </Grid>
                                        <Box style={{
                                            width: '1px',
                                            backgroundColor: '#fff',
                                            height: '40px',
                                            margin: '0px 15px'
                                        }} />
                                        <Grid item>
                                            <Typography variant="caption" style={{ color: '#9ca3af' }}>LEARNERS ENROLLED</Typography>
                                            <Typography variant="body1">10</Typography>
                                        </Grid>
                                    </Grid>

                                    {/* Last Update */}
                                    <Grid style={{ padding: '0px' }} item xs={12} container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <UpdateIcon style={{ color: '#9ca3af' }} />
                                        </Grid>
                                        <Box style={{
                                            width: '1px',
                                            backgroundColor: '#fff',
                                            height: '40px',
                                            margin: '0px 15px'
                                        }} />
                                        <Grid item>
                                            <Typography variant="caption" style={{ color: '#9ca3af' }}>LAST UPDATE</Typography>
                                            <Typography variant="body1">April 2024</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{ margin: 'auto' }}>
                        <Box
                            style={{
                                flex: "1 1 auto",
                                backgroundColor: "transparent",
                                borderRadius: "16px",
                                padding: "0 32px",
                                minWidth: "500px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    // width: "100%",
                                    maxWidth: "50rem",
                                    margin: "0 auto",
                                    padding: "0 auto",
                                }}
                            >
                                <svg width="700" height="800" viewBox="-50 -50 700 800">
                                    <defs>
                                        <filter
                                            id="drop_shadow"
                                            x="-50%"
                                            y="-50%"
                                            width="200%"
                                            height="200%"
                                        >
                                            <feDropShadow
                                                dx="0"
                                                dy="4"
                                                stdDeviation="2"
                                                floodOpacity="0.25"
                                                floodColor="#000000"
                                            />
                                        </filter>

                                        <linearGradient
                                            id="paint0_linear_right"
                                            x1="27.9747"
                                            y1="15.797"
                                            x2="8.97465"
                                            y2="21.297"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#334155" />
                                            <stop offset="1" stopColor="#475569" />
                                        </linearGradient>

                                        <linearGradient
                                            id="paint0_linear_left"
                                            x1="5.00195"
                                            y1="29"
                                            x2="24.002"
                                            y2="23.5"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#334155" />
                                            <stop offset="1" stopColor="#475569" />
                                        </linearGradient>

                                        <linearGradient
                                            id="paint0_linear_17843_19187"
                                            x1="36"
                                            y1="27.002"
                                            x2="5.99999"
                                            y2="27.002"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#334155" />
                                            <stop offset="1" stopColor="#475569" />
                                        </linearGradient>
                                        <filter
                                            id="circle-blur"
                                            x="-100%"
                                            y="-100%"
                                            width="300%"
                                            height="300%"
                                        >
                                            <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                                        </filter>
                                    </defs>
                                    {this.currentProgress !== -1 && (
                                        <g
                                            transform={`translate(${this.state.stations[
                                                this.currentProgress
                                            ].x - 77.5} ${this.state.stations[this.currentProgress].y -
                                            77.5})`}
                                        >
                                            <svg
                                                width="155"
                                                height="155"
                                                viewBox="0 0 155 155"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g opacity="0.8" filter="url(#filter0_f_17843_19144)">
                                                    <circle
                                                        cx="77.5"
                                                        cy="77.5"
                                                        r="54.9"
                                                        stroke="#72EFDD"
                                                        stroke-width="0.2"
                                                    />
                                                </g>
                                                <g opacity="0.4" filter="url(#filter0_f_17843_19145)">
                                                    <circle
                                                        cx="77.5"
                                                        cy="77.5"
                                                        r="64.9"
                                                        stroke="#72EFDD"
                                                        stroke-width="0.2"
                                                    />
                                                </g>
                                                <g opacity="0.2" filter="url(#filter0_f_17843_19146)">
                                                    <circle
                                                        cx="77.5"
                                                        cy="77.5"
                                                        r="75.4"
                                                        stroke="#72EFDD"
                                                        stroke-width="0.2"
                                                    />
                                                </g>
                                            </svg>
                                            <defs>
                                                <filter
                                                    id="filter0_f_17843_19145"
                                                    x="0"
                                                    y="0"
                                                    width="155"
                                                    height="155"
                                                    filterUnits="userSpaceOnUse"
                                                    color-interpolation-filters="sRGB"
                                                >
                                                    <feFlood
                                                        flood-opacity="0"
                                                        result="BackgroundImageFix"
                                                    />
                                                    <feBlend
                                                        mode="normal"
                                                        in="SourceGraphic"
                                                        in2="BackgroundImageFix"
                                                        result="shape"
                                                    />
                                                    <feGaussianBlur
                                                        stdDeviation="1"
                                                        result="effect1_foregroundBlur_17843_19145"
                                                    />
                                                </filter>
                                                <filter
                                                    id="filter0_f_17843_19146"
                                                    x="0"
                                                    y="0"
                                                    width="155"
                                                    height="155"
                                                    filterUnits="userSpaceOnUse"
                                                    color-interpolation-filters="sRGB"
                                                >
                                                    <feFlood
                                                        flood-opacity="0"
                                                        result="BackgroundImageFix"
                                                    />
                                                    <feBlend
                                                        mode="normal"
                                                        in="SourceGraphic"
                                                        in2="BackgroundImageFix"
                                                        result="shape"
                                                    />
                                                    <feGaussianBlur
                                                        stdDeviation="1"
                                                        result="effect1_foregroundBlur_17843_19146"
                                                    />
                                                </filter>
                                                <filter
                                                    id="filter0_f_17843_19144"
                                                    x="0"
                                                    y="0"
                                                    width="155"
                                                    height="155"
                                                    filterUnits="userSpaceOnUse"
                                                    color-interpolation-filters="sRGB"
                                                >
                                                    <feFlood
                                                        flood-opacity="0"
                                                        result="BackgroundImageFix"
                                                    />
                                                    <feBlend
                                                        mode="normal"
                                                        in="SourceGraphic"
                                                        in2="BackgroundImageFix"
                                                        result="shape"
                                                    />
                                                    <feGaussianBlur
                                                        stdDeviation="1"
                                                        result="effect1_foregroundBlur_17843_19144"
                                                    />
                                                </filter>
                                            </defs>
                                        </g>
                                    )}
                                    <path
                                        className="progress-path"
                                        opacity="0.2"
                                        stroke="#a2b7d6"
                                        strokeWidth="19.5"
                                        strokeLinecap="round"
                                        fill="none"
                                        d={this.createMainPath()}
                                    />
                                    <g transform="translate(365 -18)">
                                        <svg
                                            width="195"
                                            height="125"
                                            viewBox="0 0 195 125"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.3"
                                                d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM189.333 122C189.333 123.473 190.527 124.667 192 124.667C193.473 124.667 194.667 123.473 194.667 122C194.667 120.527 193.473 119.333 192 119.333C190.527 119.333 189.333 120.527 189.333 122ZM3 3.5L92 3.5V2.5L3 2.5V3.5ZM191.5 103V122H192.5V103H191.5ZM92 3.5C146.952 3.5 191.5 48.0477 191.5 103H192.5C192.5 47.4954 147.505 2.5 92 2.5V3.5Z"
                                                fill="url(#paint0_linear_17848_19930)"
                                            />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear_17848_19930"
                                                    x1="-1.5"
                                                    y1="2.99999"
                                                    x2="194.5"
                                                    y2="118"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stop-color="#BAC1CB" />
                                                    <stop offset="0.513804" stop-color="#424952" />
                                                    <stop offset="1" stop-color="#CDD5E1" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </g>
                                    <g transform="translate(-10 153)">
                                        <svg
                                            width="197"
                                            height="219"
                                            viewBox="0 0 197 219"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.3"
                                                d="M89.1667 3C89.1667 4.47276 87.9728 5.66667 86.5 5.66667C85.0272 5.66667 83.8333 4.47276 83.8333 3C83.8333 1.52724 85.0272 0.333333 86.5 0.333333C87.9728 0.333333 89.1667 1.52724 89.1667 3ZM196.667 216C196.667 217.473 195.473 218.667 194 218.667C192.527 218.667 191.333 217.473 191.333 216C191.333 214.527 192.527 213.333 194 213.333C195.473 213.333 196.667 214.527 196.667 216ZM86.6193 3.48557L77.2668 5.78267L77.0283 4.81153L86.3807 2.51443L86.6193 3.48557ZM1.5 102.411V116H0.5V102.411H1.5ZM101 215.5H194V216.5H101V215.5ZM1.5 116C1.5 170.952 46.0477 215.5 101 215.5V216.5C45.4954 216.5 0.5 171.505 0.5 116H1.5ZM77.2668 5.78267C32.7784 16.7096 1.5 56.6001 1.5 102.411H0.5C0.5 56.1397 32.0927 15.8483 77.0283 4.81153L77.2668 5.78267Z"
                                                fill="url(#paint0_linear_17848_19931)"
                                            />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear_17848_19931"
                                                    x1="194"
                                                    y1="216"
                                                    x2="83"
                                                    y2="12"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stop-color="#545B64" />
                                                    <stop offset="1" stop-color="#B0C0D5" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </g>
                                    <g transform="translate(485 415)">
                                        <svg
                                            width="78"
                                            height="122"
                                            viewBox="0 0 78 122"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.3"
                                                d="M71.8333 3.5C71.8333 2.02724 73.0272 0.833336 74.5 0.833336C75.9728 0.833336 77.1667 2.02724 77.1667 3.5C77.1667 4.97276 75.9728 6.16666 74.5 6.16666C73.0272 6.16666 71.8333 4.97276 71.8333 3.5ZM0.833333 118.5C0.833333 117.027 2.02724 115.833 3.5 115.833C4.97276 115.833 6.16667 117.027 6.16667 118.5C6.16667 119.973 4.97276 121.167 3.5 121.167C2.02724 121.167 0.833333 119.973 0.833333 118.5ZM73.5411 19.9221L74.0002 3.48604L74.9998 3.51396L74.5407 19.95L73.5411 19.9221ZM74.5407 19.95C73.3015 64.3127 45.266 103.486 3.67444 118.969L3.32556 118.031C44.5352 102.691 72.3133 63.8774 73.5411 19.9221L74.5407 19.95Z"
                                                fill="url(#paint0_linear_17856_20426)"
                                            />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear_17856_20426"
                                                    x1="-122.5"
                                                    y1="114"
                                                    x2="73.5"
                                                    y2="-1.00005"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stop-color="#BAC1CB" />
                                                    <stop offset="0.513804" stop-color="#424952" />
                                                    <stop offset="1" stop-color="#CDD5E1" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </g>
                                    {/* for color background linearGradient */}
                                    <path
                                        ref={this.state.pathRef}
                                        className="progress-path"
                                        stroke="#8AB9B8"
                                        strokeWidth="19.5"
                                        strokeLinecap="round"
                                        fill="none"
                                        d={this.createMainPath()}
                                        style={{
                                            strokeDasharray: "5000",
                                            strokeDashoffset: "5000",
                                            transition: "stroke-dashoffset 2s ease-in-out",
                                        }}
                                    />
                                    <g transform="translate(0 500)">
                                        <path
                                            d="M10.0004 179.5V110.001C10.0004 54.7721 54.772 10.0006 110 10.0006H361"
                                            stroke="#212E42"
                                            strokeWidth="20"
                                            fill="none"
                                            strokeLinecap="round"
                                        />
                                    </g>
                                    {this.state.stations.map((station, index) =>
                                        this.renderActiveStation(station, index)
                                    )}
                                </svg>
                            </div>
                        </Box>
                        <Button data-test-id = "OrganSystemUnit" onClick={() => this.props.navigation.navigate("OrganSystemUnit")} style={{
                            background: "linear-gradient(0deg, rgba(51, 65, 85, 0.8), rgba(51, 65, 85, 0.8))",
                            width: '250px',
                            height: '50px',
                            borderRadius: '25px',
                            color: '#BAABFF'
                        }}>Jump to Next Course</Button>
                    </Grid>
                </Grid>

            </Box>
        )
    }




    // Customizable Area End


    render() {
        return (
            // Customizable Area Start
            <Box style={webStyle.backgroundCLr2}>
                <Header navigation={this.props.navigation} id={"3"} />

                {this.taskSelectionNext()}
                {this.NewFlow()}

                <Footer navigation={this.props.navigation} id={""} />
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    backgroundCLr2: {
        backgroundColor: "rgb(28, 34, 53)",
    },
};
// Customizable Area End
