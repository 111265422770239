import React from "react";
// Customizable Area Start
import { Box, CircularProgress, Typography,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  CssBaseline, } from "@material-ui/core";
import Header from "../../catalogue1/src/Header.web";
import Footer from "../../catalogue1/src/Footer.web";
import "../../catalogue1/src/Header.css";
import "../../scheduling/src/global.css";
import UnitController, { Props } from "./UnitController.web";
import TopicProgress from "./TopicProgress.web";
import { Done, RadioButtonUnchecked,  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon} from "@material-ui/icons";
import CustomAudioPlayer from "../../../components/src/CustomAudioPlayer";
import { UnitSkeleton } from "../../../components/src/Skeletons";

  const openDrawerWidth = 300; // Width when drawer is open
const closedDrawerWidth = 60; // Width when drawer is closed
  const styles = {
    root: {
      display: "flex",
      
    },
    appBar: {
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    appBarShift: {
      marginLeft: openDrawerWidth,
    width: `calc(100% - ${openDrawerWidth}px)`,
      transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1), margin 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    menuButton: {
      marginRight: "20px",
    },
    hide: {
      display: "none",
    },
    show: {
      display: "block",
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: openDrawerWidth,
      transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    drawerClose: {
      overflowX: "hidden",
      width: closedDrawerWidth,
      transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    
    },
    drawerPaper: {
    
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0px ",
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: "30px",
      marginLeft: 0, // Default for closed drawer
      transition: "margin-left 225ms cubic-bezier(0, 0, 0.2, 1)",
      width: `calc(100% - ${closedDrawerWidth}px)`,
      
    },
    contentShift: {
      marginLeft: 0, // Adjust when drawer is open
      width: `calc(100% - ${openDrawerWidth}px)`,
    },
  };

// Customizable Area End
export default class Unit extends UnitController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  renderProgressBar() {
    const {
      currentTopic,
      currentSectionIndex,
      scrollPercentages,
    } = this.state;
    
    return (
      <TopicProgress data-test-id = "handleNavigation"
      sections={currentTopic?.sections?.data || []}
      currentIndex={currentSectionIndex}
      onNavigate={this.handleNavigation}
      scrollProgress={scrollPercentages || 6}
      />
    );
  }

  renderLessions = () =>{
      const {isPlayingLess, currentSectionIndex} = this.state
      const currentlessons = this.state.currentTopic?.sections?.data[currentSectionIndex]
    ?.attributes?.lessons?.data
   return currentlessons && currentlessons.map((currentLesson: any) => {
        let contentLess = "";
        const hasImageLess = currentLesson?.attributes?.image?.url;
        const descriptionLess = currentLesson?.attributes?.description || "";
        if (hasImageLess) {
          contentLess = `<img style={webStyle.imgTag} src=${currentLesson?.attributes?.image?.url} />`;
          contentLess += descriptionLess;
        } else {
          contentLess = descriptionLess;
        }
        return(
          <>
          <Box style={webStyle.titleContainerLesson}>
          <Typography style={webStyle.titleLession}>
          <span style={{ marginRight: '8px' }}>•</span>
            {
              currentLesson?.attributes?.name	
            }
          </Typography>
        </Box>
        <div style={webStyle.wholeContent}>
        <div
          className="preview-font"
          style={webStyle.description}
          dangerouslySetInnerHTML={{
            __html: contentLess || "",
          }}
        />
        {currentLesson?.attributes.video?.url && (
          <div style={webStyle.videoContainer}>
            <div style={webStyle.videoWrapper}>
              <video
                ref={(ref) => this.videoRefLess = ref}
                controls={this.state.isPlayingLess}
                style={webStyle.video}
                src={currentLesson.attributes.video.url}
                onPlay={() => this.handleVideoToggle(false)}
                onPause={() => this.handleVideoToggle(true)}
              >
                Your browser does not support the video tag.
              </video>
              {!isPlayingLess && (
                <div
                  style={webStyle.videoOverlay}
                  onClick={this.handlePlayClick}
                >
                  <button style={webStyle.playButton}>
                    <svg
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                    >
                      <circle
                        cx="32"
                        cy="32"
                        r="32"
                        fill="rgba(0, 0, 0, 0.5)"
                      />
                      <path
                        d="M42 32L26 41.5885L26 22.4115L42 32Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {currentLesson?.attributes?.audio?.url && (
          <div style={webStyle.videoContainer}>
            <CustomAudioPlayer
              audioUrl={currentLesson.attributes.audio.url}
            />
          </div>
        )}
        {currentLesson?.attributes?.table?.url && (
          <div style={webStyle.videoContainer}>
            <img
              style={webStyle.video}
              src={currentLesson.attributes.table.url}
              alt="Table content"
            />
          </div>
        )}
      </div>
      </>
        )
      })
  }

  LoadingSkeleton = () => {
    return (
      <UnitSkeleton />
    );
  };

  renderContent() {
    const {
      currentTopic,
      loading,
      error,
      currentSectionIndex,
      qb,
      isPlaying,
    } = this.state;
    const currentSection =
      currentTopic?.sections?.data[currentSectionIndex || 0];
    const isLastSection =
      currentSectionIndex === currentTopic?.sections?.data.length - 1;

    if (loading) {
      return this.LoadingSkeleton();
    }

    if (error) {
      return (
        <Typography variant="h6" style={{ color: "red" }}>
          {error}
        </Typography>
      );
    }

    let content = "";
    const hasImage = currentSection?.attributes?.image?.url;
    const description = currentSection?.attributes?.description || "";
    
    if (hasImage) {
      content = `<img style={webStyle.imgTag} src=${currentSection?.attributes?.image?.url} />`;
      content += description;
    } else {
      content = description;
    }
    
    
    return (
      <Box style={webStyle.mainContent}>
        <>
          <style>
            {`
                .preview-font {
                    font-family: 'Poppins', sans-serif !important;
                }
                .mainDescription p, .mainDescription span, .mainDescription {
                  color: white !important;
                  font-size: 20px;
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                  font-weight: 300;
                }
                .preview-font span {
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                  color: #334155 !important;
                }
                .scroll-container::-webkit-scrollbar {
                  display: none;
                }
                .preview-font p {
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                  clear: left;
                  color: #334155;
                }
                .preview-font img {
                  float: right;
                  margin: 0 0 15px 15px;
                  border-radius: 10px;
                  height: 200px;
                  width: 250px;
                  object-fit: fill;
                }
                .MuiPaper-root.MuiPaper-rounded{
                  min-width: auto !important;
                  border-radius: 50px;
                  scrollbar-width: none;
                }
            `}
          </style>
        </>
        <Box style={webStyle.content}>
          <Box style={webStyle.courseName}>
            <Typography
              variant="h2"
              style={{ fontSize: "24px", fontWeight: "700" }}
              data-testid="unit-title"
            >
              {this.state.data?.unit_name}
            </Typography>
            <div
              className="mainDescription"
              dangerouslySetInnerHTML={{
                __html: this.state.currentTopic?.name,
              }}
            ></div>
          </Box>
          {this.renderProgressBar()}
          <Box style={webStyle.container} className="scroll-container">
            <Box style={webStyle.titleContainer}>
              <Typography variant="subtitle1" style={webStyle.title}>
                {
                  this.state.currentTopic?.sections?.data[currentSectionIndex]
                    ?.attributes?.name
                }
              </Typography>
            </Box>
            <div style={webStyle.wholeContent}>
              <div
                className="preview-font"
                style={webStyle.description}
                dangerouslySetInnerHTML={{
                  __html: content || "",
                }}
              />
              {currentSection?.attributes.video?.url && (
                <div style={webStyle.videoContainer}>
                  <div style={webStyle.videoWrapper}>
                    <video
                      ref={(ref) => this.videoRef = ref}
                      controls={this.state.isPlaying}
                      style={webStyle.video}
                      src={currentSection.attributes.video.url}
                      onPlay={() => this.handleVideoToggle(false)}
                      onPause={() => this.handleVideoToggle(true)}
                    >
                      Your browser does not support the video tag.
                    </video>
                    {!isPlaying && (
                      <div
                        style={webStyle.videoOverlay}
                        onClick={this.handlePlayClick}
                      >
                        <button style={webStyle.playButton}>
                          <svg
                            width="64"
                            height="64"
                            viewBox="0 0 64 64"
                            fill="none"
                          >
                            <circle
                              cx="32"
                              cy="32"
                              r="32"
                              fill="rgba(0, 0, 0, 0.5)"
                            />
                            <path
                              d="M42 32L26 41.5885L26 22.4115L42 32Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {currentSection?.attributes?.audio?.url && (
                <div style={webStyle.videoContainer}>
                  <CustomAudioPlayer
                    audioUrl={currentSection.attributes.audio.url}
                  />
                </div>
              )}
              {currentSection?.attributes?.table?.url && (
                <div style={webStyle.videoContainer}>
                  <img
                    style={webStyle.video}
                    src={currentSection.attributes.table.url}
                    alt="Table content"
                  />
                </div>
              )}
            </div>
           {/* Start Lessons */}
           {this.renderLessions()}
            
          </Box>
          <Box style={webStyle.btnBox}>
            {qb ? (
              <div>
                <button style={{ ...webStyle.btn2, width: "280px" }}>
                  Generate Question Bank
                </button>
                <div
                  style={{
                    color: "#72efddcf",
                    marginTop: "20px",
                    fontSize: "14px",
                    letterSpacing: "1.1px",
                    fontWeight: "500",
                    width: "280px",
                    textDecorationSkipInk: "auto",
                    textDecoration: "underline",
                    textAlign: "center",
                    textDecorationColor: "#72efddcf",
                    cursor: "pointer",
                  }}
                >
                  Take me to the next topic
                </div>
              </div>
            ) : (
              <>
                <button
                  style={{
                    ...webStyle.btn1,
                    background: !currentSection?.attributes.completed
                      ? "transparent"
                      : "#72EFDD33",
                  }}
                  onClick={this.handleSectionCompletion}
                >
                  {!currentSection?.attributes.completed ? (
                    <>
                      <RadioButtonUnchecked style={{ color: "white" }} />
                      <span style={{ color: "white" }}>Mark as completed</span>
                    </>
                  ) : (
                    <>
                      <Done style={{ color: "#72EFDD" }} />
                      <span style={{ color: "white" }}>Completed</span>
                    </>
                  )}
                </button>
                <button
                  style={webStyle.btn2}
                  onClick={() =>{
                    this.handleNavigationNext("next", this.state.currentTopic)
                  }}
                >
                  {isLastSection ? "Go to Question Bank" : "Go to next section"}
                </button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { open } = this.state;
    return (
      //Merge Engine DefaultContainer
      <>
        <Box style={webStyle.backgroundCLr}>
          <Header navigation={this.props.navigation} id={"3"} />


          <div style={styles.root} >
            {open && <div className="sidenavHeader" style={styles.drawerHeader} >
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>}
            <Toolbar className="closedNav" style={open ? styles.hide : styles.show}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                edge="start"
                style={styles.menuButton}
              >
                <ChevronRightIcon />
              </IconButton>

            </Toolbar>
            <CssBaseline />

            <Drawer
              style={styles.drawer}
              variant="permanent"
              anchor="left"
              open={open}
              PaperProps={{ style: open ? styles.drawerOpen : styles.drawerClose }}
            >

              <AppBar
                className="navHeader"
                style={open ? { ...styles.appBar, ...styles.appBarShift } : styles.appBar}

              >

              </AppBar>

              <Divider />
              {/* <List>
    {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
      <ListItem button key={text}>
        <ListItemIcon>
         "1"
        </ListItemIcon>
        {open && <ListItemText primary={text} />}
      </ListItem>
    ))}
  </List> */}
              <ul>
                <div className="navHeading">
                  {open && <button className="goBackbtn" data-testid="goBack" onClick={this.goBackfromUnit}>
                    <ChevronLeftIcon />
                  </button>}
                  <div className="navSubHead">
                    <li className="coursename">
                      <h5>{open && this.state.data && this.state.data.course_name}</h5>
                    </li>
                    <li className="unitname">
                      <h4>{open && this.state.data && this.state.data.unit_name}</h4>
                    </li>
                  </div>
                </div>
                <Divider className="gradientHR" />
                {/* Render topics and sections */}
                {this.state.data && this.state.data.topics.map((topic) => (
                  <li key={topic.id}>
                    <ListItemIcon style={{ color: "#fff" }}>

                    </ListItemIcon> <h5 className="sectionName">{open && topic.name}</h5>
                    <ul>
                      {topic.sections.data.map((section, index) => (
                        <div className="sectionArea">
                        <List key={section.id}> <ListItem >
                          <ListItemIcon
                            className="iconIndex">
                            {index + 1}
                          </ListItemIcon>
                          <p className="lessonName">{open && <ListItemText primary={section.attributes.name} />}</p>
                        </ListItem>
                    
                        </List>
                      
                        {section.attributes.lessons.data.map((lesson: any) => (
                        
                        <List key={lesson.id} className="listLesson"> <ListItem >
                         
                          <p className="smallLesson">{open && <ListItemText primary={lesson.attributes.name} />}</p>
                        </ListItem>
                       
                        </List>
                      
                      ))}
                      
                      </div>
                      ))}
                      <List>
                        <ListItem>
                          <ListItemIcon className="iconIndex">
                            {topic.sections.data.length + 1}
                          </ListItemIcon>
                          <p className="lessonName">
                            {open && <ListItemText primary="QuestionBank" />}
                          </p>
                        </ListItem>
                      </List>

                    </ul>
                  </li>
                ))}
              </ul>
              <Divider />

            </Drawer>

            <main
              style={open ? { ...styles.content, ...styles.contentShift } : styles.content}
            >
              <div style={styles.drawerHeader} />
              {!open && <button className="goBackbtn2" onClick={this.goBackfromUnit}>
                <ChevronLeftIcon />
              </button>}
              {this.renderContent()}
            </main>



          </div>
          <Footer navigation={this.props.navigation} id={""} />
        </Box>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
    minHeight: "100vh",
  },
  root: {
    backgroundColor: "rgb(28, 34, 53)",
    minHeight: "100vh",
    color: "white",
    overflowX: "hidden" as const,
    display: "flex",
    flexWrap: "wrap" as const,
    textAlign: "end" as const,
  },
  leftSidebar: {
    backgroundColor: "#050D15",
    width: "25%",
    height: "auto",
  },
  mainContent: {
    width: "100%",
  },
  content: {
    position: "relative" as const,
  },
  container: {
    padding: "40px",
    width: "84%",
    height: "auto",
    background: "linear-gradient(315deg, #94A3B8, #BECBDF, #94A3B8)",
    borderRadius: "20px",
    margin: "0 auto",
    overflowY: "auto" as const,
    scrollbarWidth: "none" as const,
    maxHeight: "100%",
  },
  titleContainer: {
    width: "fit-content",
    height: "43px",
    backgroundColor: "#64748B",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center" as const,
    alignItems: "center",
  },
  titleContainerLesson: {
    display: "flex",
    justifyContent: "start" as const,
    alignItems: "center",
    marginTop: "20px",
  },
  title: {
    fontSize: "22px",
    padding: "16px",
  },
  titleLession: {
    fontSize: "19px",
    fontWeight: "bold",
    color: "#000"
  },
  description: {
    fontSize: "18px",
    textAlign: "justify" as const,
    letterSpacing: "0.7px",
    paddingTop: "10px",
  },
  courseName: {
    display: "flex",
    alignItems: "center" as const,
    padding: "30px 0",
    flexDirection: "column" as const,
    gap: "10px",
    position: "relative" as const,
    margin: "0 154px",
  },
  btnBox: {
    width: "84%",
    padding: "40px",
    height: "auto",
    background: "#0F172A",
    borderRadius: "20px",
    margin: "30px auto",
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "center",
    gap: "10px",
  },
  btn1: {
    width: "230px",
    height: "44px",
    border: "2px solid #72EFDD",
    borderRadius: "20px",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    letterSpacing: "0.8px",
    fontWeight: 800,
    cursor: "pointer",
  },
  btn2: {
    width: "230px",
    height: "44px",
    background: "#72EFDD",
    border: "none",
    boxShadow: "rgba(5, 13, 21, 0.2) 0px 0px 0px 2px inset",
    borderRadius: "20px",
    fontSize: "16px",
    letterSpacing: "0.8px",
    fontWeight: 800,
    cursor: "pointer",
  },
  videoContainer: {
    width: "100%",
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  video: {
    width: "100%",
    maxWidth: "770px",
    borderRadius: "10px",
    backgroundColor: "transparent",
  },
  tabsContainer: {
    display: "flex",
    gap: "10px",
    overflowX: "auto" as const,
    padding: "10px 0",
    marginBottom: "20px",
  },
  tab: {
    padding: "8px 16px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    transition: "all 0.3s ease",
    whiteSpace: "nowrap" as const,
  },
  wholeContent: {},
  imgDiv: {
    padding: "20px 0",
  },
  imgTag: {
    borderRadius: "10px",
  },
  videoWrapper: {
    width: "100%",
    maxWidth: "770px",
    position: "relative" as const,
  },
  videoOverlay: {
    position: "absolute" as const,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
  },
  playButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: 0,
    transform: "scale(1)",
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.1)",
    }
  }
};
// Customizable Area End
