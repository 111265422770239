// Customizable Area Start
import React from "react";
import '../../catalogue1/src/Header.css'
import {
    Box, Typography,
    ListItemIcon,
    ListItemText,
    Divider,
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    ListItem,
    CssBaseline,
    CircularProgress,
} from "@material-ui/core";
import Header from "../../catalogue1/src/Header.web";
import "../../scheduling/src/global.css";
import ViewresultController, { Props } from "./ViewResultController.web";
import ProgessBar from "./ProgressBar.web";
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from "@material-ui/icons";
import { skipexam,backbutton } from "./assets";
const closedDrawerWidth = 60;
const openDrawerWidth = 300;

const styles = {
    root: {
        display: "flex",

    },
   
    menuButton: {
        marginRight: "20px",
    },
    hide: {
        display: "none",
    },
    appBar: {
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    appBarShift: {
      
        width: `calc(100% - ${openDrawerWidth}px)`,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1), margin 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    show: {
        display: "block",
    },
   
    drawerClose: {
        overflowX: "hidden",
        width: closedDrawerWidth,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: "0px ",
        justifyContent: "flex-end",
    },
    drawer: {
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: openDrawerWidth,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    content: {
        flexGrow: 1,
        padding: "30px",
      
        transition: "margin-left 225ms cubic-bezier(0, 0, 0.2, 1)",
        width: `calc(100% - ${closedDrawerWidth}px)`,

    },
    contentShift: {
      
        width: `calc(100% - ${openDrawerWidth}px)`,
    },
};

// Customizable Area End
export default class Viewresult extends ViewresultController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderFullBar() {
        const {
            currentLessonIndex,
            currentSection,
        } = this.state;

        return (
            <ProgessBar data-test-id="handleNavigation"             
                currentIndex={currentLessonIndex}
                lessons={currentSection?.sections?.data || []}
                scrollProgress={100} />)
    }

    renderContent() {

        const { loading, error } = this.state;
        if (loading) {
            
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    width="100%"
                >
                    <CircularProgress style={{ color: "white" }} />
                </Box>
            );
        }

        if (error) {
            return (
                <Typography variant="h6" style={{ color: "red" }}>
                    {error}
                </Typography>
            );
        }
        return (
            <Box style={webStyle.mainContentHeading}>
                <>
                    <style>
                        {`
                .preview-font {
                    font-family: 'Poppins', sans-serif !important;
                }
                .mainDescription p, .mainDescription span {
                  color: white !important;
                  font-size: 20px;
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                }
                .preview-font span {
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                  color: #fff !important;
                }
                .scroll-container::-webkit-scrollbar {
                  display: none;
                }
                .preview-font p {
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                  clear: left;
                }
                .preview-font img {
                  float: right;
                  margin: 0 0 15px 15px;
                  border-radius: 10px;
                  height: 200px;
                  width: 250px;
                  object-fit: fill;
                }
                .MuiPaper-root.MuiPaper-rounded{
                  min-width: auto !important;
                  border-radius: 50px;
                  scrollbar-width: none;
                }
            `}
                    </style>
                </>
                <Box style={webStyle.content}>
                    <Box style={webStyle.courseSubHeading}>
                        <Typography
                            variant="h2"                 
                            data-testid="unit-title"
                            style={{ fontSize: "24px" }}
                        >
                            {this.state.data?.unit_name}
                        </Typography>
                        <div
                         dangerouslySetInnerHTML={{
                            __html: this.state.currentSection?.description,
                        }}
                            className="mainDescription"
                           
                        ></div>
                    </Box>
                    {this.renderFullBar()}
                    <Box style={webStyle.container} className="scroll-container">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                
                        <img data-test-id="handleback" onClick={()=>this.handlebackbutton()}src={backbutton}  style={{cursor:'pointer',height:'30px',width:'30px',marginRight:'20px'}}alt="back button"/>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                               
                                <Typography variant="subtitle1" style={webStyle.question}>
                                    Question Bank Completed
                                </Typography>
                                <Typography variant="subtitle1" style={webStyle.questionResult}>
                                    Your results
                                </Typography>
                            </div>
                            </div>

                           
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        color: '#64748B',
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        fontFamily: 'Raleway',
                                        marginRight: '10px',
                                    }}
                                >
                                    GRADE:
                                </div>
                                <div
                                    style={{
                                        ...webStyle.questionScore,
                                        backgroundColor: this.handleColorScore(),
                                        marginRight: '10px',
                                        fontFamily:'Poppins',
                                    }}
                                >
                                    {this.state.viewresult?.score}/100
                                </div>
                            </div>
                        </div>
                        <Box style={{ ...webStyle.subcontainer }} className="scroll-container">
                          
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img style={{ marginTop: '5px', marginRight: '8px' }} src={skipexam} />
                            <div style={webStyle.question}>
                                 {this.state.viewresult?.total_questions} questions
                            </div>
                            </div>
                            <>
                                {this.state.viewresult?.question_details?.map((item: any, index: any) => (
                                    <div key={item.question_id} style={{ marginBottom: '20px' }}>
                                        <Typography variant="subtitle1" style={webStyle.questionNumber}>
                                            {`Question #${index + 1}`}
                                        </Typography>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{
                                                    ...webStyle.questionHeading,
                                                    flexGrow: 1,
                                                    marginRight: '20px',
                                                    maxWidth: 'calc(100% - 140px)',
                                                }}
                                            >
                                                {item?.question}
                                            </Typography>               
                                            <div
                                                style={{
                                                    fontFamily: 'Raleway',
                                                    fontWeight: 700,
                                                    backgroundColor: '#475569',
                                                    borderRadius: '20px',
                                                    width: '108px',
                                                    height: '30px',
                                                    color: '#FFFFFF',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexShrink: 0,
                                                    
                                                }}>
                                                <img
                                                    alt="Status Icon"
                                                    style={{ marginRight: '8px' }}
                                                    src={this.getImageByStatus(item?.status)}
                                                />
                                                {item?.status}
                                            </div>
                                        </div>
                                        <Divider style={{backgroundColor:'white',marginTop:'20px'}} />

                                    </div>
                                ))}
                            </>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
    render() {
        // Customizable Area Start
        const { openTab } = this.state;
        return (
            <>
                <Box style={webStyle.baseColor}>
                    <Header navigation={this.props.navigation} id={"5"} />
                    <div style={styles.root} >
                        {openTab && <div className="sidenavHeader" style={styles.drawerHeader} >
                            <IconButton onClick={this.handleClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>}
                        <Toolbar className="closedNav" style={openTab ? styles.hide : styles.show}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleopenTab}
                                edge="start"
                                style={styles.menuButton}
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        </Toolbar>
                        <CssBaseline />
                        <Drawer
                            style={styles.drawer}
                            variant="permanent"
                            anchor="left"
                            open={openTab}
                            PaperProps={{ style: openTab ? styles.drawerOpen : styles.drawerClose }}>

                            <AppBar
                                className="navHeader"
                                style={openTab ? { ...styles.appBar, ...styles.appBarShift } : styles.appBar}>
                            </AppBar>
                            <Divider />
                            <ul>
                                <div className="navHeading">
                                    {openTab && <button className="goBackbtn" data-testid="goBack">
                                        <ChevronLeftIcon />
                                    </button>}
                                    <div className="navSubHead">
                                        <li className="coursename">
                                            <h5>{openTab && this.state.data && this.state.data.course_name}</h5>
                                        </li>
                                        <li className="unitname">
                                            <h4>{openTab && this.state.data && this.state.data.unit_name}</h4>
                                        </li>
                                    </div>
                                </div>
                                <Divider className="gradientHR" />

                                {this.state.data && this.state.data?.topics?.map((section) => (
                                    <li key={section.id}>
                                        <ListItemIcon style={{ color: "#fff" }}>

                                        </ListItemIcon> <h5 className="sectionName">{openTab && section.name}</h5>
                                        <ul>
                                            {section.sections?.data?.map((lesson, index) => (
                                                <List key={lesson.id}> <ListItem >
                                                    <ListItemIcon
                                                        className="iconIndex">
                                                        {index + 1}
                                                    </ListItemIcon>
                                                    <p className="lessonName">{openTab && <ListItemText primary={lesson.attributes.name} />}</p>
                                                </ListItem></List>
                                            ))}
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon className="iconIndex">
                                                        {section.sections.data.length + 1}
                                                    </ListItemIcon>
                                                    <p className="lessonName">
                                                        {openTab && <ListItemText primary="QuestionBank" />}
                                                    </p>
                                                </ListItem>
                                            </List>

                                        </ul>
                                    </li>
                                ))}
                            </ul>
                            <Divider />

                        </Drawer>

                        <main
                            style={openTab ? { ...styles.content, ...styles.contentShift } : styles.content}
                        >
                            <div style={styles.drawerHeader} />
                            {!open && <button className="goBackbtn2" >
                                <ChevronLeftIcon />
                            </button>}
                            {this.renderContent()}
                        </main>
                        </div>
                    <div
                        style={{ backgroundColor: '#050D15', height: '45px' }}>
                        <div className="social-links-container">
                            <p style={{ color: '#FFFFFF', marginTop: '5px', marginLeft: '100px' }}>2024 MedEd Cloud. All rights reserved.</p>
                        </div>

                    </div>
                </Box>
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle = {
    baseColor: {
        backgroundColor: "rgb(28, 34, 53)",
        minHeight: "100vh",
    },
    root: {
        backgroundColor: "rgb(28, 34, 53)",
        minHeight: "100vh",
        color: "white",
        overflowX: "hidden" as const,
        display: "flex",
        flexWrap: "wrap" as const,
        textAlign: "end" as const,
    },
    mainContentHeading: {
        width: "100%",
        minHeight: '700px',
        height: '100%'
    },
    content: {
        position: "relative" as const,
    },
    container: {
        padding: "40px",
        width: "84%",
        height: "auto",
  
        minHeight: '500px',
        background: "linear-gradient(315deg, #94A3B8, #BECBDF, #94A3B8)",
        borderRadius: "20px",
        margin: "0 auto",
        overflowY: "auto" as const,
        scrollbarWidth: "none" as const,
        maxHeight: "100%",
    },
    wholeContent: {
        fontSize: '18px',
        marginRight: '10px',
        marginTop: '30px',
        fontFamily: "Poppins"
    },
    titleContainer: {
        width: "fit-content",
        height: "43px",
        backgroundColor: "#64748B",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center" as const,
        alignItems: "center",
    },
    questionScore: {
        color: 'white',
        fontSize: '19px',
        fontWeight: 'bold',
        marginRight: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '99px',
        height: '43px',
        borderRadius: '15px'
    },
    questionNumber:{
         marginTop:'20px',
         fontFamily:'Raleway',
         fontSize:'18px',
         fontWeight:900,
         color:'#64748B'
    },
    questionHeading:{
        marginTop:'20px',
        fontFamily:'Poppins',
        fontSize:'16px',
        color:'#334155'
    },
    subcontainer: {
        border:'1px solid white',
        padding: "40px",
        height: "auto",
        background: "#CBD5E1",
        borderRadius: "20px",
        margin: "0 auto",
        marginTop: '30px',
        overflowY: "auto" as const,
        scrollbarWidth: "none" as const,
        maxHeight: "100%",
    },
    title: {
        fontFamily: 'Raleway',
        fontSize: "22px",
        padding: "16px",
        fontWeight: 'bold'
    },
    description: {
        fontSize: "18px",
        textAlign: "justify" as const,
        letterSpacing: "0.7px",
        paddingTop: "10px",
    },
    courseSubHeading: {
        display: "flex",
        alignItems: "center" as const,
        padding: "30px 0",
        flexDirection: "column" as const,
        gap: "10px",
        position: "relative" as const,
        margin: "0 154px",
    },

    btn2: {
        cursor: 'pointer',
        width: "230px",
        height: "44px",
        background: "#72EFDD",
        border: "1px solid #72EFDD",
        borderRadius: "20px",
        fontSize: "16px",
        letterSpacing: "0.8px",
        fontWeight: 600,
        fontFamily: 'Raleway'
    },
    video: {
        width: "100%",
        maxWidth: "770px",
        borderRadius: "10px",
        backgroundColor: "transparent",
    },
    tab: {
        padding: "8px 16px",
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
        fontSize: "16px",
        transition: "all 0.3s ease",
        whiteSpace: "nowrap" as const,
    },
    questionResult:{
        color:'#334155',
        fontSize: '22px',
        fontWeight:700,
        fontFamily: "Raleway"
    },
    question: {
        color: '#64748B',
        fontSize: '16px',
        marginTop: '10px',
        fontFamily: "Raleway"
    },
};
// Customizable Area End
